import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { chaat } from "../app/app-root";
import Insert from "./cue-insert-mark";

const CueInsertPointText = styled.div`
  font-family: ${p => p.theme.sansSerif};
  width: 1000px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-left: 5px;
`;

const CueInsertTarget = observer(() => {
  return (
    <div>
      <CueInsertPointText>
        {chaat.cueInsertAtomId >= 0 ? <Insert /> : null}
        {`${chaat.utils.getScriptStringMaxChars(chaat.cueInsertAtomId, 150)}`}
      </CueInsertPointText>
    </div>
  );
});

export default CueInsertTarget;
