import * as firebase from "firebase/app";
import "firebase/firestore";
import { chaat } from "./app-root";
import { openEntityVersionsDialog } from "../components/entity-versions-dialog";

export class EntityVersionsActions {
  constructor() {}

  get dbPaths() {
    return chaat.dbPaths;
  }

  async openEntityVersionsDialogAction(entityId) {
    const snap = await this.dbPaths.entityVersionsDocRef.get();
    let entityVersions = snap
      .data()
      .versions.filter(
        entity => entity.id == entityId && entity.updates.length > 0
      );
    entityVersions.sort((a, b) => b.version - a.version);
    console.log(entityVersions);
    openEntityVersionsDialog(entityVersions);
  }
}
