import React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "../components/stylesystem/theme";
import Script from "./script";
import Sidebar from "../components/sidebar";
import { TracksComponent } from "../components/track/tracks";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  @import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
  body {
    margin:0;
    padding:0;
  }
`;

const PanesContainer = styled.div`
  padding-left: 200px;
`;

const SideBarPane = styled.div`
  left: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  position: fixed;
  overflow: auto;
`;

const ScriptPane = styled.div``;

const TracksContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${p => p.theme.tracksZIndex};
`;

const Layout = ({}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PanesContainer>
        <SideBarPane>
          <Sidebar />
        </SideBarPane>
        <ScriptPane>
          <Script />
        </ScriptPane>
        <TracksContainer>
          <TracksComponent />
        </TracksContainer>
      </PanesContainer>
    </ThemeProvider>
  );
};

export default Layout;
