import { observable, reaction } from "mobx";
import { appBus, transportState } from "../../app/app-root";

export class TrackWidget {
  @observable frameNumber = 0;
  audioStart = 0;

  foregroundCanvas = null;
  _backgroundCanvas = null;
  _backgroundContext = null;
  _foregroundContext = null;

  canvasStartTime = 0;
  canvasEndTime = 0;
  canvasTimeExtent = 0;
  canvasPixelWidth = 0;
  canvasPixelHeight = 0;
  timeZoomSelect = 2;
  timeZoomLevels;
  DPR = window.devicePixelRatio;
  targetScale = 2.0;

  needsBackgroundRedraw = false;
  animationFramePending = false;

  gadgets = [];
  disposers = [];
  lastRolloverGadget = null;
  lastRolloverState = null;
  lastHoverState = null;
  enabled = true;

  hoverTargetElementId = "";
  _hoverTargetElement = null;
  _hoverElement = null;
  hoverShowing = false;

  get backgroundCanvas() {
    if (!this._backgroundCanvas) {
      this._backgroundCanvas = document.createElement("canvas");
      this._backgroundCanvas.width = this.foregroundCanvas.width;
      this._backgroundCanvas.height = this.foregroundCanvas.height;
    }
    return this._backgroundCanvas;
  }

  get adjustScale() {
    return this.DPR / this.targetScale;
  }

  get inverseAdjustScale() {
    return this.targetScale / this.DPR;
  }

  adjustScaleForTargetScale(ctx) {
    if (this.DPR !== this.targetScale) {
      const adjustScale = this.adjustScale;
      ctx.scale(adjustScale, adjustScale);
    }
  }

  get foregroundContext() {
    if (!this._foregroundContext) {
      const ctx = this.foregroundCanvas.getContext("2d");
      this.adjustScaleForTargetScale(ctx);
      this._foregroundContext = ctx;
    }
    return this._foregroundContext;
  }

  get backgroundContext() {
    if (!this._backgroundContext) {
      const ctx = this.backgroundCanvas.getContext("2d");
      this.adjustScaleForTargetScale(ctx);
      this._backgroundContext = ctx;
    }
    return this._backgroundContext;
  }

  layoutGadgetSlices() {
    // TODO or createGadgets or create and layout separate?
    throw new Error("non implemented abstract");
  }

  createGadgets() {
    // TODO ??
    throw new Error("non implemented abstract");
  }

  observeGadgetDrawRequests() {
    for (const gadget of this.gadgets) {
      gadget.onForegroundShouldRedraw(() => this.requestForegroundRedraw());
      gadget.onBackgroundShouldRedraw(() => this.requestBackgroundRedraw());
    }
  }

  disposeGadgets() {
    for (const gadget of this.gadgets) {
      gadget.dispose();
    }
    this.gadgets = [];
  }

  dispose() {
    // for this.disposers call dispose
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  initReactions() {
    this.disposers.push(
      reaction(
        () => this.frameNumber,
        () => this.draw()
      )
    );
    this.disposers.push(
      reaction(
        () => transportState.audioPosition,
        () => this.handleAudioPositionUpdate()
      )
    );
    this.disposers.push(
      appBus.subscribe("timeZoom", direction => this.timeZoomAction(direction))
    );
    this.disposers.push(
      appBus.subscribe("tracksToggleEnabled", direction =>
        this.toggleEnabled(direction)
      )
    );
  }

  setCanvas(canvas) {
    this.foregroundCanvas = canvas;
    this.initFromCanvas();
  }

  initFromCanvas() {
    const width = this.foregroundCanvas.width;
    const height = this.foregroundCanvas.height;
    this.foregroundCanvas.width = this.foregroundCanvas.width * this.DPR;
    this.foregroundCanvas.height = this.foregroundCanvas.height * this.DPR;
    this.foregroundCanvas.style.width = width + "px";
    this.foregroundCanvas.style.height = height + "px";
    this.canvasPixelWidth = width * this.targetScale;
    this.canvasPixelHeight = height * this.targetScale;
    this.timePixelScale = 1.0 / this.targetScale;
    this.timeZoomLevels = [
      this.timePixelScale / 5.0,
      this.timePixelScale / 2.0,
      this.timePixelScale,
      this.timePixelScale * 2.0
    ];
    this.canvasTimeExtent = this.canvasPixelWidth / this.timePixelScale;
    this.canvasEndTime = this.canvasStartTime + this.canvasTimeExtent;

    this.createGadgets();
    this.layoutGadgetSlices();
    this.observeGadgetDrawRequests();
    this.setGadgetsCanvasMapping();
    this.initReactions();
    this.requestRedraw();
    this.foregroundCanvas.addEventListener("mousemove", e =>
      this.handleMouseMove(e)
    );
    this.foregroundCanvas.addEventListener("click", e =>
      this.handleMouseClick(e)
    );
    this.foregroundCanvas.addEventListener("mouseout", e =>
      this.handleMouseOut(e)
    );
  }

  canvasPixelXToTime(pixelX) {
    return pixelX / this.timePixelScale + this.canvasStartTime;
  }

  timeToCanvasPixelX(time) {
    return (time - this.canvasStartTime) * this.timePixelScale;
  }

  timeIsOnCanvas(time) {
    return time > this.canvasStartTime && time < this.canvasEndTime;
  }

  setGadgetsCanvasMapping() {
    for (const gadget of this.gadgets) {
      gadget.setCanvasMapping(
        this.canvasStartTime,
        this.canvasTimeExtent,
        this.canvasPixelWidth,
        this.timePixelScale
      );
    }
  }

  setCanvasMappingWithStartTime(time) {
    if (time < this.audioStart) {
      time = this.audioStart;
    }
    this.canvasStartTime = time;
    this.canvasEndTime = time + this.canvasTimeExtent;
    this.setGadgetsCanvasMapping();
  }

  requestTimePositionVisible(time, placement) {
    if (this.timeIsOnCanvas(time)) {
      return;
    }
    const requestStartTime = time - this.canvasTimeExtent * placement;
    this.setCanvasMappingWithStartTime(requestStartTime);
  }

  handleAudioPositionUpdate() {
    if (!this.enabled) {
      return;
    }
    const audioPosition = transportState.audioPosition;
    // TODO need to check of isPlaying or not?
    const placement = transportState.isPlaying ? 0.01 : 0.15;
    this.requestTimePositionVisible(audioPosition, placement);
  }

  drawBackgroundLayer() {
    // draw the gadgets on the background
    const ctx = this.backgroundContext;
    ctx.clearRect(0, 0, this.canvasPixelWidth, this.canvasPixelHeight);

    for (const gadget of this.gadgets) {
      gadget.drawBackgroundLayer(ctx);
    }
    this.needsBackgroundRedraw = false;
  }

  drawForegroundLayer() {
    // blit the background and draw gadgets on the foreground
    const ctx = this.foregroundContext;
    ctx.clearRect(0, 0, this.canvasPixelWidth, this.canvasPixelHeight);
    ctx.save();
    const scale = this.inverseAdjustScale;
    ctx.scale(scale, scale);
    ctx.drawImage(this.backgroundCanvas, 0, 0);
    ctx.restore();
    for (const gadget of this.gadgets) {
      gadget.drawForegroundLayer(ctx);
    }
  }

  draw() {
    // draw background if needed
    if (this.needsBackgroundRedraw) {
      this.drawBackgroundLayer();
    }
    this.drawForegroundLayer();

    // allow requests for new animation frames for new changes
    this.animationFramePending = false;
  }

  requestForegroundRedraw() {
    this.requestRedraw();
  }

  requestBackgroundRedraw() {
    this.needsBackgroundRedraw = true;
    this.requestRedraw();
  }

  requestRedraw() {
    // request animation frame if haven't already
    if (this.animationFramePending) {
      return;
    }
    this.animationFramePending = true;
    requestAnimationFrame(() => this.frameDriver());
  }

  requestFullRedraw() {
    this.requestForegroundRedraw();
    this.requestBackgroundRedraw();
  }

  frameDriver() {
    // increment the frame number in mobx observable to cause drawing inside mobx reaction
    this.frameNumber++;
  }

  handleCanvasResize() {
    // TODO assuming will only resize width for now, determine if resize can occur or not, probably not
    this.canvasPixelWidth = this.foregroundCanvas.width;
    this.canvasTimeExtent = this.canvasPixelWidth / this.timePixelScale;
    this.setGadgetsCanvasMapping();
    this.requestRedraw();
  }

  handleMouseMove(e) {
    this.checkRollover(
      e.offsetX * this.targetScale,
      e.offsetY * this.targetScale
    );
    this.checkHover();
  }

  handleMouseOut(e) {
    if (this.lastRolloverGadget) {
      this.lastRolloverGadget.setRolloverState(null);
    }
    this.lastRolloverState = null;
    this.lastRolloverGadget = null;
    this.lastHoverState = null;
    this.hideHover();
  }

  handleMouseClick(e) {
    const x = e.offsetX * this.targetScale;
    const y = e.offsetY * this.targetScale;
    const time = this.canvasPixelXToTime(x);

    for (const gadget of this.gadgets.slice().reverse()) {
      if (gadget.yInside(y) && gadget.handleMouseClickAtTime(x, y, time, e))
        return;
    }
  }

  checkRolloverGadget(gadget, x, y, time, state = null) {
    if (!gadget.yInside(y)) {
      return null;
    } else {
      return gadget.checkRollover(x, y, time, state);
    }
  }

  checkRollover(x, y) {
    // check the last and rest of gadgets
    // TODO TODO XXXXXXXXX
    const time = this.canvasPixelXToTime(x);
    let rolloverState = null;

    if (this.lastRolloverGadget) {
      rolloverState = this.checkRolloverGadget(
        this.lastRolloverGadget,
        x,
        y,
        time,
        this.lastRolloverState
      );
      if (this.lastRolloverState !== rolloverState) {
        const gadget = this.lastRolloverGadget;
        this.deactivateCurrentRollover();
        if (rolloverState) {
          gadget.setRolloverState(rolloverState);
          this.lastRolloverState = rolloverState;
        }
      } else {
        return;
      }
    }

    let pixelValue;
    let gotPixelValue = false;
    for (const gadget of this.gadgets) {
      if (gadget.doesRollover && gadget.yInside(y)) {
        if (gadget.detectPixelForRollover) {
          if (!gotPixelValue) {
            const pixel = this.backgroundContext.getImageData(x, y, 1, 1);
            const data = pixel.data;
            pixelValue = data[3];
            gotPixelValue = true;
          }
          if (pixelValue > 0) {
            // TODO factor duplicate code
            rolloverState = this.checkRolloverGadget(gadget, x, y, time);
            if (rolloverState) {
              // TODO maybe move into checkRolloverGadget?
              gadget.setRolloverState(rolloverState);
              this.lastRolloverGadget = gadget;
              this.lastRolloverState = rolloverState;
              return;
            }
          }
        } else {
          rolloverState = this.checkRolloverGadget(gadget, x, y, time);
          if (rolloverState) {
            gadget.setRolloverState(rolloverState);
            this.lastRolloverGadget = gadget;
            this.lastRolloverState = rolloverState;
            return;
          }
        }
      }
    }
  }

  deactivateCurrentRollover() {
    if (this.lastRolloverGadget) {
      this.lastRolloverGadget.deactivateRollover();
    }
    this.lastRolloverGadget = null;
    this.lastRolloverState = null;
  }

  timeIntervalToPixelInterval(interval) {
    return {
      start: this.timeToCanvasPixelX(interval.start),
      end: this.timeToCanvasPixelX(interval.end)
    };
  }

  checkHover() {
    if (this.lastHoverState !== this.lastRolloverState) {
      this.lastHoverState = this.lastRolloverState;
      if (!this.lastHoverState) {
        this.hideHover();
        return;
      }
      this.doHover();
    }
  }

  doHover() {
    // implemented by subclass
  }

  get hoverElement() {
    if (!this._hoverElement) {
      const target = document.getElementById(this.hoverTargetElementId);
      this._hoverElement = document.createElement("div");
      this._hoverElement.setAttribute("class", "track-hover");
      target.insertAdjacentElement("beforeend", this._hoverElement);
    }
    return this._hoverElement;
  }

  get hoverTargetElement() {
    if (!this._hoverTargetElement) {
      this._hoverTargetElement = document.getElementById(
        this.hoverTargetElementId
      );
    }
    return this._hoverTargetElement;
  }

  showHover(gadget, text, placement) {
    let { x, bottom, top, absolute, interval } = placement;
    const target = this.hoverTargetElement;
    const hoverElement = this.hoverElement;
    this.hoverShowing = true;
    hoverElement.style.display = "inline";
    hoverElement.innerHTML = text;

    if (interval) {
      interval = this.timeIntervalToPixelInterval(interval);
      x = (interval.start + interval.end) / 2 / this.targetScale;
      x -= hoverElement.offsetWidth / 2;
    } else {
      x = x / this.targetScale;
    }

    hoverElement.style.left = target.offsetLeft + x + "px";

    if (top) {
      top = top / this.targetScale;
      const gadgetTop = gadget.originY / this.targetScale;
      const offsetHeight = target.offsetHeight;
      const coordinatesRect = target.offsetParent.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();
      const offsetTargetBottom = coordinatesRect.bottom - targetRect.bottom;
      hoverElement.style.bottom =
        offsetHeight - (gadgetTop - top) + offsetTargetBottom + "px";
    } else if (bottom) {
      bottom = bottom / this.targetScale;
      const gadgetBottom = gadget.bottomY / this.targetScale;
      hoverElement.style.top = target.offsetTop + gadgetBottom + bottom + "px";
    } else if (absolute) {
      const y = absolute / this.targetScale;
      hoverElement.style.top = target.offsetTop + y + "px";
    }
  }

  hideHover() {
    if (this.hoverShowing) {
      this.hoverElement.style.display = "none";
      this.hoverShowing = false;
    }
  }

  setElementId(id) {
    this.hoverTargetElementId = id;
  }

  timeZoomAction(direction) {
    const select = direction
      ? this.timeZoomSelect + 1
      : this.timeZoomSelect - 1;
    if (select < 0 || select >= this.timeZoomLevels.length) {
      return;
    }
    this.timeZoomSelect = select;
    this.timePixelScale = this.timeZoomLevels[select];
    this.canvasTimeExtent = this.canvasPixelWidth / this.timePixelScale;
    this.canvasEndTime = this.canvasStartTime + this.canvasTimeExtent;
    this.setGadgetsCanvasMapping();
  }

  toggleEnabled() {
    this.enabled = !this.enabled;

    if (this.enabled) {
    }
  }
}
