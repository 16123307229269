import { SortedPoints } from "../sorted/sorted-points";

export class TreeData {
  atomRangeInterval = null;
  atomEntityList = null;
  typedEntityLists = {};
  spanPrecedence = [];
  useTransitionPoints = true;
  _transitionPoints = null;
  _entityListsByPrecedence = null;

  static fromTimelineEntities(timelineEntities) {
    const treeData = new TreeData();
    treeData.atomRangeInterval = timelineEntities.atomRangeInterval;
    treeData.atomEntityList = timelineEntities.atomEntityList;
    treeData.typedEntityLists = timelineEntities.typedEntityLists;
    return treeData;
  }

  get leastPrecedence() {
    return this.spanPrecedence.length;
  }

  findWithPrecedence(address, precedenceLevel) {
    let foundSpan = false;
    let type;
    let searchEntities;
    let resultIndex;
    let resultPrecedence;
    let resultInterval = null;
    let resultPoint = null;
    let isPoint = false;

    if (this.useTransitionPoints) {
      const transitionPrecedence = this.transitionPoints[address];
      if (transitionPrecedence > precedenceLevel) {
        precedenceLevel = transitionPrecedence;
      }
    }

    for (let p = precedenceLevel; p < this.leastPrecedence; p++) {
      searchEntities = this.entityListsByPrecedence[p];
      const searchSpanType = searchEntities.type;
      const relation = searchEntities.getEntityAtomRelations();

      isPoint = relation.constructor === SortedPoints;

      const index = relation.containing(address);

      if (index >= 0) {
        // found a span
        foundSpan = true;
        type = searchSpanType;
        resultIndex = index;
        resultPrecedence = p;
        if (isPoint) {
          resultPoint = address;
        } else {
          resultInterval = relation.interval(index);
        }
        break;
      }
    }
    let id, data;

    if (!foundSpan) {
      // didn't find span, result is an atom
      isPoint = false;
      type = "ATOM";
      resultPrecedence = this.leastPrecedence;
      resultIndex = address;
      id = this.atomEntityList.getId(resultIndex);
      data = this.atomEntityList.getEntityDataFromIndex(resultIndex);
    } else {
      id = searchEntities.getId(resultIndex);
      data = searchEntities.getEntityDataFromIndex(resultIndex);
    }

    /* return result */
    return {
      isPoint,
      type,
      data,
      id,
      interval: resultInterval,
      point: resultPoint,
      index: resultIndex,
      precedenceLevel: resultPrecedence
    };
  }

  get entityListsByPrecedence() {
    if (!this._entityListsByPrecedence) {
      const byPrecedence = [];
      for (const entityType of this.spanPrecedence) {
        byPrecedence.push(this.typedEntityLists[entityType]);
      }
      this._entityListsByPrecedence = byPrecedence;
    }
    return this._entityListsByPrecedence;
  }

  get transitionPoints() {
    if (!this._transitionPoints) {
      this._transitionPoints = new Array(this.atomEntityList.count).fill(
        this.leastPrecedence
      );
      for (const [
        precedence,
        entityList
      ] of this.entityListsByPrecedence.entries()) {
        const atomRelations = entityList.getEntityAtomRelations();
        for (const point of atomRelations.openTransitions) {
          if (this._transitionPoints[point] > precedence) {
            this._transitionPoints[point] = precedence;
          }
        }
      }
    }
    return this._transitionPoints;
  }
}
