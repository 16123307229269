export class TreeIteratorConfig {
  treeData = null;
  withChildren = true;
  productionFunctions = {};
}

export class TreeIterator {
  config = null;
  info = null;

  currentAddress = 0;
  currentPrecedence = 0;

  constructor(spanInfo, config) {
    this.info = spanInfo;
    this.config = config;
    this.currentAddress = this.info.interval.start;
    this.currentPrecedence = this.precedenceLevel;
  }

  get precedenceLevel() {
    return this.info.precedenceLevel + 1;
  }

  // TODO change to elements not children?
  next() {
    if (this.currentAddress >= this.info.interval.end) {
      return { done: true };
    }
    let result = this.config.treeData.findWithPrecedence(
      this.currentAddress,
      this.currentPrecedence
    );
    const currentAddress = this.currentAddress;
    if (result) {
      if (result.isPoint) {
        // for point mode go down instead of forward
        this.currentPrecedence = result.precedenceLevel + 1;
      } else {
        this.currentPrecedence = this.precedenceLevel;
        if (result.type !== "ATOM" && this.config.withChildren) {
          // TODO avoid closure?
          const children = () => {
            result.interval.start = Math.max(
              result.interval.start,
              currentAddress
            );
            result.interval.end = Math.min(
              result.interval.end,
              this.info.interval.end
            );
            return new TreeIterator(result, this.config);
          };
          result.children = children;
          this.currentAddress = Math.min(
            this.info.interval.end,
            result.interval.end
          );
        } else {
          this.currentAddress++;
        }
      }

      const func = this.config.productionFunctions[result.type];
      if (func) {
        result = func(result);
      }
      return { value: result, done: false };
    }
  }

  [Symbol.iterator] = () => this;
  // TODO work out if should return new iterable each time or not
}

export function makeRootTreeIterator(treeData) {
  const spanInfo = {
    interval: treeData.atomRangeInterval,
    precedenceLevel: -1
  };
  const config = new TreeIteratorConfig();
  config.treeData = treeData;
  config.withChildren = true;
  return new TreeIterator(spanInfo, config);
}

export function makeTree(treeData) {
  const spanInfo = {
    interval: treeData.atomRangeInterval,
    precedenceLevel: -1
  };
  const config = new TreeIteratorConfig();
  config.treeData = treeData;
  config.withChildren = true;
  return new FastTree(spanInfo, config);
}

export class FastTree {
  config = null;
  info = null;

  currentAddress = 0;
  currentPrecedence = 0;

  constructor(spanInfo, config) {
    this.info = spanInfo;
    this.config = config;
    this.currentAddress = this.info.interval.start;
    this.currentPrecedence = this.precedenceLevel;
  }

  get precedenceLevel() {
    return this.info.precedenceLevel + 1;
  }

  get tree() {
    // TODO change to elements not children?
    const tree = [];
    while (this.currentAddress < this.info.interval.end) {
      let result = this.config.treeData.findWithPrecedence(
        this.currentAddress,
        this.currentPrecedence
      );
      const currentAddress = this.currentAddress;
      if (result) {
        if (result.isPoint) {
          // for point mode go down instead of forward
          this.currentPrecedence = result.precedenceLevel + 1;
        } else {
          this.currentPrecedence = this.precedenceLevel;
          if (result.type !== "ATOM" && this.config.withChildren) {
            result.interval.start = Math.max(
              result.interval.start,
              currentAddress
            );
            result.interval.end = Math.min(
              result.interval.end,
              this.info.interval.end
            );
            const childrenTree = new FastTree(result, this.config);
            result.children = childrenTree.tree;
            this.currentAddress = Math.min(
              this.info.interval.end,
              result.interval.end
            );
          } else {
            this.currentAddress++;
          }
        }

        const func = this.config.productionFunctions[result.type];
        if (func) {
          result = func(result);
        }
        tree.push(result);
      }
    }
    return tree;
  }
}

export function expandTree(iter) {
  const result = [];
  for (const elem of iter) {
    if (elem.type === "ATOM" || elem.isPoint) {
      result.push(elem);
    } else {
      elem.children = expandTree(elem.children());
      result.push(elem);
    }
  }
  return result;
}

export function printTree(iter) {
  for (const elem of iter) {
    const indent = "X   ".repeat(iter.info.precedenceLevel + 1);
    if (elem.type === "ATOM") {
      console.log(indent + elem.data.text);
    } else {
      console.log(elem.type + " " + elem.index);
      printTree(elem.children());
    }
  }
}
