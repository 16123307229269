import { observable, reaction, runInAction } from "mobx";
import notie from "notie";
import { DbPaths } from "./db-paths";
import { timestamperServerBox } from "../../app/chaat-project";
import { EMPTY_OBJECT } from "../immutable_empties";

export class ChaatProjectLoader {
  projectKey = null;
  @observable notifyDataUpdate = 1;
  @observable.ref projectDoc = null;
  @observable.ref scriptDoc = null;
  @observable.ref cuesDoc = null;
  @observable.ref waveformData = null;
  @observable.ref entityDoc = null;
  @observable.ref additionalEntityDoc = null;
  @observable.ref timestampsDoc = null;
  @observable.ref transcriptDoc = null;
  @observable.ref audioAnalysisDoc = null;
  @observable.ref audioRegionsDoc = null;
  audioURL = "";
  appContent = null;
  chaatContent = null;

  fireStoreDisposers = [];
  reactionDisposers = [];

  constructor(appContentLayer, chaatContentLayer) {
    this.appContent = appContentLayer;
    this.chaatContent = chaatContentLayer;

    this.watch(() => this.waveformData);
    this.watch(() => this.entityDoc);
    this.watch(() => this.additionalEntityDoc);
    this.watch(() => this.timestampsDoc);
    this.watch(() => this.scriptDoc);
    this.watch(() => this.cuesDoc);
    this.watch(() => this.transcriptDoc);
    this.watch(() => this.audioAnalysisDoc);
    this.watch(() => this.projectDoc);
    //    this.watch(() => this.audioRegionsDoc); TODO put in after handle version dependencies entirely
    this.appContent.onContentUpdated(() => this.handleContentReady());
  }

  watch(f) {
    this.reactionDisposers.push(reaction(f, () => this.handleUpdate()));
  }

  async load(key) {
    if (true || key !== this.projectKey) {
      this.close();
      this.dbPaths = new DbPaths(key);
      this.scriptDoc = null;
      this.cuesDoc = null;
      this.projectDoc = null;
      this.waveformData = null;
      this.entityDoc = null;
      this.timestampsDoc = null;
      this.transcriptDoc = null;
      this.audioAnalysisDoc = null;
      this.audioRegionsDoc = null;

      this.projectKey = key;
      this.audioURL = `https://storage.googleapis.com/jw-timestamper/${key}_m16000.wav`;

      this.dbPaths.timestampsDocRef.onSnapshot(doc => {
        console.log("*** timestamp doc update");
        this.handleTimestampsDocSnapshot(doc.data());
      });

      this.dbPaths.transcriptDocRef.onSnapshot(doc => {
        console.log("*** transcript doc update");
        this.transcriptDoc = doc.data();
      });

      this.dbPaths.audioAnalysisDocRef.onSnapshot(doc => {
        console.log("*** audio analysis doc update");
        this.audioAnalysisDoc = doc.data();
      });

      this.dbPaths.entitiesDocRef.onSnapshot(doc => {
        console.log("*** entities doc update");
        this.entityDoc = doc.data();
      });

      this.dbPaths.additionalEntitiesDocRef.onSnapshot(doc => {
        console.log("*** additional entities doc update");
        let data = doc.data();
        if (!data) {
          data = EMPTY_OBJECT;
        }
        this.additionalEntityDoc = data;
      });

      this.dbPaths.scriptDocRef.onSnapshot(doc => {
        console.log("*** script doc update");
        this.scriptDoc = doc.data();
      });

      this.dbPaths.cuesDocRef.onSnapshot(doc => {
        console.log("*** cues doc update");
        this.cuesDoc = doc.data();
      });

      this.dbPaths.audioRegionsDocRef.onSnapshot(doc => {
        console.log("*** audio region doc update");
        this.audioRegionsDoc = doc.data();
      });

      this.dbPaths.chaatDocRef.onSnapshot(doc => {
        console.log("*** chaat project doc update");
        this.handleProjectDocSnapshot(doc.data());
      });

      // const snap = await this.dbPaths.chaatDocRef.get();
      // this.projectDoc = snap.data();
      const waveformPromise = getWaveformWithCache(this.audioURL);
      const [waveformData] = await Promise.all([waveformPromise]);
      this.waveformData = waveformData;
    }
  }

  close() {
    // TODO actually save firestore listener disposers above
    for (const disposer of this.fireStoreDisposers) {
      disposer();
    }
    //TODO call the reaction disposers and init again on open?
  }

  refresh() {
    this.appContent.refresh();
  }

  handleTimestampsDocSnapshot(doc) {
    if (!doc) {
      return;
    }
    console.log("basedoc version: " + doc.version);
    if (doc.transcriptNotFetched) {
      notie.alert({
        text: "Transcription not finished will check again in a minute"
      });
      console.log("TRANSCRIPT not finished: " + doc.transcriptNotFetched);
      setTimeout(() => {
        console.log("trying run again");
        fetch(`${timestamperServerBox}/chaat_run?key=${this.projectKey}`);
      }, 60 * 1000);
    } else {
      this.timestampsDoc = doc;
    }
  }

  handleProjectDocSnapshot(doc) {
    if (!doc) {
      return;
    }
    if (!doc.audioUrl) {
      notie.alert({
        text: "Chaat timestamping is not initialized for " + this.projectKey
      });
      console.log("Chaat not initialized for key");
    } else {
      this.projectDoc = doc;
    }
  }

  handleUpdate() {
    // TODO if this.projectDoc and is not initialized notice and
    if (
      this.timestampsDoc &&
      this.scriptDoc &&
      this.cuesDoc &&
      this.entityDoc &&
      this.additionalEntityDoc &&
      this.waveformData &&
      this.audioAnalysisDoc &&
      this.audioRegionsDoc &&
      this.transcriptDoc &&
      this.projectDoc
    ) {
      console.log("INSIDE Loader handleUpdate ");
      if (
        this.scriptDoc.version !== this.timestampsDoc.scriptVersion ||
        this.cuesDoc.version !== this.timestampsDoc.cuesVersion
      ) {
        return;
      }
      console.log(
        `script: ${this.scriptDoc.version} output:${this.timestampsDoc.scriptVersion}`
      );
      console.log("RUNNING ACTION Loader handleUpdate ");
      runInAction(() => {
        this.appContent.projectKey = this.projectKey;
        this.appContent.audioURL = this.audioURL;
        this.appContent.alternativeAudio = this.projectDoc.alternativeAudio;
        this.appContent.scriptEntitiesDict = this.entityDoc;
        this.appContent.additionalEntitiesDict = this.additionalEntityDoc;
        this.appContent.waveformData = this.waveformData;

        //  CHAAT
        this.chaatContent.projectKey = this.projectKey;
        // this.chaatContent.atoms = this.scriptDoc.originalWords;
        // this.chaatContent.originalWords = this.scriptDoc.originalWords;
        this.chaatContent.atoms = this.scriptDoc.words;
        this.chaatContent.originalWords = this.scriptDoc.words;
        this.chaatContent.positionalMapping = this.scriptDoc.positionalMapping;
        // this.chaatContent.cues = this.scriptDoc.cues;
        this.chaatContent.cues = this.cuesDoc.cues;

        this.chaatContent.atomStartTimes = this.timestampsDoc.wordStartTimes;
        this.chaatContent.atomEndTimes = this.timestampsDoc.wordEndTimes;
        this.chaatContent.skipWarnStartTimes = this.timestampsDoc.skipWarnStartTimes;
        this.chaatContent.skipWarnEndTimes = this.timestampsDoc.skipWarnEndTimes;
        this.chaatContent.skipWarnData = this.timestampsDoc.skipWarnData;
        this.chaatContent.interpolatedStartTimes = this.timestampsDoc.interpolatedStartTimes;
        this.chaatContent.interpolatedEndTimes = this.timestampsDoc.interpolatedEndTimes;
        this.chaatContent.interpolationData = this.timestampsDoc.interpolationData;

        this.chaatContent.transcriptWords = this.transcriptDoc.transcriptWords;
        this.chaatContent.transcriptWordStartTimes = this.transcriptDoc.transcriptWordStartTimes;
        this.chaatContent.transcriptWordEndTimes = this.transcriptDoc.transcriptWordEndTimes;

        this.chaatContent.notchStartTimes = this.audioAnalysisDoc.notchStartTimes;
        this.chaatContent.notchEndTimes = this.audioAnalysisDoc.notchEndTimes;

        this.chaatContent.audioRegions = this.audioRegionsDoc;
      });
    }
  }

  handleContentReady() {
    this.notifyDataUpdate++;
  }

  onContentUpdated(cb) {
    this.reactionDisposers.push(
      reaction(
        () => this.notifyDataUpdate,
        () => cb(this.appContent)
      )
    );
  }
}

async function getWaveformWithCache(audioURL) {
  const waveformCacheURL = audioURL + "/waveform";

  const cache = await caches.open("blort2-cache");
  let resp = await cache.match(waveformCacheURL);
  if (resp) {
    const buffer = await resp.arrayBuffer();
    return new Uint16Array(buffer);
  }
  resp = await fetch(audioURL);
  if (!resp.ok) {
    console.log("unable to load audio from url: " + audioURL);
    return;
  }
  const buffer = await resp.arrayBuffer();
  window.OfflineAudioContext =
    window.OfflineAudioContext || window.webkitOfflineAudioContext;
  const audioContext = new window.OfflineAudioContext(1, 16000, 16000);
  const audioBuffer = await audioContext.decodeAudioData(buffer);
  const waveformData = createWaveformData(audioBuffer, audioContext);
  const uint16WaveformData = Uint16Array.from(waveformData);
  const blob = new Blob([uint16WaveformData.buffer]);
  const synthResp = new Response(blob);
  await cache.put(waveformCacheURL, synthResp);
  return uint16WaveformData;
}

function createWaveformData(audioBuffer, audioContext) {
  const rawData = audioBuffer.getChannelData(0);
  const blockSize = audioContext.sampleRate / 100.0; // 10 ms
  let samples = Math.floor(rawData.length / blockSize);
  samples = Math.min(samples);
  const waveformData = [];
  for (let i = 0; i < samples; i++) {
    let blockStart = blockSize * i;
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]);
    }
    waveformData.push(Math.round((sum / blockSize) * 10 * 65535)); // TODO instead calculate a scaling factor
  }
  return waveformData;
}
