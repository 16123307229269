import { observable } from "mobx";

export class Signal {
  value = 0;
  boxed = null;
  fastReactions = [];

  constructor(value) {
    this.value = value;
  }

  set(value) {
    this.value = value;
    this.runFastReactions();
    if (this.boxed) {
      this.boxed.set(value);
    }
  }

  changed() {
    if (!this.boxed) {
      this.boxed = observable.box(this.value);
    }
    return this.boxed.get();
  }

  runFastReactions() {
    for (const reaction of this.fastReactions) {
      reaction();
    }
  }

  fastReaction(f) {
    this.fastReactions.push(f);
    return () => {
      this.fastReactions = this.fastReactions.filter(r => !(r === f));
    };
  }
}
