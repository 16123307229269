import { createElement } from "react";
import { create } from "nano-css";
import { addon as addonRule } from "nano-css/addon/rule";
import { addon as addonCache } from "nano-css/addon/cache";
//import { addon as addonJsx } from "nano-css/addon/jsx";
import { addon as addonStyle } from "nano-css/addon/style";
import { addon as addonStyled } from "nano-css/addon/styled";
import { addon as addonGlobal } from "nano-css/addon/global";

const nano = create({
  h: createElement
});

addonRule(nano);
addonCache(nano);
// addonJsx(nano);
hackedJSX(nano);
addonStyle(nano);
addonStyled(nano);
addonGlobal(nano);

const { put, rule, jsx, style, styled, global } = nano;

export { nano, put, rule, jsx, style, styled, global };

function hackedJSX(renderer) {
  if (!renderer.cache) {
    addonCache(renderer);
  }

  renderer.jsx = function(fn, styles, block) {
    let className;
    const isElement = typeof fn === "string";

    // In dev mode emit CSS immediately so correct sourcemaps can be generated.
    if (process.env.NODE_ENV !== "production") {
      className = renderer.rule(styles, block);
    }

    const Component = function(props) {
      if (!className) {
        className = renderer.rule(styles, block);
      }

      let copy = props;

      const $as = copy.$as;
      const $ref = copy.$ref;
      let h = copy.h || renderer.h;

      if (process.env.NODE_ENV !== "production") {
        copy = renderer.assign({}, props);
      }

      const dynamicClassName = renderer.cache(props.css);
      delete copy.css;
      delete copy.$as;

      if (isElement || $as) {
        delete copy.h;
        delete copy.$ref;
        copy.ref = $ref;
      }

      copy.className = (props.className || "") + className + dynamicClassName;

      return isElement || $as ? h($as || fn, copy) : fn(copy);
    };

    if (process.env.NODE_ENV !== "production") {
      if (block) {
        Component.displayName = "jsx(" + block + ")";
      }
    }

    return Component;
  };
}
