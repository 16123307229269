function sentenceIdentityKey(sentence) {
  // TODO hasWarning not needed already in children?
  return (
    sentence.data.id +
    (sentence.data.hasWarning
      ? "W_" + childrenIdentityKeys(sentence)
      : childrenIdentityKeys(sentence))
  );
}

function warningIdentityKey(warning) {
  return "WN_" + childrenIdentityKeys(warning);
}

function segmentIdentityKey(segment) {
  return `SG_`;
  //return `SG_${segment.data.segmentTimestamp}`;
}

function cueIdentityKey(cue) {
  return `CU_`;
  //return `CU_${cue.data.cueTimestamp}`;
}

function speakerIdentityKey(speaker) {
  return `SP_${speaker.data.label}${speaker.id}`;
}

function chapterIdentityKey(chapter) {
  return `CH_${chapter.data.title}`;
}

function passageIdentityKey(passage) {
  return `PS_${passage.data.hint}`;
}

function wordGroupIdentityKey(wordGroup) {
  return "WG_" + childrenIdentityKeys(wordGroup) + wordGroup.data.note + "_GW";
}

function childrenIdentityKeys(element) {
  let result = "";
  const { children } = element;
  for (const child of children) {
    result += identityKey(child);
  }
  return result;
}

function atomIdentityKey(atom) {
  const data = atom.data;
  const text = data.text;
  return `_A_${text}`;
}

const typeMap = {
  CHAPTER: chapterIdentityKey,
  PASSAGE: passageIdentityKey,
  SPEAKER: speakerIdentityKey,
  SENTENCE: sentenceIdentityKey,
  WORD_GROUP: wordGroupIdentityKey,
  WARNING: warningIdentityKey,
  SEGMENT: segmentIdentityKey,
  CUE: cueIdentityKey,
  ATOM: atomIdentityKey
};

export function identityKey(elem) {
  const type = elem.type;
  const identityKeyFunction = typeMap[type];
  return identityKeyFunction(elem);
}
