import { MDBModal, MDBModalBody } from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { root } from "../app/app-root";

const modalState = observable({});

export function openTestingModal() {
  root.controls.disable();
  modalState.open = true;
}

export const TestingModal = observer(() => {
  const toggleModal = () => {
    modalState.open = !modalState.open;
    if (modalState.open) {
      root.controls.disable();
    } else {
      root.controls.enable();
    }
  };
  return (
    <MDBModal size="xl" isOpen={modalState.open} toggle={toggleModal}>
      <MDBModalBody></MDBModalBody>
    </MDBModal>
  );
});
