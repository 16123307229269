/** @jsx h */
import { _h } from "../lib/jsx/default-jsx";
import { CueGraphic, CuePath } from "./stylesystem/editorial";

export default ({ h = _h }) => (
  <CueGraphic h={h} viewBox="0 0 20 20">
    <CuePath
      h={h}
      d=" M 16.402344 8.144531 L 5.292969 0.539062 C 4.78125 0.1875 4.265625 0 3.835938 0 C 3.003906 0 2.488281 0.667969 2.488281 1.78125 L 2.488281 18.21875 C 2.488281 19.335938 3.003906 20 3.832031 20 C 4.261719 20 4.769531 19.8125 5.285156 19.460938 L 16.398438 11.855469 C 17.113281 11.367188 17.511719 10.707031 17.511719 10 C 17.511719 9.292969 17.117188 8.632812 16.402344 8.144531 Z M 16.402344 8.144531 "
    />
  </CueGraphic>
);
