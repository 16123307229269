const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿—–]/g;

class TimestampShifter {
  targetTimeIntervals = null;
  wordArray = null;
  originalStartTime = 0;
  originalEndTime = 0;
  targetStartTime = 0;
  targetEndTime = 0;
  startWordIndex = 0;
  endWordIndex = 0;
  doAble = false;

  constructor(rangeDefinition, wordArray, targetTimeIntervals) {
    this.wordArray = wordArray;
    this.targetTimeIntervals = targetTimeIntervals;
    this.originalStartTime = rangeDefinition.containingInterval.start;
    this.originalEndTime = rangeDefinition.containingInterval.end;
    this.targetStartTime = this.originalStartTime;
    this.targetEndTime = this.originalEndTime;
    this.startWordIndex = rangeDefinition.startWordIndex;
    this.endWordIndex = rangeDefinition.endWordIndex;
  }

  shiftStart(time) {
    this.targetStartTime = time;
    this.doAble = this.targetStartTime < this.originalEndTime;
  }

  shiftEnd(time) {
    this.targetEndTime = time;
    this.doAble = this.targetEndTime > this.originalStartTime;
  }

  doIt() {
    throw new Error("non implemented abstract");
  }
}

export class ChoppingShifter extends TimestampShifter {
  doIt() {
    const startPoints = this.targetTimeIntervals.startPoints;
    const endPoints = this.targetTimeIntervals.endPoints;
    startPoints[this.startWordIndex] = Math.max(
      this.targetStartTime,
      this.originalStartTime
    );
    endPoints[this.startWordIndex] = Math.min(
      this.targetEndTime,
      this.originalEndTime
    );
  }
}

export class InterpolatingShifter extends TimestampShifter {
  doIt() {
    if (!this.doAble) {
      //TODO throw
    }
    const duration = this.targetEndTime - this.targetStartTime;
    let words = this.wordArray.slice(
      this.startWordIndex,
      this.endWordIndex + 1
    );
    words = words.map(text => text.replace(punctuationRegex, ""));
    const wordLengths = words.map(word => word.length);
    const positions = [];
    let pos = 0;
    for (const len of wordLengths) {
      positions.push(pos);
      pos += len;
    }
    positions.push(pos);

    const charCount = wordLengths.reduce((a, b) => a + b, 0);

    const quanta = duration / charCount;
    const startPoints = this.targetTimeIntervals.startPoints;
    const endPoints = this.targetTimeIntervals.endPoints;
    const base = this.targetStartTime;

    words.forEach((_, index) => {
      const start = base + quanta * positions[index];
      const end = base + quanta * positions[index + 1];
      const targetIndex = this.startWordIndex + index;
      startPoints[targetIndex] = start;
      endPoints[targetIndex] = end;
    });
  }
}
