/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import { StyledGroup, SicAnnotation } from "../stylesystem/editorial";
import ElementTree from "./element-tree";
import { entityVersionsActions } from "../../app/app-root";
import { styled } from "../stylesystem/nano";
import { entityIdToString, stringToEntityId } from "../../lib/ids/id-utils";

const componentTypes = {
  normal: StyledGroup,
  vocab: styled(StyledGroup)({
    borderBottom: "2px solid #91de4b"
  }),
  tricky: styled(StyledGroup)({
    fontStyle: "cursive",
    backgroundColor: "#fff8a6",
    borderBottom: "2px solid #6bbdca"
  }),
  sic: styled(StyledGroup)({
    borderBottom: "2px solid #ffa100"
  })
};

const onWordGroupClick = (id, e) => {
  e.preventDefault();
  if (e.getModifierState("Meta")) {
    entityVersionsActions.openEntityVersionsDialogAction(id);
  }
};

const onWordGroupEntityClick = (idString, event) => {
  const id = stringToEntityId(idString);
  return onWordGroupClick(id, event);
};

window.onWordGroupEntityClick = onWordGroupEntityClick;

const WordGroup = _props => {
  const { h = _h } = _props;
  const { root: group } = _props;
  const { group_type: type, head = "", note = "" } = group.data;
  const id = group.id;
  const onClick = e => onWordGroupClick(id, e);
  onClick.alternate = `onWordGroupEntityClick("${entityIdToString(
    id
  )}", event)`;

  const GroupContainer = componentTypes[type] || StyledGroup;
  return (
    <GroupContainer h={h} className={"tooltippp"} onClick={onClick}>
      <ElementTree
        h={h}
        elements={group.children}
        groupType={type}
        withToolTip={false}
      />
      {note ? <span className="tooltiptext">{note}</span> : ""}
    </GroupContainer>
  );
};

export default WordGroup;
