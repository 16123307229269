export class NavigationPoint {
  navigatorKey = null;
  navigationId = 0; // TODO
  index = 0;
  position = 0;

  constructor(info) {
    this.navigatorKey = info.navigatorKey;
    this.navigationId = info.navigationId;
    this.index = info.index;
    this.position = info.position;
  }
}

export class Navigation {
  navigators = {};

  addNavigator(navigator) {
    if (!navigator.navigatorKey) {
      // TODO throw
    }
    this.navigators[navigator.navigatorKey] = navigator;
  }

  getNavigatorForKey(key) {
    return this.navigators[key];
  }

  next(navigationPoint) {
    if (!navigationPoint) {
      return null;
    }
    const navigator = this.getNavigatorForKey(navigationPoint.navigatorKey);
    const nextPoint = navigator.next(navigationPoint);
    return nextPoint;
  }

  prev(navigationPoint) {
    if (!navigationPoint) {
      return null;
    }
    const navigator = this.getNavigatorForKey(navigationPoint.navigatorKey);
    const prevPoint = navigator.prev(navigationPoint);
    return prevPoint;
  }

  nextClosest(navigationPoint, position) {
    if (!navigationPoint) {
      return null;
    }
    const navigator = this.getNavigatorForKey(navigationPoint.navigatorKey);
    const nextPoint = navigator.nextClosest(position);
    return nextPoint;
  }

  prevClosest(navigationPoint, position) {
    if (!navigationPoint) {
      return null;
    }
    const navigator = this.getNavigatorForKey(navigationPoint.navigatorKey);
    const nextPoint = navigator.prevClosest(position);
    return nextPoint;
  }
}

const LEADING = "LEADING";
const TRAILING = "TRAILING";
const MIDPOINT = "MIDPOINT";

export class TimelineNavigator {
  positions = null;
  intervals = null; // optional then positions are derived from intervals
  navigatorKey = null;
  durationFromIntervals = false; //otherwise duration from position between navigation positions
  edge = LEADING;

  static LEADING = LEADING;
  static TRAILING = TRAILING;
  static MIDPOINT = MIDPOINT;

  constructor(key) {
    this.navigatorKey = key;
  }

  setIntervals(intervals, edge = LEADING) {
    this.intervals = intervals;
    this.edge = edge;
    if (edge === LEADING) {
      this.positions = intervals.getStartPoints();
    } else if (edge === MIDPOINT) {
      this.positions = intervals.getMidPoints();
    } else {
      this.positions = intervals.getEndPoints();
    }
  }

  setPositions(positions) {
    this.positions = positions;
  }

  getPositionForIndex(index) {
    if (!this.positions) {
      // TODO throw
    }
    return this.positions.point(index);
  }

  getDurationForIndex(index) {
    if (this.checkValidIndex(index + 1)) {
      if (this.durationFromIntervals) {
        const interval = this.intervals.interval(index + 1);
        // TODO or throw?
      } else {
        return (
          this.getPositionForIndex(index + 1) - this.getPositionForIndex(index)
        );
      }
    }
  }

  checkValidIndex(index) {
    return this.positions.checkValidIndex(index);
  }

  navigationPoint(index) {
    let navigationPoint = null;

    if (this.positions) {
      if (this.checkValidIndex(index)) {
        const position = this.getPositionForIndex(index);
        navigationPoint = new NavigationPoint({
          navigatorKey: this.navigatorKey,
          index,
          position,
          navigationId: null
        }); // TODO navigationId
      }
    }
    return navigationPoint;
  }

  next(navigationPoint) {
    if (navigationPoint.navigatorKey !== this.navigatorKey) {
      // TODO throw
    }
    const nextNavigationPoint = this.navigationPoint(navigationPoint.index + 1);
    return nextNavigationPoint || navigationPoint;
  }

  prev(navigationPoint) {
    if (navigationPoint.navigatorKey !== this.navigatorKey) {
      // TODO throw
    }
    const nextNavigationPoint = this.navigationPoint(navigationPoint.index - 1);
    return nextNavigationPoint || navigationPoint;
  }

  nextClosest(position) {
    let index = this.positions.firstAfter(position);
    return this.navigationPoint(index);
  }

  prevClosest(position) {
    let index = this.positions.lastBeforeOrAt(position);
    return this.navigationPoint(index);
  }
}
