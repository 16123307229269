import { observable, computed, reaction, untracked, when, $mobx } from "mobx";
import { isArray, once } from "lodash";

function successor(id) {
  return typeof id === "number" ? id + 1 : 1;
}

const PRIMITIVE = "PRIMITIVE";
const AGGREGATE = "AGGREGATE";
const FUNCTION = "FUNCTION";

export const createBus = initialState => {
  const __map = {};
  let returnNullOnUndefined = true;

  class Boxed {
    @observable observed;
    type = PRIMITIVE;
    x = {};

    static box(value) {
      const b = new Boxed();
      b.set(value);
      return b;
    }

    @computed
    get value() {
      if (this.type === PRIMITIVE) {
        return this.observed;
      } else if (this.type === FUNCTION) {
        return this.observed();
      } else if (this.type === AGGREGATE) {
        const result = {};
        this.observed.forEach(path => {
          result[path] = get(path);
        });
        return result;
      } else {
        // default
        return this.observed;
      }
    }

    set(value) {
      this.observed = value;
    }

    getX() {
      return this.x;
    }

    setX(value) {
      this.x = value;
    }

    aggregate(paths) {
      this.type = AGGREGATE;
      this.observed = paths;
      //this.observed = successor(this.observed);
    }

    computeWith(fn) {
      this.observed = fn;
      this.type = FUNCTION;
    }
  }

  const boxed = key => {
    //TODO rearrange to make more efficient
    const box = __map[key] || Boxed.box(undefined);
    __map[key] = box;
    return box;
  };

  const get = key => {
    const result = boxed(key).value;
    if (returnNullOnUndefined && typeof result === "undefined") {
      return null;
    }
    return result;
  };

  const getUntracked = key => {
    return untracked(() => get(key));
  };

  const set = (key, value, emitGlobal = true) => {
    boxed(key).set(value);
    if (emitGlobal) {
      emit("*");
    }
  };

  const emit = (key, ...args) => {
    const box = boxed(key);
    if (args) {
      box.setX(args);
    }
    box.set(successor(box.value));
  };

  const subscribe = (name, callback, once = false) => {
    const box = boxed(name);
    const value = box.value;
    const sense = once ? () => box.value !== value : () => box.value;
    const cb = () => callback(...box.getX());
    const unsubscribe = once ? when(sense, cb) : reaction(sense, cb);
    return { unsubscribe };
  };

  const subscribeOnce = (name, callback) => {
    return subscribe(name, callback, true);
  };

  const channelIsEmpty = name => {
    const box = boxed(name);
    return box[$mobx].values.get("observed").observers.length === 0;
  };

  const touch = paths => {
    paths.forEach(path => {
      get(path);
    });
  };

  const onUpdate = (paths, callback = () => {}) => {
    if (isArray(paths)) {
      //const options = { equals: (a, b) => false, delay: 30 };
      const options = { equals: (a, b) => false };
      const unsubscribe = once(reaction(() => touch(paths), callback, options));
      return [{ unsubscribe }];
    }
    return onUpdate([paths], callback);
  };

  const aggregate = (key, paths) => {
    const box = boxed(key);
    box.aggregate(paths);
  };

  if (initialState) {
    for (const [key, value] of Object.entries(initialState)) {
      set(key, value);
    }
  }

  set("*", 1);

  return {
    get,
    getUntracked,
    set,
    onUpdate,
    aggregate,
    emit,
    subscribe,
    subscribeOnce,
    channelIsEmpty
  };
};
