import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { audioRegionActions } from "../app/app-root";
import { appBus } from "../app/app-root";

const dialogState = observable({});
dialogState.regionData = {};

export function openAudioRegionLabelDialog(regionData) {
  dialogState.regionData = regionData;
  dialogState.open = true;
}

export const AudioRegionLabelDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const regionData = dialogState.regionData;

  const handleLabelChange = event => {
    regionData.label = event.target.value;
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        Update Region Label
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div className="form-group">
            <label htmlFor="labelInput">Label</label>
            <input
              type="text"
              className="form-control"
              id="labelInput"
              value={regionData.label}
              onChange={handleLabelChange}
            />
          </div>
        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <>
          <MDBBtn
            color="info"
            onClick={() => {
              audioRegionActions.addUpdateAudioRegion(regionData);
              toggleModal();
            }}
          >
            Update
          </MDBBtn>
        </>
      </MDBModalFooter>
    </MDBModal>
  );
});
