import { runInAction } from "mobx";
import { BaseTimelineTracker } from "./base-timeline-tracker";
import { Signal } from "./signal";

const NO_INDEX = -1;

export class Tracker extends BaseTimelineTracker {
  notifyAllChanges() {
    runInAction(() => {
      const tracker = this.trackerEngine;
      this.notifyIsUnder(tracker.isUnderOldChangeIndex);
      this.notifyIsUnder(tracker.isUnderNewChangeIndex);
      this.notifyIsBefore(
        tracker.isBeforeChangeRangeStart,
        tracker.isBeforeChangeRangeEnd
      );
      this.notifyIsVisited(
        tracker.isVisitedChangeRangeStart,
        tracker.isVisitedChangeRangeEnd
      );
      this.notifyChange();
    });
  }

  makeSignal() {
    return new Signal(this.trackerEngine.vIdx);
  }

  notifyChange() {
    if (this._anyIsChanged) {
      this._anyIsChanged.set(this.trackerEngine.vIdx);
    }
  }

  notifySignal(signals, index) {
    if (index === NO_INDEX || !signals) {
      return;
    }
    const signal = signals[index];
    if (signal) {
      signal.set(this.trackerEngine.vIdx);
    }
  }

  notifySignalRange(signals, start, end) {
    if (!signals || start < 0 || end > signals.length) {
      return;
    }
    for (let i = start; i <= end; i++) {
      const signal = signals[i];
      if (signal) {
        signal.set(this.trackerEngine.vIdx);
      }
    }
  }
}
