import * as firebase from "firebase/app";
import "firebase/firestore";

export let db = null;

export function initDb() {
  const config = {
    apiKey: "AIzaSyCk7qf6Qbsq2oRwCel1g08st-su4pYDB_0",
    authDomain: "jiveworld.firebaseapp.com",
    projectId: "jiveworld"
  };
  firebase.initializeApp(config);
  db = firebase.firestore();
}

initDb();

export class DbPaths {
  key;
  _chaatDocRef = null;
  _scriptDocRef = null;
  _cuesDocRef = null;
  _audioRegionsDocRef = null;
  _audioAnalysisDocRef = null;
  _segmentAnalysisDocRef = null;
  _transcriptDocRef = null;
  _timestampsDocRef = null;
  _entitiesDocRef = null;
  _additionalEntitiesDocRef = null;
  _entityVersionsDocRef = null;
  _commentsDocRef = null;

  constructor(key) {
    this.key = key;
    this.db = db;
  }

  get rootRef() {
    return this.db;
  }

  get chaatDocRef() {
    if (!this._chaatDocRef) {
      this._chaatDocRef = this.rootRef
        .collection("Chaat__metadata")
        .doc(this.key);
    }
    return this._chaatDocRef;
  }

  get scriptDocRef() {
    if (!this._scriptDocRef) {
      this._scriptDocRef = this.rootRef
        .collection("Script__verbatim")
        .doc(this.key);
    }
    return this._scriptDocRef;
  }

  get cuesDocRef() {
    if (!this._cuesDocRef) {
      this._cuesDocRef = this.rootRef.collection("Chaat__cues").doc(this.key);
    }
    return this._cuesDocRef;
  }

  get audioRegionsDocRef() {
    if (!this._audioRegionsDocRef) {
      this._audioRegionsDocRef = this.rootRef
        .collection("Chaat__audioRegions")
        .doc(this.key);
    }
    return this._audioRegionsDocRef;
  }

  get audioAnalysisDocRef() {
    if (!this._audioAnalysisDocRef) {
      this._audioAnalysisDocRef = this.rootRef
        .collection("Chaat__audioAnalysis")
        .doc(this.key);
    }
    return this._audioAnalysisDocRef;
  }

  get segmentAnalysisDocRef() {
    if (!this._segmentAnalysisDocRef) {
      this._segmentAnalysisDocRef = this.rootRef
        .collection("Chaat__segmentAnalysis")
        .doc(this.key);
    }
    return this._segmentAnalysisDocRef;
  }

  get transcriptDocRef() {
    if (!this._transcriptDocRef) {
      this._transcriptDocRef = this.rootRef
        .collection("Chaat__speechTranscripts")
        .doc(this.key);
    }
    return this._transcriptDocRef;
  }

  get timestampsDocRef() {
    if (!this._timestampsDocRef) {
      this._timestampsDocRef = this.rootRef
        .collection("Chaat__timestamps")
        .doc(this.key);
    }
    return this._timestampsDocRef;
  }

  get entitiesDocRef() {
    if (!this._entitiesDocRef) {
      this._entitiesDocRef = this.rootRef
        .collection("Entity__entities")
        .doc(this.key);
    }
    return this._entitiesDocRef;
  }

  get additionalEntitiesDocRef() {
    if (!this._additionalEntitiesDocRef) {
      this._additionalEntitiesDocRef = this.rootRef
        .collection("Entity__additionalEntities")
        .doc(this.key);
    }
    return this._additionalEntitiesDocRef;
  }

  get entityVersionsDocRef() {
    if (!this._entityVersionsDocRef) {
      this._entityVersionsDocRef = this.rootRef
        .collection("Entity__entityVersions")
        .doc(this.key);
    }
    return this._entityVersionsDocRef;
  }

  get commentsDocRef() {
    if (!this._commentsDocRef) {
      this._commentsDocRef = this.rootRef
        .collection("Entity__discussions")
        .doc(this.key);
    }
    return this._commentsDocRef;
  }
}
