/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import Element from "./element";

const ElementTree = ({ elements = [], ...props }) => {
  const { h = _h } = props;
  return elements.map(element => {
    if (!element) {
      return null;
    }
    return (
      <Element root={element} key={`${element.type + element.id}`} {...props} />
    );
  });
};

export default ElementTree;
