import { computed, observable, reaction } from "mobx";
import { Tracker } from "../tracker/tracker";
import { EntityListBuilder } from "../entity/entity-list-builder";
import { JWContentLayer } from "./jw-content-layer";
import { isEmpty } from "lodash";

export class EntityContentLayer extends JWContentLayer {
  @observable.ref scriptEntitiesDict = {};
  @observable.ref additionalEntitiesDict = {};
  trackers = {};
  entityArrays = {};
  entityLists = {};

  constructor(baseContentLayer) {
    super(baseContentLayer);

    const options = { equals: (a, b) => false };
    this.disposers.push(
      reaction(
        () => this.atomEntityList,
        () => {
          this.entityLists = {};
        },
        options
      )
    );
    this.disposers.push(
      reaction(
        () => this.entitiesDict,
        () => {
          this.entityArrays = {};
          this.entityLists = {};
        },
        options
      )
    );
  }

  @computed
  get entitiesDict() {
    // TODO fix undefined bullshit
    if (this.additionalEntitiesDict && !isEmpty(this.additionalEntitiesDict)) {
      console.log("MERGING ADDITIONAL ENTITIES");
      return { ...this.additionalEntitiesDict, ...this.scriptEntitiesDict };
    }
    return this.scriptEntitiesDict;
  }

  get atoms() {
    return this.baseContentLayer.atoms;
  }

  get atomEntityList() {
    return this.baseContentLayer.atomEntityList;
  }

  get atomTimeIntervals() {
    return this.baseContentLayer.atomTimeIntervals;
  }

  get atomTracker() {
    return this.baseContentLayer.atomTracker;
  }

  getEntityArrayOfType(type) {
    if (
      !this.entityArrays ||
      this.entityArrays.projectKey !== this.projectKey
    ) {
      this.entityArrays = { projectKey: this.projectKey };
      const t1 = Date.now();
      for (const entity of Object.values(this.entitiesDict)) {
        const key = entity.type;
        let entityArray = this.entityArrays[key];
        if (!entityArray) {
          entityArray = [];
          this.entityArrays[key] = entityArray;
        }
        entityArray.push(entity);
      }
      console.log("entity arrays of types build time: " + (Date.now() - t1));
    }
    let entities = this.entityArrays[type];
    return entities ? entities : [];
  }

  getEntityListForType(type) {
    let touch = this.atomEntityList; // TODO needed?
    touch = this.entitiesDict; //touch
    if (this.entityLists.projectKey !== this.projectKey) {
      this.entityLists = { projectKey: this.projectKey };
    }
    let entityList = this.entityLists[type];
    if (!entityList) {
      const entityBuilder = new EntityListBuilder(
        this.getEntityArrayOfType(type),
        type,
        this.atomEntityList,
        this.atomTimeIntervals
      );
      entityList = entityBuilder.getEntityList();
      this.entityLists[type] = entityList;
    }
    return entityList;
  }

  trackerForEntityType(type) {
    let tracker = this.trackers[type];
    const entityList = this.getEntityListForType(type);
    if (tracker) {
      if (tracker.projectKey === this.projectKey) {
        tracker.setEntities(entityList);
        return tracker;
      } else {
        tracker.dispose();
      }
    }
    tracker = new Tracker(() => this.atomTracker.anyIsChangedSignal.changed());
    tracker.setEntities(entityList);
    tracker.projectKey = this.projectKey;
    this.trackers[type] = tracker;
    return tracker;
  }

  getTrackers(types) {
    const result = {};
    for (const type of types) {
      result[type] = this.trackerForEntityType(type);
    }
    return result;
  }

  timesForEntityType(type) {
    const entityList = this.getEntityListForType(type);
    return entityList.getSortedTimes();
  }

  atomRelationsForEntityType(type) {
    const entityList = this.getEntityListForType(type);
    return entityList.getEntityAtomRelations();
  }

  updateOn() {
    // touch the observables to sense data update
    return this.entitiesDict;
  }
}
