/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import parseUnderscores from "../../lib/parse-underscores";
import { ChapterHeading, ChapterTranslation } from "../stylesystem/editorial";

const ChapterTitle = ({ h = _h, title, translation }) => {
  return (
    <div>
      <ChapterHeading h={h}>{title}</ChapterHeading>
      {translation && (
        <ChapterTranslation h={h} {...parseUnderscores(translation)} />
      )}
    </div>
  );
};

export default ChapterTitle;
