import { computed, observable, reaction, runInAction } from "mobx";
import {
  WrappedAudioElement,
  wrappedAudioElement
} from "./wrapped-audio-element.js";

export const audioSourceKeyToUIText = {
  CHAAT_SOURCE_AUDIO: "Chaat Audio",
  AUDIO: "Final Audio"
};

const audioSourceKeySortOrder = ["CHAAT_SOURCE_AUDIO", "AUDIO"];

const DEFAULT_SOURCE_KEY = "AUDIO";
const emptyAudioSource = new WrappedAudioElement();

export class MultiAudioSource {
  key = "";
  @observable.ref audioSourceDefinitions = {};
  @observable.ref currentAudioSourceKey = DEFAULT_SOURCE_KEY;
  @observable.ref currentAudioElement = emptyAudioSource;

  constructor() {
    reaction(
      () => this.computedAudioElement,
      () => this.configureCurrentAudioElement()
    );
  }

  setAudioSourceDefinitions(key, sources) {
    this.key = key;
    runInAction(() => {
      this.audioSourceDefinitions = sources;
      this.currentAudioSourceKey = DEFAULT_SOURCE_KEY;
    });
  }

  setCurrentAudioSourceKey(sourceKey) {
    this.currentAudioSourceKey = sourceKey;
  }

  @computed({ keepAlive: true })
  get audioSourceElements() {
    const result = {};
    for (const [key, url] of Object.entries(this.audioSourceDefinitions)) {
      result[key] = new WrappedAudioElement(url);
    }
    return result;
  }

  @computed({ keepAlive: true })
  get sourceKeys() {
    const availableSources = new Set(Object.keys(this.audioSourceDefinitions));
    return audioSourceKeySortOrder.filter(key => availableSources.has(key));
  }

  @computed({ keepAlive: true })
  get computedAudioElement() {
    const result = this.audioSourceElements[this.currentAudioSourceKey];
    if (result) {
      return result;
    }
    return emptyAudioSource;
  }

  configureCurrentAudioElement() {
    if (this.computedAudioElement !== emptyAudioSource) {
      this.computedAudioElement.currentTime = this.currentAudioElement.currentTime;
      this.computedAudioElement.playbackRate = this.currentAudioElement.playbackRate;
    }
    this.currentAudioElement = this.computedAudioElement;
  }

  audioElementFunction = () => this.currentAudioElement;
}
