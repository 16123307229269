const parseUnderscores = (text = "") => {
  if (!text) {
    return null;
  }
  return {
    dangerouslySetInnerHTML: {
      __html: text.replace(/_(.+)_/, "<i>$1</i>")
    }
  };
};

export default parseUnderscores;
