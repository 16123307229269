import * as React from "react";
import createListener from "keyboardist";

const listeners = {
  keydown: createListener("keydown"),
  keyup: createListener("keyup")
};

export class Keyboard extends React.PureComponent {
  subs = [];
  enabled = true;
  keyboardListener;

  static defaultProps = {
    eventName: "keydown",
    bindings: {},
    monitor: null
  };

  constructor(props) {
    super(props);
    this.keyboardListener = listeners[props.eventName];
  }

  componentDidMount() {
    const { bindings, monitor } = this.props;

    Object.keys(bindings).forEach(eventName => {
      const callback = bindings[eventName];
      const subscription = this.keyboardListener.subscribe(eventName, callback);
      this.subs.push(subscription);
    });

    if (monitor) {
      this.keyboardListener.setMonitor(monitor);
    }
  }

  componentWillUnmount() {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }

  enable() {
    if (!this.enabled) {
      this.keyboardListener.startListening();
      this.enabled = true;
    }
  }

  disable() {
    if (this.enabled) {
      this.keyboardListener.stopListening();
      this.enabled = false;
    }
  }

  render() {
    return null;
  }
}
