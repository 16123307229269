import { NavigationTrackGadget } from "./navigation-gadget";
import { SortedIntervals } from "../../lib/sorted/sorted-intervals";
import { observable, reaction } from "mobx";

export class AudioRegionSelectionTrackGadget extends NavigationTrackGadget {
  @observable notifyRegionSelect = 0;
  transportState = null;

  drawHandle(ctx, index) {
    super.drawHandle(ctx, index);
    this.drawHandleEdges(ctx, index, this.height);
  }

  setTransportState(transportState) {
    if (this.transportState === transportState) {
      return;
    }
    this.transportState = transportState;
  }

  get selectionIntervals() {
    return this.handleIntervals;
  }

  get selectionInterval() {
    if (this.selectionIntervals.length) {
      return this.selectionIntervals.interval(0);
    }
    return null;
  }

  setSelectionInterval(interval) {
    const newSelectionIntervals = new SortedIntervals({
      startPoints: [interval.start],
      endPoints: [interval.end]
    });
    this.setHandleIntervals(newSelectionIntervals);
    this.notifyRegionSelect++;
  }

  clearSelection() {
    this.setHandleIntervals(new SortedIntervals());
    this.notifyRegionSelect++;
  }

  onRegionSelect(f) {
    this.disposers.push(
      reaction(
        () => this.notifyRegionSelect,
        () => f(this, this.selectionInterval)
      )
    );
  }

  handleMouseClickAtTime(x, y, time, e) {
    if (!e.getModifierState("Shift")) {
      return;
    }
    const audioPosition = this.transportState.audioPosition;

    let selection = this.selectionInterval;
    if (selection) {
      selection =
        time > selection.start
          ? { start: selection.start, end: time }
          : { start: time, end: selection.end };
    } else {
      selection =
        time < audioPosition
          ? { start: time, end: audioPosition }
          : { start: audioPosition, end: time };
    }
    this.setSelectionInterval(selection);
    return true;
  }
}
