import React from "react";

// how close to the bottom has the element to be before auto-scrolling.
const LINCHPIN_SCROLLING_TRESHOLD = window.innerHeight / 3;

/**
 * Component helper to keep elements scrolled into view.
 * It creates a React reference object and passes it down
 * through the render prop pattern.
 *
 * Whenever the `active` prop becomes true, it will check
 * for the element offset position relative to the viewport
 * and if it's bellow `LINCHPIN_SCROLLING_TRESHOLD` it will get
 * scrolled into the middle using the browser `scrollIntoView` API.
 *
 * Usage:
 * <Linchipin active={true|false}>
 * {refProps=>(
 *  <Sentence {...refProps}>{...}</Sentence>
 * )}
 * </Linchpin>
 */
export default class Linchpin extends React.PureComponent {
  // Passing this reference object down we can access the child element's DOM object
  __ref = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active && this.props.active) {
      const height = window.innerHeight;
      const elem = this.__ref.current.parentElement;
      const top = elem.offsetTop;
      const offset = height - top;
      if (offset < LINCHPIN_SCROLLING_TRESHOLD) {
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  render() {
    return this.props.children({ ref: this.__ref });
  }
}
