import { nano, styled, global } from "./nano";
import {
  SpeakerLabelCore,
  WordTextCore,
  WordWrapperCore,
  SegmentCore,
  ChapterContainerCore,
  ChapterHeadingCore,
  ChapterTranslationCore,
  SmallCueGraphicCore,
  SmallCuePathCore,
  CueGraphicCore,
  CuePathCore,
  PassageCore,
  HintCore,
  RedWarningCore,
  YellowWarningCore,
  GroupCore,
  SicAnnotationCore
} from "./styles-cores";

export function setCursorColor(color) {
  global({
    ".under-cursor": {
      borderBottom: `2px solid ${color} !important`
    }
  });
}

export function setSelectionColor(color) {
  global({
    ".selected": {
      backgroundColor: `${color} !important`
    }
  });
}

function nanoStyled(def) {
  return styled(def[0])(def[1]);
}

export const SpeakerLabelSpan = nanoStyled(SpeakerLabelCore);
export const WordWrapper = nanoStyled(WordWrapperCore);
export const WordText = nanoStyled(WordTextCore);
export const SegmentSpan = nanoStyled(SegmentCore);
export const ChapterContainer = nanoStyled(ChapterContainerCore);
export const ChapterHeading = nanoStyled(ChapterHeadingCore);
export const ChapterTranslation = nanoStyled(ChapterTranslationCore);
export const SmallCueGraphic = nanoStyled(SmallCueGraphicCore);
export const SmallCuePath = nanoStyled(SmallCuePathCore);
export const CueGraphic = nanoStyled(CueGraphicCore);
export const CuePath = nanoStyled(CuePathCore);
export const StyledPassage = nanoStyled(PassageCore);
export const StyledHint = nanoStyled(HintCore);
export const RedWarning = nanoStyled(RedWarningCore);
export const YellowWarning = nanoStyled(YellowWarningCore);
export const StyledGroup = nanoStyled(GroupCore);
export const SicAnnotation = nanoStyled(SicAnnotationCore);
