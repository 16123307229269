import React from "react";
import { audioTransport, root } from "../app/app-root";

export class AudioComponent extends React.Component {
  __audioSource = React.createRef();

  componentDidMount(prevProps) {
    // audioTransport.setAudioSource(this.__audioSource);
    // root.setAudioSource(this.__audioSource);
  }

  render() {
    return null;
    // const audioURL = root.audioURL;
    // console.log("audioURL: " + audioURL);
    // return (
    //   <audio ref={this.__audioSource}>
    //     <source src={audioURL} type="audio/wav" />
    //     <p>Your browser doesn&apos;t support HTML5 audio</p>
    //   </audio>
  }
}
