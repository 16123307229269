import React from "react";
import { Keyboard } from "../lib/keyboardist/keyboard";

import {
  player,
  transportState,
  appBus,
  root,
  cueActions,
  wordGroupActions,
  audioRegionActions
} from "../app/app-root";
import { projectDiffPatch } from "../lib/ids/project-diff-patch";
import { setCursorColor } from "./stylesystem/editorial";
import { openTestingModal } from "./testing-modal";

export class ChaatControls extends React.Component {
  keyboardEl;

  componentDidMount() {
    root.controls = this;
  }

  componentWillUnmount() {
    root.controls = null;
  }

  enable() {
    console.log("ENABLING GLOBAL KB");
    this.keyboardEl.enable();
  }

  disable() {
    console.log("DISABLING GLOBAL KB");
    this.keyboardEl.disable();
  }

  render() {
    return (
      <>
        <Keyboard
          ref={el => (this.keyboardEl = el)}
          bindings={{
            Space: () => {
              player.togglePlay();
            },
            "Ctrl+Space": () => {
              player.playSection();
            },
            KeyQ: () => {
              player.playSection();
            },
            Enter: () => {
              player.rewind();
            },
            "Shift+Enter": () => {
              player.playPeekBack();
            },
            KeyX: () => {
              player.playPeek();
            },
            "Shift+KeyX": () => {
              player.playPeekBack();
            },
            Up: () => {
              player.adjustPlaybackRateAction(1);
            },
            Down: () => {
              player.adjustPlaybackRateAction(0);
            },
            "Shift+Left": () => {
              player.seek(transportState.audioPosition - 1500);
            },
            "Shift+Right": () => {
              player.seek(transportState.audioPosition + 1500);
            },
            "Alt+Left": () => {
              player.nudge(-20);
            },
            "Alt+Right": () => {
              player.nudge(20);
            },
            Left: () => {
              player.prevClosest();
            },
            Right: () => {
              player.nextClosest();
            },
            Equal: () => {
              appBus.emit("timeZoom", 1);
            },
            NumpadAdd: () => {
              appBus.emit("timeZoom", 1);
            },
            Plus: () => {
              appBus.emit("timeZoom", 1);
            },
            Minus: () => {
              appBus.emit("timeZoom", 0);
            },
            NumpadSubtract: () => {
              appBus.emit("timeZoom", 0);
            },
            KeyU: () => {
              appBus.emit("tracksToggleEnabled");
            },
            KeyC: () => {
              cueActions.addCue();
            },
            "Shift+KeyC": () => {
              cueActions.removeCue();
            },
            KeyA: () => {
              cueActions.addShiftCue();
            },
            KeyS: () => {
              cueActions.addShiftEndCue();
            },
            KeyK: () => {
              root.enableKerning(1000);
            },
            "Shift+KeyK": () => {
              player.kerningEnable(false);
            },
            Escape: () => {
              appBus.emit("deselect");
            },
            KeyR: () => {
              appBus.emit("audioRegionAddUpdate");
            },
            "Shift+KeyR": () => {
              appBus.emit("audioRegionRemove");
            },
            KeyL: () => {
              audioRegionActions.audioRegionLabelDialogAction();
            },
            KeyE: () => {
              root.withEverything();
            },
            // KeyV: () => {
            //   wordGroupActions.wordGroupDialogAction("vocab");
            // },
            // KeyT: () => {
            //   wordGroupActions.wordGroupDialogAction("tricky");
            // },
            // KeyI: () => {
            //   wordGroupActions.wordGroupDialogAction("sic");
            // },
            Digit0: () => {
              openTestingModal();
              //wordGroupActions.wordGroupDialogAction();
              // wordGroupActions.testAddWordGroup();
              // projectDiffPatch(root.projectKey);
            }
          }}
        />
      </>
    );
  }
}
