import { root } from "./app-root";
import { SortedIntervals } from "../lib/sorted/sorted-intervals";
import {
  ChoppingShifter,
  InterpolatingShifter
} from "../lib/timestamp-shifter";
import { runInAction } from "mobx";

const LEFT = -1;
const RIGHT = 1;

const LINEAR_METHOD = 1;
const HARD_TIMESTAMPED = 2;
const OTHER_METHOD = 3;

export class LocalTimestamper {
  contentLayer;
  wordTimeIntervals;
  words;
  notchTimeIntervals;
  interpolatedTimeIntervals;
  unsupportedTimeIntervals;
  silenceTimeIntervals;

  mountData() {
    const contentLayer = root.baseContentLayer;
    this.wordTimeIntervals = contentLayer.atomTimeIntervals;
    this.words = contentLayer.atoms;
    this.notchTimeIntervals = contentLayer.notchTimeIntervals;
    this.interpolatedTimeIntervals = contentLayer.interpolatedTimeIntervals;

    // TODO
    this.unsupportedTimeIntervals = new SortedIntervals();
    this.silenceTimeIntervals = new SortedIntervals();
    this.contentLayer = contentLayer;
  }

  interpolateWithCue(cuePosition, cueTimestamp) {
    this.mountData();
    const rightShifter = this.buildShifter(cuePosition, RIGHT);
    const leftShifter = this.buildShifter(cuePosition - 1, LEFT);

    if (!rightShifter || !leftShifter) {
      return;
    }

    // TODO use silences to create different left and right shift times
    const leftShiftTime = cueTimestamp;
    const rightShiftTime = leftShiftTime;

    leftShifter.shiftEnd(leftShiftTime);
    rightShifter.shiftStart(rightShiftTime);
    if (!leftShifter.doAble || !rightShifter.doAble) {
      return;
    }
    leftShifter.doIt();
    rightShifter.doIt();

    runInAction(() => {
      this.contentLayer.atomStartTimes = this.wordTimeIntervals.startPoints.slice();
      this.contentLayer.atomEndTimes = this.wordTimeIntervals.endPoints.slice();
    });
  }

  buildShifter(wordIndex, direction) {
    const type = this.previousMethod(wordIndex);
    if (type === LINEAR_METHOD) {
      const rangeDefinition = this.findLinearInterpolationRange(
        wordIndex,
        direction
      );
      return new InterpolatingShifter(
        rangeDefinition,
        this.words,
        this.wordTimeIntervals
      );
    }

    if (type === OTHER_METHOD) {
      return null;
    }

    const containingInterval = this.wordTimeIntervals.interval(wordIndex);
    const startWordIndex = wordIndex;
    const endWordIndex = wordIndex;
    return new ChoppingShifter(
      { containingInterval, startWordIndex, endWordIndex },
      this.words,
      this.wordTimeIntervals
    );
  }

  findLinearInterpolationRange(wordIndex, direction) {
    const startWordIndex = wordIndex;
    let endWordIndex = startWordIndex;
    let checkMidpoint = this.midPoint(wordIndex);

    function contains(point, interval) {
      return point >= interval.start && point < interval.end;
    }

    const containingIntervalIdx = this.interpolatedTimeIntervals.containing(
      checkMidpoint
    );
    const containingInterval = this.interpolatedTimeIntervals.interval(
      containingIntervalIdx
    );
    while (
      contains(this.midPoint(endWordIndex + direction), containingInterval)
    ) {
      endWordIndex += direction;
    }

    return startWordIndex < endWordIndex
      ? { containingInterval, startWordIndex, endWordIndex }
      : {
          containingInterval,
          startWordIndex: endWordIndex,
          endWordIndex: startWordIndex
        };
  }

  midPoint(wordIndex) {
    const wordInterval = this.wordTimeIntervals.interval(wordIndex);
    return (wordInterval.start + wordInterval.end) / 2;
  }

  previousMethod(wordIndex) {
    const midPoint = this.midPoint(wordIndex);
    if (this.interpolatedTimeIntervals.containing(midPoint) >= 0) {
      return LINEAR_METHOD;
    }

    if (this.unsupportedTimeIntervals.containing(midPoint) >= 0) {
      return OTHER_METHOD;
    }
    return HARD_TIMESTAMPED;
  }
}
