import React from "react";
import Layout from "./layout";
import { root } from "../app/app-root";
import { AudioRegionLabelDialog } from "../components/audio-region-label-dialog";
import { EntityVersionsDialog } from "../components/entity-versions-dialog";
import { CheatSheetModal } from "../components/cheat-sheet-modal";
import { WordGroupDialog } from "../components/word-group-dialog";
import { TestingModal } from "../components/testing-modal";

export default ({ match }) => {
  // this value comes from the URL, provided by react-router
  const {
    params: { projectKey = null }
  } = match;
  root.load(projectKey);
  return (
    <div>
      <Layout />
      <AudioRegionLabelDialog />
      <EntityVersionsDialog />
      <CheatSheetModal />
      <WordGroupDialog />
      <TestingModal />
    </div>
  );
};
