import React from "react";
import { ScriptRoot } from "../components/elements/script-root";
//import Element from '../components/elements/element';

export default () => {
  return (
    <>
      <div style={{ height: 250, vw: 100 }}>&nbsp;</div>
      <ScriptRoot />
    </>
  );
};
