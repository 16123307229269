import { getContentPosition } from "../ids/positional-ids";

export const EMPTY_ENTITY = {};

export function overrideAtomData(atomData, atomEntityList) {
  const positionalMapping = atomEntityList.positionalMapping;
  return {
    ...atomEntityList,
    getEntityData: id => atomData[getContentPosition(positionalMapping, id)],
    getEntityDataFromIndex: index => atomData[index]
  };
}

export function overrideEntityData(entityData, entityList) {
  const getIndex = entityList.getIndex;
  return {
    ...entityList,
    getEntityData: id => entityData[getIndex(id)],
    getEntityDataFromIndex: index => entityData[index]
  };
}

export function mergeEntityData(entityData, entityList) {
  const getIndex = entityList.getIndex;
  const getEntityDataFromIndex = entityList.getEntityDataFromIndex;
  return {
    ...entityList,
    getEntityData: id => {
      const index = getIndex(id);
      return { ...getEntityDataFromIndex(index), ...entityData[index] };
    },
    getEntityDataFromIndex: index => {
      return { ...getEntityDataFromIndex(index), ...entityData[index] };
    }
  };
}

export function entityIdRangeToIndexRange(entityList, range) {
  if (!range) {
    return range;
  }
  let start = entityList.getIndex(range.start);
  let end = entityList.getIndex(range.end);
  [start, end] = start < end ? [start, end] : [end, start];
  return { start, end };
}

export function forEachEntityIdInRange(entityList, range, f) {
  // TODO decide if range non-inclusive end
  if (!range) {
    return range;
  }
  range = entityIdRangeToIndexRange(entityList, range);
  const end = range.end;
  for (let index = range.start; index <= end; index++) {
    const entityId = entityList.getId(index);
    f(entityId);
  }
}

export function entityDataForIndexRange(entityList, range) {
  const result = [];
  const end = range.end;
  for (let index = range.start; index <= end; index++) {
    result.push(entityList.getEntityDataFromIndex(index));
  }
  return result;
}

export function entityDataForIdRange(entityList, range) {
  range = entityIdRangeToIndexRange(entityList, range);
  return entityDataForIndexRange(entityList, range);
}
