/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import Insert from "../cue-insert-mark.js";
import { root, chaat, player } from "../../app/app-root";
import { appBus } from "../../app/app-root";
import { WordWrapper, WordText } from "../stylesystem/editorial";
import { entityIdToString, stringToEntityId } from "../../lib/ids/id-utils";

const onWordClick = (id, e) => {
  e.preventDefault();
  if (e.getModifierState("Alt") || e.getModifierState("Ctrl")) {
    appBus.emit("cueInsertionPoint", id);
  } else if (e.getModifierState("Shift")) {
    root.atomRangeSelectToEnd(id);
  } else {
    player.seekEntity(id);
  }
};

const onWordEntityClick = (idString, event) => {
  const id = stringToEntityId(idString);
  onWordClick(id, event);
};

window.onWordEntityClick = onWordEntityClick;

const WordRender = ({
  h = _h,
  isUnderCursor,
  // isVisited,
  isCueInsert,
  root: word
}) => {
  const id = word.id;
  const onClick = e => onWordClick(id, e);
  onClick.alternate = `onWordEntityClick("${entityIdToString(id)}", event);`;
  // TODO use className
  const className = isUnderCursor ? "under-cursor" : "";

  return (
    <WordWrapper h={h} onClick={onClick}>
      {isCueInsert ? (
        <h.Fragment>
          {" "}
          <Insert h={h} />{" "}
        </h.Fragment>
      ) : null}
      <WordText h={h} className={className} id={entityIdToString(word.id)}>
        {word.data.text}
      </WordText>
      <span>&nbsp;</span>
    </WordWrapper>
  );
};

const WordCursorDriver = ({ h = _h, root: word }) => {
  const isCueInsertPoint = word.id === chaat.cueInsertAtomId;
  const isUnderCursor = chaat.atomTracker.isUnder(word.id);

  return (
    <WordRender
      h={h}
      isUnderCursor={isUnderCursor}
      root={word}
      isCueInsert={isCueInsertPoint}
    />
  );
};

export default WordCursorDriver;
