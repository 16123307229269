import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

const dialogState = observable({});
dialogState.entityVersions = [];

export function openEntityVersionsDialog(entityVersions) {
  dialogState.entityVersions = entityVersions;
  dialogState.open = true;
}

const entityUpdateString = update => {
  let valueString = "";
  if (update.value instanceof Array) {
    valueString = update.value.join(" ");
  } else {
    valueString = update.value.toString();
  }
  return `${update.property.toUpperCase()} = ${valueString}`;
};

const EntityUpdates = ({ entityVersion }) => {
  let result = "";
  return (
    <>
      <p>
        <div>{`Version ${entityVersion.version} changes:`}</div>
        {entityVersion.updates.map(update => (
          <> {entityUpdateString(update)} </>
        ))}
      </p>
    </>
  );
};

export const EntityVersionsDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const entityVersions = dialogState.entityVersions;

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        Entity Updates
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div>
            {entityVersions.map(version => (
              <EntityUpdates entityVersion={version} />
            ))}
          </div>
        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <>
          <MDBBtn
            color="info"
            onClick={() => {
              toggleModal();
            }}
          >
            Close
          </MDBBtn>
        </>
      </MDBModalFooter>
    </MDBModal>
  );
});
