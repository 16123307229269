import { TrackGadget } from "./track-gadget";
import { observable, reaction } from "mobx";
import { SortedIntervals } from "../../lib/sorted/sorted-intervals";
import * as colors from "../stylesystem/colors";

export class NavigationTrackGadget extends TrackGadget {
  @observable notifyNavigationSelect = 0;
  lastNavigationSelect = -1;
  lastTimeSelect = 0;

  handleIntervals = null;
  doesRollover = true;
  showHandles = true;
  extendNavigationLines = 0;
  extendNavigationLinesOnRollover = false;
  handleFillStyle = "#000000";
  handleFillStyleOverride = null;
  rolloverHandleFillStyle = colors.lightYellow;
  navigationIndicatorFillStyle = null;
  navigationLinesStyle = null;
  navigationTimePoints = null;

  navigatorKey = null;
  navigation = null;
  rolloverState = null;

  constructor() {
    super();
    this.handleIntervals = new SortedIntervals();
  }

  setHandleIntervals(intervals) {
    if (this.handleIntervals === intervals) {
      return;
    }
    this.handleIntervals = intervals;
    this.requestFullRedraw();
  }

  getIntervalIndexForTime(time) {
    // TODO no need for method?
    return this.handleIntervals.containing(time);
  }

  setNavigation(navigation) {
    if (this.navigation === navigation) {
      return;
    }
    this.navigation = navigation;
    this.disposers.push(
      reaction(
        () => this.navigation.navigationPoint,
        () => this.handleChangeNavigationPoint()
      )
    );
  }

  handleChangeNavigationPoint() {
    if (
      this.navigation.navigationPoint &&
      this.navigation.navigationPoint.navigatorKey === this.navigatorKey
    ) {
      this.requestForegroundRedraw();
    }
  }

  getHandleTimeInterval(index) {
    return this.handleIntervals.interval(index);
  }

  drawHandle(ctx, index) {
    this.drawSimpleHandle(ctx, index);
  }

  drawSimpleHandle(ctx, index) {
    const interval = this.getHandleTimeInterval(index);
    this.drawTimerangeRect(ctx, interval, this.height);
  }

  drawHandleOutline(ctx, index) {
    const interval = this.getHandleTimeInterval(index);
    this.drawTimerangeRectOutline(ctx, interval, this.height);
  }

  drawTextHandle(ctx, index, text) {
    const interval = this.getHandleTimeInterval(index);
    this.drawTextInTimeInterval(ctx, interval, text);
  }

  drawHandleEdges(ctx, index, pixelHeight) {
    const interval = this.getHandleTimeInterval(index);
    // TODO use pixelHeight?
    this.drawTimerangeEdges(ctx, interval, this.height);
  }

  drawHandles(ctx) {
    const range = this.handleIntervals.rangeIntersecting(
      this.canvasStartTime,
      this.canvasStartTime + this.canvasTimeExtent
    );
    if (range) {
      const rangeStart = range.start;
      const rangeEnd = range.end;

      // TODO intervals are end inclusive or exclusive?
      let currentFillStyle = this.handleFillStyle;
      for (let idx = rangeStart; idx < rangeEnd; idx++) {
        if (this.handleFillStyleOverride) {
          const fillStyle = this.handleFillStyleOverride(idx);
          if (fillStyle && fillStyle !== currentFillStyle) {
            currentFillStyle = fillStyle;
            ctx.fillStyle = fillStyle;
          } else if (!fillStyle && currentFillStyle !== this.handleFillStyle) {
            currentFillStyle = this.handleFillStyle;
            ctx.fillStyle = this.handleFillStyle;
          }
        }
        this.drawHandle(ctx, idx);
      }
    }
  }

  drawNavigationLine(ctx, index) {
    const x = this.timeToCanvasPixelX(this.getNavigationTime(index));
    const len = this.extendNavigationLines;
    if (len > 0) {
      ctx.moveTo(x, this.originY);
      ctx.lineTo(x, this.originY + len);
    } else {
      ctx.moveTo(x, this.bottomY);
      ctx.lineTo(x, this.bottomY - len);
    }
    ctx.stroke();
  }

  drawBackgroundLayer(ctx) {
    if (this.showHandles) {
      ctx.save();
      ctx.fillStyle = this.handleFillStyle;
      this.drawHandles(ctx);
      ctx.restore();
    }
  }

  get showNavigation() {
    return this.navigatorKey;
  }

  drawForegroundLayer(ctx) {
    if (this.showNavigation) {
      this.drawNavigationIndicator(ctx);
    }
    this.drawRolloverHighlight(ctx);
  }

  getVisibleNavigationIndex() {
    const navigationPoint = this.navigation.navigationPoint;
    if (
      !navigationPoint ||
      navigationPoint.navigatorKey !== this.navigatorKey
    ) {
      return -1;
    }
    const navigationInterval = this.handleIntervals.interval(
      navigationPoint.index
    );
    if (
      !this.timeIsOnCanvas(navigationInterval.end) &&
      !this.timeIsOnCanvas(navigationInterval.start)
    ) {
      if (navigationInterval.end < this.canvasStartTime) {
        return -1;
      }
      if (navigationInterval.start > this.canvasEndTime) {
        return -1;
      }
    }
    return navigationPoint.index;
  }

  drawNavigationIndicator(ctx) {
    const index = this.getVisibleNavigationIndex();
    if (index < 0) return;
    ctx.save();
    //ctx.fillStyle = this.navigationIndicatorFillStyle;
    //this.drawHandle(ctx, index);
    ctx.lineWidth = 3;
    this.drawHandleOutline(ctx, index);
    ctx.restore();
  }

  onNavigationSelect(f) {
    this.disposers.push(
      reaction(
        () => this.notifyNavigationSelect,
        () => f(this, this.navigatorKey, this.lastNavigationSelect)
      )
    );
  }

  handleMouseClickAtTime(x, y, time, event) {
    const index = this.getIntervalIndexForTime(time);
    if (index < 0) {
      return;
    }
    const timeSelect = this.handleIntervals.interval(index).start;
    this.lastNavigationSelect = { index, event, time: timeSelect };
    this.notifyNavigationSelect++;
  }

  drawRolloverHighlight(ctx) {
    if (!this.rolloverState) {
      return;
    }
    ctx.save();
    ctx.fillStyle = this.rolloverHandleFillStyle;
    this.drawHandle(ctx, this.rolloverState.index);
    ctx.lineWidth = 3;
    this.drawHandleOutline(ctx, this.rolloverState.index);
    ctx.restore();
  }

  checkRollover(x, y, time, state) {
    if (state) {
      const interval = this.getHandleTimeInterval(state.index);
      if (interval.start <= time && interval.end >= time) {
        return state;
      } else {
        // TODO maybe redundant with management code in Widget layer
        this.rolloverState = null;
        this.requestForegroundRedraw();
      }
    }
    const search = this.getIntervalIndexForTime(time);
    if (search >= 0) {
      return this.makeRolloverStateForIndex(search);
    }
    return null;
  }

  makeRolloverStateForIndex(index) {
    return { gadget: this, index, interval: this.getHandleTimeInterval(index) };
  }

  setRolloverState(state) {
    if (state !== this.rolloverState) {
      this.requestForegroundRedraw();
    }
    this.rolloverState = state;
  }

  deactivateRollover() {
    if (this.rolloverState) {
      this.requestForegroundRedraw();
    }
    this.rolloverState = null;
  }

  drawActiveNavigationHandle() {}

  getNavigationTimes() {}

  getNavigationTime(index) {}
}
