import * as colors from "./colors";

const elem = tag => styles => {
  return [tag, styles];
};

export const WordTextCore = elem("span")({
  display: "inline-block",
  borderBottom: "2px solid transparent",
  userSelect: "none",
  fontStyle: "normal"
});

export const WordWrapperCore = elem("div")({
  display: "inline",
  fontFamily: "Roboto",
  lineHeight: "1.5em"
});

export const ChapterContainerCore = elem("div")({
  marginLeft: "0px",
  marginRight: "100px",
  paddingBottom: "0px",
  paddingTop: "50px",
  paddingRight: "1em"
});

export const ChapterHeadingCore = elem("h2")({
  fontFamily: "Roboto",
  lineHeight: "1.5em",
  fontSize: "24px",
  textAlign: "center",
  color: "#666",
  fontWeight: 700,
  marginBottom: "2em"
});

export const ChapterTranslationCore = elem("h3")({
  fontFamily: "Source Serif Pro",
  fontStyle: "italic",
  lineHeight: "1.5em",
  fontSize: "18px",
  textAlign: "center",
  color: "#666",
  fontWeight: 700,
  marginTop: "-2em",
  marginBottom: "2em"
});

export const PassageCore = elem("div")({
  marginTop: "2em",
  marginBottom: "1em",
  borderLeft: "4px solid transparent"
});

export const HintCore = elem("div")({
  fontFamily: "Source Serif Pro",
  fontSize: "18px",
  marginBottom: "1em",
  lineHeight: "1.8em",
  borderBottom: "1px solid #999",
  marginLeft: "1em",
  color: "gray"
});

export const SentenceWrapperCore = elem("div")({
  position: "relative"
});

export const JumpContainerCore = elem("div")({
  position: "absolute",
  left: "-50px"
});

export const SentenceCore = elem("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  color: "#111",
  padding: "20px 0",
  marginLeft: "10px"
});

export const SentenceTranslationCore = elem("div")({
  fontFamily: "Source Serif Pro",
  fontSize: "1em",
  padding: "1em",
  paddingLeft: "2em",
  paddingTop: "0.1em",
  paddingBottom: "1.5em",
  marginTop: "-0.5em",
  fontStyle: "italic",
  lineHeight: "1.8em",
  letterSpacing: "0.0125em",
  color: "#999"
  // & i {
  //   font-style: normal;
  // }
});

export const SpeakerLabelCore = elem("span")({
  display: "inline-block",
  marginTop: "3em",
  marginLeft: "1em",
  marginBottom: "1em",
  fontSize: "14px",
  fontFamily: "Roboto",
  fontWeight: 400,
  background: "#fff",
  border: "1px solid #e9e9e9",
  padding: "3px 12px",
  borderRadius: "4px",
  color: "#151515"
});

export const SegmentCore = elem("span")({
  color: "#f7c70a",
  marginBottom: "2px",
  marginRight: "2px"
});

export const SmallCueGraphicCore = elem("svg")({
  width: "11px",
  verticalAlign: "middle"
});

export const SmallCuePathCore = elem("path")({
  fill: "#1abbd4"
});

export const CueGraphicCore = elem("svg")({
  width: "20px",
  verticalAlign: "sub"
});

export const CuePathCore = elem("path")({
  fill: "#1abbd4"
});

export const RedWarningCore = elem("span")({
  color: "#FF0000"
});

export const YellowWarningCore = elem("span")({
  color: colors.darkYellow,
  fontWeight: 500
});

export const GroupCore = elem("div")({
  display: "inline-block",
  flexWrap: "wrap",
  alignItems: "center",
  borderBottom: "2px solid transparent",
  background: "transparent",
  cursor: "default"
});

export const SicAnnotationCore = elem("span")({
  fontFamily: "Roboto",
  fontStyle: "italic",
  fontSize: "0.9em"
});
