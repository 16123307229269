/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import ElementTree from "./element-tree";
import { getWarningLevel } from "../../lib/chaat/warning-data";
import { RedWarning, YellowWarning } from "../stylesystem/editorial";

const Warning = ({ h = _h, root: warning }) => {
  const { children } = warning;
  const level = getWarningLevel(warning.data.warningData);
  const WarningStyle = level === 1 ? RedWarning : YellowWarning;
  {
  }
  return (
    <WarningStyle h={h}>
      {" "}
      <ElementTree h={h} elements={children} />{" "}
    </WarningStyle>
  );
};

export default Warning;
