/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import styled from "styled-components";
import ElementTree from "./element-tree";
import JumpTo from "../jump-to";
import Linchpin from "../linchpin";
import { observer } from "mobx-react";
import { chaat, root } from "../../app/app-root";
import parseUnderscores from "../../lib/parse-underscores";
import { msToMinSecString } from "../../lib/timestamp-formats";
import { tracking } from "../../app/app-root";
import { html_h } from "../../lib/jsx/jsx-html";
import { expr } from "mobx-utils";

const Wrapper = styled.div`
  position: relative;
`;

const JumpContainer = styled.div`
  position: absolute;
  left: -50px;
`;

const StyledSentence = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${p => (p.current ? "#111" : "#111")};
  padding: 20px 0;
  margin-left: 10px;
`;

const Translation = styled.div`
  font-family: ${p => p.theme.serif};
  font-size: 1em;
  padding: 1em;
  padding-left: 2em;
  padding-top: 0.1em;
  padding-bottom: 1.5em;
  margin-top: -0.5em;
  font-style: italic;
  line-height: 1.8em;
  letter-spacing: 0.0125em;
  color: ${p => (p.current ? "#555" : "#999")};
  & i {
    font-style: normal;
  }
`;

let sentenceRenderCount = 0;
export function resetSentenceRenderCount() {
  sentenceRenderCount = 0;
}

const SentenceWords = observer(({ h = _h, sentence }) => {
  const { children } = sentence;
  const containsCueInsertPoint = expr(() => {
    const insertIndex = chaat.cueInsertIndex;
    // TODO consider using id here instead
    if (root.sentenceAtomIntervals.doesContain(sentence.index, insertIndex)) {
      return insertIndex;
    }
  });

  h = html_h;
  const innerHTML =
    `<span>[${sentence.index + 1}]</span> ` +
    (<ElementTree h={h} elements={children} />).join(" ");
  h = _h;
  return <div dangerouslySetInnerHTML={{ __html: innerHTML }} />;
  /*<ElementTree elements={children} />*/
});

const SentenceRender = ({
  h = _h,
  isUnderCursor,
  root: sentence,
  showTranslation
}) => {
  sentenceRenderCount++;
  if (!(sentenceRenderCount % 30)) {
    console.log(sentenceRenderCount);
    console.log("sentence render timestamp: " + Date.now());
  }

  return (
    <Linchpin active={isUnderCursor}>
      {scrollRefs => (
        <Wrapper>
          <StyledSentence current={isUnderCursor} {...scrollRefs}>
            <JumpContainer>
              <JumpTo
                element={sentence}
                isCurrent={isUnderCursor}
                warning={sentence.data.hasWarning}
                label={msToMinSecString(
                  root.entities.getStartTime(sentence.id)
                )}
              />
            </JumpContainer>
            <SentenceWords sentence={sentence} />
            {/*/!*<ElementTree elements={children} />*!/*/}
            {/*<div dangerouslySetInnerHTML={{ __html: innerHTML }} />*/}
          </StyledSentence>
          {showTranslation ? (
            <Translation
              current={isUnderCursor}
              {...parseUnderscores(sentence.data.translation)}
            />
          ) : null}
        </Wrapper>
      )}
    </Linchpin>
  );
};

const SentenceCursorDriver = observer(({ h = _h, root: sentence }) => {
  let isUnderCursor = false;
  const tracking = root.sentenceTracker;
  if (tracking) {
    if (tracking.isUnderSignal(sentence.id).changed()) {
      isUnderCursor = tracking.isUnder(sentence.id);
    }
  }

  return (
    <SentenceRender
      isUnderCursor={isUnderCursor}
      root={sentence}
      showTranslation={root.getWithEverything()}
    />
  );
});

export default SentenceCursorDriver;
