import {
  MDBBtn,
  MDBIframe,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

const modalState = observable({});

export function openCheatSheetModal() {
  modalState.open = true;
}

export const CheatSheetModal = observer(() => {
  const toggleModal = () => {
    modalState.open = !modalState.open;
  };

  return (
    <MDBModal size="xl" isOpen={modalState.open} toggle={toggleModal}>
      <MDBModalBody>
        <MDBIframe src="https://jiveworld.slite.com/api/s/note/ERdSZXGqdujfNheXeWYkjs/Chaat-tool-KB-Cheat-Sheet" />
      </MDBModalBody>
    </MDBModal>
  );
});
