/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import { ChapterContainer } from "../stylesystem/editorial";
//import ChapterPosition from './chapter-position';
import ChapterTitle from "./chapter-title";
// import ChapterNote from './chapter-note';
// import ChapterRecap from './chapter-recap';
// import ChapterPaging from './chapter-paging';
// import ElementTree from './element-tree';

const Chapter = ({ h = _h, isUnderCursor, root: chapter }) => {
  return (
    <ChapterContainer h={h}>
      {/*<ChapterPosition position={position} />*/}
      <ChapterTitle
        h={h}
        title={chapter.data.title}
        translation={chapter.data.title_english}
      />
      {/*{notes.map(note => (*/}
      {/*  <ChapterNote note={note} isUnderCursor={isUnderCursor} />*/}
      {/*))}*/}
      {/*<ElementTree elements={children} />*/}
      {/*<ChapterRecap chapter={chapter} />*/}
      {/*<ChapterPaging />*/}
    </ChapterContainer>
  );
};

export default Chapter;
