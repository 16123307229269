import { computed, observable } from "mobx";
import { TreeData } from "../lib/tree/tree-data";
import { makeRootTreeIterator, makeTree } from "../lib/tree/tree-iterator";
import { TimelineEntities } from "../lib/entity/timeline-entities";
import { EntityContentLayer } from "../lib/content-layer/entity-content-layer";
import { mergeEntityData } from "../lib/entity/entity-list-utils";

export class AppContentLayer extends EntityContentLayer {
  @observable audioURL = null;
  @observable.ref audioTimerange = [0, 0];
  @observable _withEverything = false;
  waveformData = null;
  alternativeAudio = null;

  constructor(baseContentLayer) {
    super(baseContentLayer);
  }

  withEverything() {
    this._withEverything = !this._withEverything;
    this.dataUpdated();
  }

  @computed
  get sentenceTimeIntervals() {
    return this.timesForEntityType("SENTENCE");
  }

  @computed
  get sentenceAtomIntervals() {
    return this.atomRelationsForEntityType("SENTENCE");
  }

  @computed
  get sentenceTracker() {
    return this.trackerForEntityType("SENTENCE");
  }

  @computed
  get playerEntities() {
    const entities = TimelineEntities.fromTimelineEntities(
      this.baseContentLayer.playerEntities
    );
    entities.add(this.getEntityListForType("SENTENCE"));
    entities.atomRangeInterval = { start: 0, end: this.atoms.length };
    return entities;
  }

  get positionalMapping() {
    return this.baseContentLayer.positionalMapping;
  }

  @computed
  get scriptAreaEntities() {
    const t1 = Date.now();

    const entities = TimelineEntities.fromTimelineEntities(
      this.baseContentLayer.scriptAreaEntities
    );

    let sentenceEntityList = this.getEntityListForType("SENTENCE");

    const sentenceTimeIntervals = sentenceEntityList.getSortedTimes();
    const warningTimeIntervals = this.baseContentLayer.warningTimeIntervals;
    const sentenceExtra = sentenceTimeIntervals.map(interval => {
      return {
        hasWarning: warningTimeIntervals.hasIntersecting(
          interval.start,
          interval.end
        )
      };
    });

    sentenceEntityList = mergeEntityData(sentenceExtra, sentenceEntityList);

    entities.add(sentenceEntityList);
    entities.add(this.getEntityListForType("SPEAKER"));
    entities.add(this.getEntityListForType("CHAPTER"));
    entities.add(this.getEntityListForType("PASSAGE"));
    entities.add(this.getEntityListForType("WORD_GROUP"));
    entities.atomRangeInterval = { start: 0, end: this.atoms.length };
    console.log(
      "App content layer script area entities build time: " + (Date.now() - t1)
    );
    return entities;
  }

  @computed
  get treeData() {
    const treeData = TreeData.fromTimelineEntities(this.scriptAreaEntities);
    if (this._withEverything) {
      treeData.spanPrecedence = [
        "CHAPTER",
        "PASSAGE",
        "SPEAKER",
        "SENTENCE",
        "WORD_GROUP",
        "WARNING",
        "SEGMENT",
        "CUE"
      ];
    } else {
      treeData.spanPrecedence = ["SENTENCE", "WARNING", "SEGMENT", "CUE"];
    }
    return treeData;
  }

  @computed
  get rootTreeIterator() {
    return makeRootTreeIterator(this.treeData);
  }

  @computed
  get elementsData() {
    const treeData = this.treeData;
    const t1 = Date.now();
    /*
    const tree = expandTree(this.rootTreeIterator);
     */
    const tree = makeTree(treeData).tree;
    console.log(
      "App content layer elementsData build time: " + (Date.now() - t1)
    );
    return tree;
  }

  updateOn() {
    // touch the observables to sense data update
    let val = super.updateOn();
    // TODO figure out why causes extra update, inside transaction or not?
    //val = this.baseContentLayer.notifyDataUpdate;
    val = this.baseContentLayer.updateOn();
    val = this.audioURL;
    val = this.waveformData;
    val = this.audioTimerange;
    return val;
  }
}
