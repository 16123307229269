import React from "react";
import { root } from "../../app/app-root";
import { MainTrackWidget } from "./main-track-widget";
import { ChaatControls } from "../control";
import { AudioComponent } from "../audio-component";
import CueInsertTarget from "../cue-insert-target";
import * as colors from "../stylesystem/colors";
import StatusBar from "../status-bar";

export class TracksComponent extends React.Component {
  mainTrackWidget = null;

  componentDidMount() {
    // TODO make this not hardwired to MainTrackWidget, take class of component to instantiate in props
    const canvas = this.refs.canvas;
    this.mainTrackWidget = new MainTrackWidget();
    root.setMainTrackWidget(this.mainTrackWidget);
    this.mainTrackWidget.setCanvas(canvas);
    this.mainTrackWidget.setElementId("trackContainer");
  }

  render() {
    return (
      <div
        style={{
          paddingLeft: 200,
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: "#f4f4f4",
          borderBottom: "2px solid lightgrey"
        }}
      >
        <div
          style={{
            border: "1px solid black",
            width: "max-content",
            backgroundColor: "#ffffff"
          }}
        >
          <StatusBar />
          <CueInsertTarget />
          <div id={"trackContainer"}>
            <canvas
              id={"theCanvas"}
              ref="canvas"
              width={1000}
              height={140}
              style={{ borderTop: "1px solid black" }}
            />
          </div>
        </div>
        <ChaatControls />
      </div>
    );
  }
}

// TODO ISSUE working audio time range start and end point

export class MainTracksComponent extends TracksComponent {
  // TODO  or is this a functional component??
}
