/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import Sentence from "./sentence";
import Word from "./word";
import Warning from "./warning";
import Segment from "./segment";
import Cue from "./cue";
import SpeakerLabel from "./speaker-label";
import Chapter from "./chapter";
import Passage from "./passage";
import WordGroup from "./word-group";

const typeMap = {
  CHAPTER: Chapter,
  PASSAGE: Passage,
  SPEAKER: SpeakerLabel,
  SENTENCE: Sentence,
  WORD_GROUP: WordGroup,
  WARNING: Warning,
  SEGMENT: Segment,
  CUE: Cue,
  ATOM: Word
};

// TODO adapt property names
const Element = props => {
  const { h = _h } = props;
  const root = props.root;
  const Component = typeMap[root.type];
  if (Component) {
    return <Component {...props} />;
  }

  if (process.env.NODE_ENV !== "production") {
    console.error(`There's no component for type "${root.type}"`);
  }
  return null;
};

export default Element;
