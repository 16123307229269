import {
  adaptContentDimensionedArray,
  getContentPosition,
  makeContentToPositionalIdMapping
} from "../ids/positional-ids";

export function getAtomEntityList({
  atomData,
  timeIntervals,
  positionalMapping
}) {
  let indexToPositionalId = null;

  const getId = index => {
    if (!indexToPositionalId) {
      indexToPositionalId = makeContentToPositionalIdMapping(positionalMapping);
    }
    return indexToPositionalId[index];
  };

  const getIndex = id => {
    return getContentPosition(positionalMapping, id);
  };

  const getEntityData = id => {
    return atomData[getContentPosition(positionalMapping, id)];
  };

  const getEntityDataFromIndex = index => {
    return atomData[index];
  };

  const getSortedTimes = () => {
    return timeIntervals;
  };

  const getEntityTimes = id => {
    return timeIntervals.interval(getContentPosition(positionalMapping, id));
  };

  const getStartTime = id => {
    return getEntityTimes(id).start;
  };

  const count = atomData.length;

  const adaptArray = (array, oldAtomList, fillValue = 0) => {
    if (!array) {
      return array;
    }
    return adaptContentDimensionedArray(
      array,
      oldAtomList.positionalMapping,
      positionalMapping,
      fillValue
    );
  };

  return {
    type: "ATOM",
    count,
    getId,
    getIndex,
    getEntityData,
    getEntityDataFromIndex,
    getSortedTimes,
    getStartTime,
    getEntityTimes,
    adaptArray,

    positionalMapping
  };
}

export function overrideAtomData(atomData, atomEntityList) {
  const positionalMapping = atomEntityList.positionalMapping;
  return {
    ...atomEntityList,
    getEntityData: id => atomData[getContentPosition(positionalMapping, id)],
    getEntityDataFromIndex: index => atomData[index]
  };
}
