/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import { SegmentSpan } from "../stylesystem/editorial";

const Segment = ({ h = _h, root: segment }) => {
  return <SegmentSpan h={h}> &#9642; </SegmentSpan>;
};

export default Segment;
