import { observable } from "mobx";
import { Navigation } from "../lib/timeline-navigator";
import { AudioTransport, TransportState } from "../lib/player/audio-transport";
import { PlayerState, StructuredPlayer } from "../lib/player/structured-player";
import { createBus } from "../lib/bus";
import { ChaatProjectLoader } from "../lib/chaat/chaat-project-loader";
import { SortedPoints } from "../lib/sorted/sorted-points";
import { AppContentLayer } from "./app-content-layer";
import { ChaatProject } from "./chaat-project";
import { ChaatContentLayer } from "../lib/chaat/chaat-content-layer";
import { CueActions } from "./cue-actions";
import { AudioRegionActions } from "./audio-region-actions";
import { EntityVersionsActions } from "./entity-versions-actions";
import { Tracking } from "../lib/tracker/tracking";
import {
  setCursorColor,
  setSelectionColor
} from "../components/stylesystem/editorial";
import { entityIdToString } from "../lib/ids/id-utils";
import { WordGroupActions } from "./word-group-actions";
import {
  entityDataForIdRange,
  forEachEntityIdInRange
} from "../lib/entity/entity-list-utils";
import { MultiAudioSource } from "../lib/multi-audio-source";

export const navigation = new Navigation();
export const tracking = new Tracking();
export const transportState = new TransportState();
export const audioSource = new MultiAudioSource();
export const audioTransport = new AudioTransport({ transportState });
// TODO put in param constructor instead to be consistent?
audioTransport.setAudioSource(audioSource);
export const playerState = new PlayerState();
export const player = new StructuredPlayer({
  transportState,
  audioTransport,
  playerState,
  navigation
});

export const appBus = createBus();

class AppRoot {
  projectKey = null;
  @observable notifyDataUpdate = 1;
  @observable.ref elementsData = [];
  @observable.ref audioURL = "";
  mainTrackWidget;
  entities;
  scriptEntities;
  atomRangeSelection = null;
  atomTracker = null;
  sentenceTracker = null;
  sentenceTimeIntervals;
  sentenceAtomIntervals;
  waveformData;
  controls;
  contentLoader;
  contentLayer;
  baseContentLayer;
  audioSource = null;
  base = null;

  constructor() {
    this.base = new ChaatProject(this);
    chaat = this.base;
    this.baseContentLayer = new ChaatContentLayer();
    this.contentLayer = new AppContentLayer(this.baseContentLayer);
    this.contentLoader = new ChaatProjectLoader(
      this.contentLayer,
      this.baseContentLayer
    );
    this.contentLoader.onContentUpdated(content => this.contentUpdate(content));
    this.contentLoader.refresh();
    appBus.subscribe("deselect", () => this.cancelAtomRangeSelection());
    setCursorColor("#1abbd4");
    setSelectionColor("#87cefa");
    this.audioSource = new Audio();
  }

  load(projectKey) {
    //TODO fix up relation with "base" chaat project
    this.base.loadingProject(projectKey);
    cueActions.listenEditorFlags(projectKey);
    this.contentLoader.load(projectKey);
  }

  atomRangeSelectToEnd(atomId) {
    //TODO move to observable reaction driven
    this.cancelAtomRangeSelection();
    const start = this.atomTracker.currentIsUnder;
    if (start) {
      this.atomRangeSelection = { start, end: atomId };
      this.setSelectedClassName(this.atomRangeSelection);
    }
  }

  getTextFromIdRange(range) {
    let words = entityDataForIdRange(this.base.atomEntityList, range);
    words = words.map(data => data.text);
    words = words.join(" ");
    return words;
  }

  cancelAtomRangeSelection() {
    if (this.atomRangeSelection) {
      this.setSelectedClassName(this.atomRangeSelection, false);
      this.atomRangeSelection = null;
    }
  }

  setSelectedClassName(range, selected = true) {
    forEachEntityIdInRange(this.base.atomEntityList, range, atomId => {
      const domId = entityIdToString(atomId);
      const node = document.getElementById(domId);
      if (selected) {
        node.classList.add("selected");
      } else {
        node.classList.remove("selected");
      }
    });
  }

  withEverything() {
    this.contentLayer.withEverything();
  }

  getWithEverything() {
    return this.contentLayer._withEverything;
  }

  enableKerning(duration) {
    const biggerGaps = this.base.atomTimeIntervals.getGapIntervals(200);
    const kerningPoints = new SortedPoints(biggerGaps.midPoints);
    player.setKerning(kerningPoints, duration);
    player.kerningEnable(true);
  }

  setAudioSource(source) {
    // this.audioSource = source;
  }

  setMainTrackWidget(widget) {
    this.mainTrackWidget = widget;
  }

  contentUpdate(content) {
    const t1 = Date.now();

    this.projectKey = content.projectKey;

    if (this.audioURL !== content.audioURL) {
      this.audioURL = content.audioURL;
      if (this.audioURL) {
        let audioSourceDefinitions = {
          CHAAT_SOURCE_AUDIO: this.audioURL
        };
        if (content.alternativeAudio) {
          audioSourceDefinitions = {
            ...audioSourceDefinitions,
            ...content.alternativeAudio
          };
        }
        audioSource.setAudioSourceDefinitions(
          this.projectKey,
          audioSourceDefinitions
        );
      } else {
        audioSource.setAudioSourceDefinitions(this.projectKey, {});
      }
    }

    this.atomTracker = content.atomTracker;
    this.sentenceTracker = content.sentenceTracker;
    tracking.setTrackers({
      ATOM: this.atomTracker,
      SENTENCE: this.sentenceTracker
    });

    this.entities = content.playerEntities;
    this.scriptEntities = content.scriptAreaEntities;
    this.elementsData = content.elementsData;
    this.sentenceTimeIntervals = content.sentenceTimeIntervals;
    this.sentenceAtomIntervals = content.sentenceAtomIntervals;
    this.waveformData = content.waveformData;
    this.base.contentUpdate(content);

    player.setEntities(this.entities);
    // audioTransport.setAudioSource(this.audioSource);
    audioTransport.setAudioSource(audioSource);

    console.log("data update time: " + (Date.now() - t1));

    const t2 = Date.now();
    this.notifyDataUpdate++;
    console.log("notify data update time: " + (Date.now() - t2));
  }
}

export let chaat = null;
export const root = new AppRoot();
export const cueActions = new CueActions();
export const audioRegionActions = new AudioRegionActions();
export const entityVersionsActions = new EntityVersionsActions();
export const wordGroupActions = new WordGroupActions();
