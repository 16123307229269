import React from "react";
import { observer } from "mobx-react";
import { root } from "../../app/app-root";
import Element from "./element";
import { identityKey } from "./identity-keys";
import { resetSentenceRenderCount, sentenceRenderCount } from "./sentence";
import { setupAtomLevelCursoring } from "./style-based-cursoring";

class ElementCache {
  projectKey = null;
  reactElementMap = {};

  get elementMap() {
    if (this.projectKey !== root.projectKey) {
      this.projectKey = root.projectKey;
      this.reactElementMap = {};
    }
    return this.reactElementMap;
  }
}

const elementCache = new ElementCache();

export const ScriptRoot = observer(() => {
  if (!root.elementsData) {
    // throw
  }
  let foundKeys = 0;
  let createdKeys = 0;
  const elementMap = elementCache.elementMap;

  const t1 = Date.now();
  resetSentenceRenderCount();

  const reactElements = [];
  for (const elem of root.elementsData) {
    const key = identityKey(elem);
    let reactElement = elementMap[key];
    if (!reactElement) {
      reactElement = <Element root={elem} key={key} />;
      elementMap[key] = reactElement;
      createdKeys++;
      // if (createdKeys < 4) { console.log(key); }
    } else {
      foundKeys++;
    }
    reactElements.push(reactElement);
  }
  console.log("found keys: " + foundKeys);
  console.log("created keys: " + createdKeys);
  console.log("identity lookup time: " + (Date.now() - t1));
  console.log("BEGIN REACT RENDER TIMESTAMP: " + Date.now());
  setupAtomLevelCursoring();
  return <>{reactElements}</>;
});
