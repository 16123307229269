import { observable, reaction } from "mobx";

export class JWContentLayer {
  projectKey = null;
  disposers = [];
  @observable notifyDataUpdate = 0;
  baseContentLayer = null;

  constructor(baseContentLayer) {
    this.baseContentLayer = baseContentLayer;
    this.initializeUpdateReaction();
  }

  /*
   * These computed properties are part of the interface  but
   * are only in comments here because Mobx doesn't support override
   * of @computed in subclasses. So just using duck typing.
   *
   * @ computed
   * get atomEntityList() {}
   *
   * @computed
   * get atomTimeIntervals() {}
   *
   * @computed
   * get atomTracker() {}
   *
   * @computed
   * get playerEntities() {}
   *
   * @computed
   * get scriptAreaEntities() {}
   *
   */

  dataUpdated() {
    console.log("NOTIFY UPDATE " + this.constructor.name);
    this.notifyDataUpdate++;
  }

  onContentUpdated(cb) {
    this.disposers.push(
      reaction(
        () => this.notifyDataUpdate,
        () => cb(this)
      )
    );
  }

  refresh() {
    this.notifyDataUpdate++;
  }

  updateOn() {
    // touch the observables
    throw new Error("non implemented abstract");
  }

  initializeUpdateReaction() {
    const options = { equals: (a, b) => false };
    reaction(
      () => {
        this.updateOn();
      },
      () => {
        this.dataUpdated();
      },
      options
    );
  }
}
