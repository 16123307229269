import { SortedIntervals } from "../sorted/sorted-intervals";

const NO_INDEX = -1;

export class TimelineTrackerEngine {
  sortedIntervals = null;
  vIdx = 0;

  position;
  furthestPosition;

  currentIsUnderIndex = 0;
  lastBeforeIndex = 0;
  lastFurthestIndex = 0;

  isUnderOldChangeIndex = NO_INDEX;
  isUnderNewChangeIndex = NO_INDEX;
  isBeforeChangeRangeStart = NO_INDEX;
  isBeforeChangeRangeEnd = NO_INDEX;
  isVisitedChangeRangeStart = NO_INDEX;
  isVisitedChangeRangeEnd = NO_INDEX;
  anyChangeRecord = false;

  constructor(sortedIntervals = new SortedIntervals()) {
    this.sortedIntervals = sortedIntervals;
  }

  refreshIntervals(intervals) {
    this.sortedIntervals = intervals;
  }

  advanceVIdxBecauseStateChange() {
    this.vIdx = this.vIdx + 1;
  }

  clearChangeRecords() {
    this.isUnderOldChangeIndex = NO_INDEX;
    this.isUnderNewChangeIndex = NO_INDEX;
    this.isBeforeChangeRangeStart = NO_INDEX;
    this.isBeforeChangeRangeEnd = NO_INDEX;
    this.isVisitedChangeRangeStart = NO_INDEX;
    this.isVisitedChangeRangeEnd = NO_INDEX;
    this.anyChangeRecord = false;
  }

  recordChanged() {
    this.anyChangeRecord = true;
  }

  recordIsUnderChanges(oldIndex, newIndex) {
    this.isUnderOldChangeIndex = oldIndex;
    this.isUnderNewChangeIndex = newIndex;
  }

  recordIsBeforeChanges(start, end) {
    this.isBeforeChangeRangeStart = start;
    this.isBeforeChangeRangeEnd = end;
  }

  recordIsVisitedChanges(start, end) {
    this.isVisitedChangeRangeStart = start;
    this.isVisitedChangeRangeEnd = end;
  }

  recordChangesForNewPosition(position) {
    this.position = position;

    if (position > this.furthestPosition) {
      this.furthestPosition = position;
    }

    if (this.currentIsUnderIndex !== NO_INDEX) {
      if (
        this.sortedIntervals.doesContain(this.currentIsUnderIndex, position)
      ) {
        // everything we are watching is the same
        return;
      }
    }

    const currentIsUnderIndex = this.sortedIntervals.containing(position);
    let currentBeforeIndex = this.sortedIntervals.lastStartsBeforeOrAt(
      position
    );

    if (
      currentIsUnderIndex === this.currentIsUnderIndex &&
      currentBeforeIndex === this.lastBeforeIndex
    ) {
      // everything we are watching is the same
      return;
    }

    // something will have changed
    this.advanceVIdxBecauseStateChange();

    this.recordChanged();

    // IS UNDER
    if (this.currentIsUnderIndex !== currentIsUnderIndex) {
      this.recordIsUnderChanges(this.currentIsUnderIndex, currentIsUnderIndex);
      this.currentIsUnderIndex = currentIsUnderIndex;
    }

    if (currentBeforeIndex === this.lastBeforeIndex) {
      // everything remaining we are watching is the same
      return;
    }

    // IS BEFORE
    let startChangeIndex =
      Math.min(this.lastBeforeIndex, currentBeforeIndex) + 1;
    let endChangeIndex = Math.max(this.lastBeforeIndex, currentBeforeIndex);
    this.recordIsBeforeChanges(startChangeIndex, endChangeIndex);

    this.lastBeforeIndex = currentBeforeIndex;

    if (this.lastFurthestIndex > currentBeforeIndex) {
      return;
    }

    startChangeIndex = Math.min(this.lastBeforeIndex, currentBeforeIndex) + 1;
    endChangeIndex = Math.max(this.lastBeforeIndex, currentBeforeIndex);
    this.recordIsVisitedChanges(startChangeIndex, endChangeIndex);
  }

  get currentIsUnder() {
    return this.currentIsUnderIndex;
  }

  isUnder(idx) {
    return this.sortedIntervals.doesContain(idx, this.position);
  }

  isBefore(idx) {
    return this.sortedIntervals.intervalStartsBeforeOrAt(this.position, idx);
  }

  isVisited(idx) {
    const position = this.position;
    if (position <= this.furthestPosition) {
      return idx <= this.lastFurthestIndex;
    }
    const interval = this.interval(idx);
    return interval.start <= position; //TODO inclusive?
  }

  interval(idx) {
    return this.sortedIntervals.interval(idx);
  }
}
