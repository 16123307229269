import { computed, observable } from "mobx";
import { fastSortOnAtomIndex } from "./sort-on-atom-index";
import { SortedIntervals } from "../sorted/sorted-intervals";
import { SortedPoints } from "../sorted/sorted-points";

const POSITION = "POSITION";
const RANGE = "RANGE";
const BREAK = "BREAK";
const FORWARD_BREAK = "FORWARD_BREAK";
const POINT = "POINT";
const INTERVAL = "INTERVAL";

function exists(typestring) {
  return !(typestring === "undefined");
}

export class EntityListBuilder {
  type = "";
  @observable.ref entities = [];
  @observable.ref atomEntityList = null;
  @observable.ref atomTimeIntervals = null;

  constructor(entities, type, atomEntityList, atomTimeIntervals = null) {
    this.entities = entities;
    this.type = type;
    this.atomEntityList = atomEntityList;
    this.atomTimeIntervals = atomTimeIntervals;
  }

  @computed
  get anchorType() {
    const entities = this.entities;
    if (entities.length === 0) {
      // whatever here doesn't matter
      return RANGE;
    }
    const toSniff = entities[0];
    if (exists(typeof toSniff.breakPosition)) {
      return BREAK;
    }
    if (exists(typeof toSniff.position)) {
      return POSITION;
    }
    if (exists(typeof toSniff.startPosition)) {
      if (exists(typeof toSniff.endPosition)) {
        return RANGE;
      }
      return FORWARD_BREAK;
    }
    throw new Error("failed sniff entity anchor type");
  }

  @computed
  get atomRelationType() {
    switch (this.anchorType) {
      case RANGE:
      case BREAK:
      case FORWARD_BREAK:
        return INTERVAL;
      case POSITION:
        return POINT;
    }
    throw new Error("impossible");
  }

  @computed
  get entityIdToEntityData() {
    const map = {};
    this.sortedEntities.forEach((entity, index) => {
      map[entity.id] = entity;
    });
    return map;
  }

  @computed
  get entityIdToIndex() {
    const map = {};
    for (let [index, entity] of this.sortedEntities.entries()) {
      map[entity.id] = index;
    }
    return map;
  }

  @computed
  get entityIndexToId() {
    const result = new Array(this.sortedEntities.length).fill(0);
    for (let [id, index] of Object.entries(this.entityIdToIndex)) {
      result[index] = id;
    }
    return result;
  }

  @computed
  get sortedEntities() {
    const entities = this.entities.slice();
    const atomIdToIndex = this.atomEntityList.getIndex;
    switch (this.anchorType) {
      case BREAK:
        fastSortOnAtomIndex(entities, e => atomIdToIndex(e.breakPosition));
        break;
      case POSITION:
        fastSortOnAtomIndex(entities, e => atomIdToIndex(e.position));
        break;
      case RANGE:
      case FORWARD_BREAK:
        fastSortOnAtomIndex(entities, e => atomIdToIndex(e.startPosition));
        break;
      default:
        //throw
        break;
    }

    return entities;
  }

  @computed
  get entityAtomRelations() {
    const entities = this.sortedEntities;
    let startIndexes, endIndexes, points;
    const atomIdToIndex = this.atomEntityList.getIndex;
    let breaks = null;

    switch (this.anchorType) {
      case BREAK:
        breaks = entities.map(e => atomIdToIndex(e.breakPosition));
        [startIndexes, endIndexes] = intervalDataFromBreaks(breaks);
        break;
      case FORWARD_BREAK:
        breaks = entities.map(e => atomIdToIndex(e.startPosition));
        [startIndexes, endIndexes] = intervalDataFromForwardBreaks(
          breaks,
          this.atomEntityList.count
        );
        break;
      case RANGE:
        startIndexes = entities.map(e => atomIdToIndex(e.startPosition));
        endIndexes = entities.map(e => atomIdToIndex(e.endPosition));
        break;
      case POSITION:
        points = entities.map(e => atomIdToIndex(e.position));
        break;
      default:
        //throw
        break;
    }

    if (this.atomRelationType === INTERVAL) {
      return new SortedIntervals({
        startPoints: startIndexes,
        endPoints: endIndexes
      });
    }
    if (this.atomRelationType === POINT) {
      return new SortedPoints(points);
    }
    throw new Error("no result");
  }

  @computed
  get entityTypeTimes() {
    if (!this.atomTimeIntervals) {
      if (this.atomRelationType === INTERVAL) {
        return new SortedIntervals(); // TODO or null?
      }
      return new SortedPoints();
    }

    if (this.atomRelationType === INTERVAL) {
      return this.atomTimeIntervals.translate(
        this.entityAtomRelations.startPoints,
        this.entityAtomRelations.endPoints
      );
    }
    // TODO point relation type
  }

  getEntityList() {
    const entityIdToEntity = this.entityIdToEntityData;
    const sortedEntities = this.sortedEntities;
    const entityAtomRelations = this.entityAtomRelations;
    const entitySortedTimes = this.entityTypeTimes;
    const entityIdToIndex = this.entityIdToIndex;
    const entityIndexToId = this.entityIndexToId;

    const getId = index => {
      return entityIndexToId[index];
    };

    const getIndex = id => {
      return entityIdToIndex[id];
    };

    const getEntityData = id => {
      return entityIdToEntity[id];
    };

    const getEntityDataFromIndex = index => {
      return sortedEntities[index];
    };

    const getSortedTimes = () => {
      return entitySortedTimes;
    };

    const getEntityTimes = id => {
      return entitySortedTimes.at(entityIdToIndex[id]);
    };

    const getStartTime = id => {
      return getEntityTimes(id).start;
    };

    const getEntityAtomRelations = () => {
      return entityAtomRelations;
    };

    const atomRelation = id => {
      return entityAtomRelations.at(entityIdToIndex[id]);
    };

    const startAtomIndex = id => {
      return entityAtomRelations.at(entityIdToIndex[id]).start;
    };

    const count = this.entities.length;

    const adaptArray = (array, oldEntityList, fillValue = 0) => {
      if (!array) {
        return array;
      }
      const result = new Array(count).fill(fillValue);
      for (let [entityId, oldPosition] of Object.entries(
        oldEntityList.entityIdToIndex
      )) {
        const newPosition = entityIdToIndex[entityId];
        result[newPosition] = array[oldPosition];
      }
      return result;
    };

    return {
      type: this.type,
      count,
      getId,
      getIndex,
      getEntityData,
      getEntityDataFromIndex,
      getSortedTimes,
      getStartTime,
      getEntityTimes,
      adaptArray,

      entityIdToIndex,
      getEntityAtomRelations,
      atomRelation,
      startAtomIndex
    };
  }
}

function intervalDataFromBreaks(breaks) {
  let pos = 0;
  const startIndexes = [];
  const endIndexes = [];

  for (let i of breaks) {
    startIndexes.push(pos);
    endIndexes.push(i);
    pos = i;
  }
  return [startIndexes, endIndexes];
}

function intervalDataFromForwardBreaks(breaks, end) {
  let pos = null;
  const startIndexes = [];
  const endIndexes = [];

  for (let i of breaks) {
    if (pos !== null) {
      startIndexes.push(pos);
      endIndexes.push(i);
    }
    pos = i;
  }
  if (pos !== end) {
    startIndexes.push(pos);
    endIndexes.push(end);
  }
  return [startIndexes, endIndexes];
}
