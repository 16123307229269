import * as firebase from "firebase/app";
import "firebase/firestore";
import { chaat } from "./app-root";
import { appBus } from "./app-root";
import { openAudioRegionLabelDialog } from "../components/audio-region-label-dialog";
import { randomString } from "../lib/ids/random-string";
import { transportState } from "./app-root";

export class AudioRegionActions {
  constructor() {
    appBus.subscribe("audioRegionDialog", () =>
      this.audioRegionLabelDialogAction()
    );
    appBus.subscribe("audioRegionAddUpdate", () =>
      this.addUpdateAudioRegionAction()
    );
    appBus.subscribe("audioRegionRemove", () => this.removeAudioRegionAction());
  }

  get dbPaths() {
    return chaat.dbPaths;
  }

  getTargetRegion() {
    const selection = chaat.audioRegionSelection;
    if (selection) {
      const intersecting = chaat.audioRegionTimeIntervals.rangeIntersecting(
        selection.start,
        selection.end
      );
      if (intersecting) {
        const intervals = chaat.audioRegionTimeIntervals.retrieveRange(
          intersecting.start,
          intersecting.end
        );
        if (intervals.length === 1) {
          // TODO create new interval from union of selection and existing and present as update to existing
        }
      }
      // open dialog to create new audio region from audio region selection
      return selection;
    }
    const targetRegionIndex = chaat.audioRegionTimeIntervals.containing(
      transportState.audioPosition
    );
    if (targetRegionIndex !== -1) {
      // get audio region data for index
      const audioRegionData = chaat.audioRegions[targetRegionIndex];
      return audioRegionData;
    }
    return null;
  }

  addUpdateAudioRegionAction() {
    const targetRegion = this.getTargetRegion();
    if (targetRegion) {
      this.addUpdateAudioRegion(targetRegion);
    }
  }

  removeAudioRegionAction() {
    const targetRegion = this.getTargetRegion();
    if (targetRegion) {
      this.removeAudioRegion(targetRegion);
    }
  }

  audioRegionLabelDialogAction() {
    if (chaat.audioRegionSelection) {
      appBus.emit("deselect");
    }
    const targetRegion = this.getTargetRegion();
    if (targetRegion && targetRegion.key) {
      openAudioRegionLabelDialog(targetRegion);
    }
  }

  async addUpdateAudioRegion({ key, start, end, label = null }) {
    const regionsDocRef = this.dbPaths.audioRegionsDocRef;
    const regionData = { start, end, label };
    // TODO change key to id, anyway make consistent
    const regionKey = key ? key : `REGION:${randomString(12)}`;
    regionData["key"] = regionKey;
    const region = {};
    region[regionKey] = regionData;
    const result = await regionsDocRef.update(region);
    chaat.updateTimestamping();
  }

  async removeAudioRegion({ key }) {
    const regionsDocRef = this.dbPaths.audioRegionsDocRef;
    const result = await regionsDocRef.update({
      [key]: firebase.firestore.FieldValue.delete()
    });
    chaat.updateTimestamping();
  }
}
