import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from "mdbreact";
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { wordGroupActions } from "../app/app-root";
import { appBus } from "../app/app-root";

class WordGroupDialogState {
  @observable open = false;
  @observable group_type;
  @observable note;
  id;
  text;
  startPosition;
  endPosition;
}

const dialogState = new WordGroupDialogState();

export function openWordGroupDialog(wordGroupData) {
  // TODO
  dialogState.id = wordGroupData.id;
  dialogState.text = wordGroupData.text;
  console.log("SETTING VOCAB TEXT: " + wordGroupData.text);
  dialogState.group_type = wordGroupData.group_type;
  dialogState.note = wordGroupData.note ? wordGroupData.note : "";
  dialogState.startPosition = wordGroupData.startPosition;
  dialogState.endPosition = wordGroupData.endPosition;
  dialogState.open = true;
}

export const WordGroupDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const handleTypeChange = event => {
    dialogState.group_type = event.target.value;
  };

  const handleNoteChange = event => {
    dialogState.note = event.target.value;
  };

  const getEditedState = () => {
    return {
      id: dialogState.id,
      group_type: dialogState.group_type,
      note: dialogState.note,
      startPosition: dialogState.startPosition,
      endPosition: dialogState.endPosition
    };
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        {dialogState.id ? "Update Word Group" : "Add Word Group"}
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div className="form-group">Words: {dialogState.text}</div>
          <div className="form-group">
            <select
              value={dialogState.group_type}
              className="browser-default custom-select"
              onChange={handleTypeChange}
            >
              <option>Group Type</option>
              <option value="vocab">Vocab</option>
              <option value="tricky">Tricky Bit</option>
              <option value="sic">Sic</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="translationInput">Translation</label>
            <input
              type="text"
              className="form-control"
              id="translationInput"
              value={dialogState.note}
              onChange={handleNoteChange}
            />
            {/*<MDBInput label="Definition"  group value={dialogState.note}/>*/}
          </div>
        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        {dialogState.id ? (
          <>
            <MDBBtn
              color="info"
              onClick={() => {
                // TODO
                wordGroupActions.removeWordGroup(getEditedState());
                toggleModal();
              }}
            >
              Delete
            </MDBBtn>
            <MDBBtn
              color="info"
              onClick={() => {
                wordGroupActions.addUpdateWordGroup(getEditedState());
                toggleModal();
              }}
            >
              Update
            </MDBBtn>
          </>
        ) : (
          <MDBBtn
            color="info"
            onClick={() => {
              // TODO
              wordGroupActions.addUpdateWordGroup(getEditedState());
              appBus.emit("deselect");
              toggleModal();
            }}
          >
            Add
          </MDBBtn>
        )}
      </MDBModalFooter>
    </MDBModal>
  );
});
