/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import { StyledHint } from "../stylesystem/editorial";
import parseUnderscores from "../../lib/parse-underscores";

const PassageHint = ({ h = _h, hint, current }) => {
  return <StyledHint h={h} current={current} {...parseUnderscores(hint)} />;
};

export default PassageHint;
