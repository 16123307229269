import { getEntityType, isAtomId } from "../ids/id-utils";

const NO_INDEX = -1;

export class TimelineEntities {
  atomRangeInterval = { start: 0, end: 0 };
  atomEntityList = null;
  typedEntityLists = {};

  static fromTimelineEntities(timelineEntities) {
    const result = new TimelineEntities();
    result.atomEntityList = timelineEntities.atomEntityList;
    result.typedEntityLists = { ...timelineEntities.typedEntityLists };
    return result;
  }

  add(entityList) {
    const type = entityList.type;
    if (type === "ATOM") {
      this.atomEntityList = entityList;
    } else {
      this.typedEntityLists[type] = entityList;
    }
  }

  getType(entityId) {
    return getEntityType(entityId);
  }

  getIndex(entityId) {
    if (isAtomId(entityId)) {
      return this.atomEntityList.getIndex(entityId);
    }
    const type = this.getType(entityId);
    return this.typedEntityLists[type].getIndex(entityId);
  }

  getId(type, index) {
    if (type === "ATOM") {
      return this.atomEntityList.getId(index);
    }
    return this.typedEntityLists[type].getId(index);
  }

  getEntityData(entityId) {
    if (isAtomId(entityId)) {
      return this.atomEntityList.getEntityData(entityId);
    }
    const type = this.getType(entityId);
    return this.typedEntityLists[type].getEntityData(entityId);
  }

  getStartTime(entityId) {
    if (isAtomId(entityId)) {
      return this.atomEntityList.getStartTime(entityId);
    }
    const type = this.getType(entityId);
    return this.typedEntityLists[type].getStartTime(entityId);
  }

  getStartAtomIndex(entityId) {
    return this.getEntityAtomRelation(entityId).start;
  }

  getEntityAtomRelation(entityId) {
    if (isAtomId(entityId)) {
      return { start: entityId };
    }
    const type = this.getType(entityId);
    return this.typedEntityLists[type].atomRelation(entityId);
  }

  getAtomRelations(type) {
    return this.typedEntityLists[type].getEntityAtomRelations();
  }

  // TODO move most of the logic for these 3 functions to the EntityLists and delegate?
  getEntityIdContaining(entityId, type) {
    const atomIndex = this.getStartAtomIndex(entityId);
    const atomRelations = this.getAtomRelations(type);
    const resultIndex = atomRelations.containing(atomIndex);
    if (resultIndex === NO_INDEX) {
      return null;
    }
    return this.getId(type, resultIndex);
  }

  getEntityIdAtOrBefore(entityId, type) {
    const atomIndex = this.getStartAtomIndex(entityId);
    const atomRelations = this.getAtomRelations(type);
    const resultIndex = atomRelations.lastStartsBeforeOrAt(atomIndex);
    if (resultIndex === NO_INDEX) {
      return null;
    }
    return this.getId(type, resultIndex);
  }

  getEntityIdAfter(entityId, type = null) {
    type = type ? type : this.getType(entityId);
    const atomIndex = this.getStartAtomIndex(entityId);
    const atomRelations = this.getAtomRelations(type);
    const resultIndex = atomRelations.firstStartsAfter(atomIndex);
    if (resultIndex === NO_INDEX) {
      return null;
    }
    return this.getId(type, resultIndex);
  }
}
