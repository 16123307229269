const theme = {
  serif: `"Source Serif Pro", serif`,
  sansSerif: `"Roboto", sans-serif`,
  vocabColor: "#91de4b",
  vocabTipColor: "#69c00d",
  trickyColor: "#6bbdca",
  sicColor: "#ffa100",
  jumpColor: "#1abbd4",
  tracksZIndex: 200
};
export default theme;
