import { searchsorted } from "./searchsorted";
import { SortedIntervals } from "./sorted-intervals";

export class SortedPoints {
  points = [];

  constructor(points) {
    this.points = points;
  }

  get length() {
    return this.points.length;
  }

  point(idx) {
    return this.points[idx];
  }

  at(idx) {
    return { start: this.points[idx] };
  }

  validReturnIndex(idx) {
    if (this.checkValidIndex(idx)) {
      return idx;
    }
    return -1;
  }

  checkValidIndex(idx) {
    return idx >= 0 && idx < this.points.length;
  }

  validReturnInterval(startIdx, endIdx) {
    if (startIdx < 0 || endIdx < 0) {
      return null; // TODO or return empty interval?
    }

    if (startIdx > endIdx) {
      return null; // TODO possible?
    }

    return { start: startIdx, end: endIdx };
  }

  find(value) {
    let idx = searchsorted(this.points, value);
    if (idx >= this.points.length) {
      return -1;
    }
    if (this.points[idx] == value) {
      return idx;
    }
  }

  lastBeforeOrAt(value) {
    let idx = searchsorted(this.points, value, true);
    idx--;
    return this.validReturnIndex(idx);
  }

  firstAfter(value) {
    let idx = searchsorted(this.points, value, true);
    return this.validReturnIndex(idx);
  }

  nearest(value) {
    const low = this.lastBeforeOrAt(value);
    if (low < 0) {
      return this.checkValidIndex(0);
    }

    const high = this.checkValidIndex(low + 1);
    if (high < 0) {
      return low;
    }
    const lowValue = this.points[low];
    const highValue = this.points[high];
    return highValue - value > value - lowValue ? low : high;
  }

  hasContained(startValue, endValue) {
    return !!this.rangeContained(startValue, endValue);
  }

  rangeContained(startValue, endValue) {
    let startIdx = this.lastFirstAfter(startValue); // TODO or at?
    let endIdx = this.lastBefore(endValue);

    return this.validReturnInterval(startIdx, endIdx);
  }

  asIntervals(domainStart, domainEnd) {
    const startPoints = [];
    const endPoints = [];
    startPoints.push(domainStart);
    for (const val of this.points) {
      endPoints.push(val);
      startPoints.push(val);
    }
    endPoints.push(domainEnd);
    return new SortedIntervals({ startPoints, endPoints });
  }

  get openTransitions() {
    return this.points;
  }

  containing(value) {
    return this.find(value);
  }
}
