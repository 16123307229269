import { reaction } from "mobx";
import { TrackGadget } from "./track-gadget";

export class AudioPositionsTrackGadget extends TrackGadget {
  // observes audio restart point, audio position (while playing, and while not?), last played to position

  cursorFillStyle = "#000000";
  strokeStyle = "#000000";
  cursorWidth = 25;

  positionLineExtension = 0;
  transportState = null;

  setTransportState(transportState) {
    if (this.transportState === transportState) {
      return;
    }

    this.transportState = transportState;
    this.disposers.push(
      reaction(
        () => this.transportState.audioPosition,
        () => this.handlePlayerStatusUpdate()
      )
    );
    this.disposers.push(
      reaction(
        () => this.transportState.audioRestartPosition,
        () => this.handlePlayerStatusUpdate()
      )
    );
    this.disposers.push(
      reaction(
        () => this.transportState.audioLastPlayToPosition,
        () => this.handlePlayerStatusUpdate()
      )
    );
    this.requestForegroundRedraw();
  }

  handlePlayerStatusUpdate() {
    this.requestForegroundRedraw();
  }

  drawBackgroundLayer(ctx) {}

  drawForegroundLayer(ctx) {
    ctx.save();
    ctx.fillStyle = this.cursorFillStyle;
    ctx.strokeStyle = this.strokeStyle;
    ctx.beginPath();
    this.drawGadgetSliceBounds(ctx, TrackGadget.TOP);
    ctx.lineWidth = 2;
    ctx.beginPath();
    this.drawLineAtTime(
      ctx,
      this.transportState.audioPosition,
      this.positionLineExtension,
      TrackGadget.DOWN
    );

    this.drawTriangleAtTime(
      ctx,
      this.transportState.audioPosition,
      this.cursorWidth
    );
    ctx.stroke();
    this.drawTriangleAtTime(
      ctx,
      this.transportState.audioRestartPosition,
      this.cursorWidth
    );
    this.drawTriangleAtTime(
      ctx,
      this.transportState.audioLastPlayToPosition,
      this.cursorWidth
    );
    ctx.restore();
  }
}
