/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
//import React from "react";

import { SpeakerLabelSpan as Label } from "../stylesystem/editorial";

const SpeakerLabel = ({ h = _h, root: speaker }) => {
  return (
    <div>
      <Label h={h}>{speaker.data.label}</Label>
    </div>
  );
};

export default SpeakerLabel;
