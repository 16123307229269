// some integer array searching functions optimized for different usages

export function searchsorted(array, value, retHighest = false) {
  let low = 0;
  let high = array == null ? low : array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    const computed = array[mid];
    if (retHighest ? computed <= value : computed < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return high;
}
