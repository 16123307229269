import * as firebase from "firebase/app";
import "firebase/firestore";
import { chaat } from "./app-root";
import { root, appBus } from "./app-root";
// import { openAudioRegionLabelDialog } from "../components/audio-region-dialog";
import { randomString } from "../lib/ids/random-string";
import { openWordGroupDialog } from "../components/word-group-dialog";

export class WordGroupActions {
  constructor() {
    // tODO appBus.subscribe("audioRegionDialog", () => this.audioRegionLabelDialogAction());
    // appBus.subscribe("audioRegionAddUpdate", () =>
    //   this.addUpdateAudioRegionAction()
    // );
    // appBus.subscribe("audioRegionRemove", () => this.removeAudioRegionAction());
  }

  get dbPaths() {
    return chaat.dbPaths;
  }

  addWordsFor(wordGroupData) {
    const range = {
      start: wordGroupData.startPosition,
      end: wordGroupData.endPosition - 1
    };
    return { text: root.getTextFromIdRange(range), ...wordGroupData };
  }

  getTargetWordGroup(group_type) {
    const selection = root.atomRangeSelection;
    if (selection) {
      // TODO TODO fix end selection
      return this.addWordsFor({
        startPosition: selection.start,
        endPosition: selection.end + 1,
        group_type
      });
    }
    // TODO version that returns index on tracker?
    const currentAtomId = root.atomTracker.currentIsUnder;
    if (!currentAtomId) {
      return null;
    }
    const wordGroupId = root.scriptEntities.getEntityIdContaining(
      currentAtomId,
      "WORD_GROUP"
    );
    // const currentAtomIndex = chaat.atomIdToIndex(currentAtomId);
    // const wordGroupAtomRelations = root.scriptEntities.getAtomRelations('WORD_GROUP');
    // // TODO add containing on TimelineEntities that returns entityId?
    // const wordGroupIndex = wordGroupAtomRelations.containing(currentAtomIndex);
    // // TODO NO_INDEX
    //
    // if (wordGroupIndex !== -1) {
    //   const wordGroupId = root.scriptEntities.getId('WORD_GROUP', wordGroupIndex);
    //   return root.scriptEntities.getEntityData(wordGroupId);
    // }
    if (wordGroupId) {
      return this.addWordsFor(root.scriptEntities.getEntityData(wordGroupId));
    }
    return this.addWordsFor({
      startPosition: currentAtomId,
      endPosition: currentAtomId + 1,
      group_type
    });
  }

  wordGroupDialogAction(group_type) {
    const targetWordGroup = this.getTargetWordGroup(group_type);
    if (targetWordGroup) {
      openWordGroupDialog(targetWordGroup);
    }
  }

  async addUpdateWordGroup({ id, ...data }) {
    const additionalEntitiesDocRef = this.dbPaths.additionalEntitiesDocRef;
    // TODO if has id check is editable kind of word group
    id = id ? id : `WORD_GROUP:${randomString(12)}`;
    const wordGroupData = { ...data };
    // TODO check group_type is properly set
    wordGroupData["type"] = "WORD_GROUP";
    wordGroupData["id"] = id;
    wordGroupData["editable"] = true;
    const wordGroup = {};
    wordGroup[id] = wordGroupData;
    const result = await additionalEntitiesDocRef.set(wordGroup, {
      merge: true
    });
  }

  testAddWordGroup() {
    this.addUpdateWordGroup({
      group_type: "vocab",
      note: "producer",
      startPosition: 6,
      endPosition: 7
    });
  }

  async removeWordGroup(wordGroupData) {
    const { id } = wordGroupData;
    const additionalEntitiesDocRef = this.dbPaths.additionalEntitiesDocRef;
    const result = await additionalEntitiesDocRef.update({
      [id]: firebase.firestore.FieldValue.delete()
    });
  }
}
