export function isAtomId(id) {
  return typeof id === "number";
}

export function getEntityType(entityId) {
  if (isAtomId(entityId)) {
    return "ATOM";
  }
  return entityId.slice(0, entityId.indexOf(":"));
}

export function entityIdToString(entityId) {
  if (getEntityType(entityId) === "ATOM") {
    return `ATOM:${entityId}`;
  }
  return entityId;
}

export function stringToEntityId(str) {
  const type = str.slice(0, str.indexOf(":"));
  if (type === "ATOM") {
    return parseInt(str.slice(str.indexOf(":") + 1));
  }
  return str;
}
