import { SortedPoints } from "../sorted/sorted-points";
import { EMPTY_ENTITY } from "./entity-list-utils";

export function getPassthruAdhocEntityList({
  type,
  entityData,
  atomRelations,
  atomEntityList,
  sortedTimes = null
}) {
  let _sortedTimes = sortedTimes;

  const getId = index => {
    return index;
  };

  const getIndex = id => {
    return id;
  };

  const getEntityData = id => {
    return entityData ? entityData[id] : EMPTY_ENTITY; // TODO or null?
  };

  const getEntityDataFromIndex = index => {
    return entityData ? entityData[index] : EMPTY_ENTITY; // TODO or null?
  };

  const getSortedTimes = () => {
    if (!_sortedTimes) {
      const atomTimeIntervals = atomEntityList.getSortedTimes();
      if (atomRelations.constructor === SortedPoints) {
        _sortedTimes = atomTimeIntervals.translateStartPointsToValues(
          atomRelations.points
        );
      } else {
        _sortedTimes = atomTimeIntervals.translate(
          atomRelations.startPoints,
          atomRelations.endPoints
        );
      }
    }
    return _sortedTimes;
  };

  const getEntityTimes = id => {
    const times = getSortedTimes();
    return times.at(id);
  };

  const getStartTime = id => {
    return getEntityTimes(id).start;
  };

  const count = entityData ? entityData.length : atomRelations.length;

  const adaptArray = (array, oldAtomList, fillValue = 0) => {
    return array;
  };

  let getEntityAtomRelations = null;
  let atomRelation = null;
  let startAtomIndex = null;

  if (atomRelations) {
    getEntityAtomRelations = () => atomRelations;
    atomRelation = id => atomRelations.at(id);
    startAtomIndex = id => atomRelations.at(id).start;
  }

  return {
    type,
    count,
    getId,
    getIndex,
    getEntityData,
    getEntityDataFromIndex,
    getSortedTimes,
    getStartTime,
    getEntityTimes,
    adaptArray,
    getEntityAtomRelations,
    atomRelation,
    startAtomIndex
  };
}

export function getTimebasedAdhocEntityList({
  type,
  entityData,
  atomRelations,
  atomEntityList,
  sortedTimes = null
}) {
  let _sortedTimes = sortedTimes;

  const getSortedTimes = () => {
    if (!_sortedTimes) {
      const atomTimeIntervals = atomEntityList.getSortedTimes();
      if (atomRelations.constructor === SortedPoints) {
        // TODO throw
      } else {
        _sortedTimes = atomTimeIntervals.translate(
          atomRelations.startPoints,
          atomRelations.endPoints
        );
      }
    }
    return _sortedTimes;
  };

  const getId = index => {
    const times = getSortedTimes();
    return `${type}:${times.midPoints[index]}`;
  };

  const getIndex = id => {
    const midpoint = id.slice(id.indexOf(":") + 1).parseInt();
    return getSortedTimes().containing(midpoint);
  };

  const getEntityData = id => {
    return entityData ? entityData[getIndex(id)] : EMPTY_ENTITY; // TODO or null?
  };

  const getEntityDataFromIndex = index => {
    return entityData ? entityData[index] : EMPTY_ENTITY; // TODO or null?
  };

  const getEntityTimes = id => {
    const times = getSortedTimes();
    const index = getIndex(id);
    return times.at(index);
  };

  const getStartTime = id => {
    return getEntityTimes(id).start;
  };

  const count = entityData ? entityData.length : atomRelations.length;

  const adaptArray = (array, oldAtomList, fillValue = 0) => {
    // TODO?
    return array;
  };

  let getEntityAtomRelations = null;
  let atomRelation = null;
  let startAtomIndex = null;
  if (atomRelations) {
    getEntityAtomRelations = () => atomRelations;
    atomRelation = id => atomRelations.at(id);
    startAtomIndex = id => atomRelations.at(id).start;
  }

  return {
    type,
    count,
    getId,
    getIndex,
    getEntityData,
    getEntityDataFromIndex,
    getSortedTimes,
    getStartTime,
    getEntityTimes,
    adaptArray,
    getEntityAtomRelations,
    atomRelation,
    startAtomIndex
  };
}
