import { root } from "../../app/app-root";
import { entityIdToString } from "../../lib/ids/id-utils";

let unsubscribeIsUnderNotify = null;

export function setupAtomLevelCursoring() {
  if (unsubscribeIsUnderNotify) {
    unsubscribeIsUnderNotify();
  }
  unsubscribeIsUnderNotify = root.atomTracker.subscribeIsUnder(
    handleCursorChange
  );
}

function handleCursorChange(atomId) {
  // console.log("handle change for:" + atomId);
  const stringEntityId = entityIdToString(atomId);
  const node = document.getElementById(stringEntityId);
  if (!node) {
    console.log("couldn't find DOM element handleCursorChange");
    return;
  }
  const under = root.atomTracker.isUnder(atomId);
  if (under) {
    node.classList.add("under-cursor");
  } else {
    node.classList.remove("under-cursor");
  }
}
