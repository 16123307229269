import * as firebase from "firebase/app";
import "firebase/firestore";
import Swal from "sweetalert2";
import { db } from "../lib/chaat/db-paths";
import { chaat } from "./app-root";
import { transportState } from "./app-root";

export class CueActions {
  editorFlags = {};
  projectKey = null;

  listenEditorFlags(projectKey) {
    if (projectKey === this.projectKey) {
      return;
    }
    this.projectKey = projectKey;
    // TODO cancel listen
    db.collection("Script__editor")
      .doc(projectKey)
      .onSnapshot(doc => {
        this.editorFlags = doc.data() || {};
      });
  }

  get dbPaths() {
    return chaat.dbPaths;
  }

  getNewVersionKey() {
    return Math.round(Date.now() / 1000);
  }

  addShiftCue() {
    const id = chaat.atomTracker.currentIsUnder;
    if (id >= 0) {
      chaat.cueInsertAtomId = id;
      this.addCue();
    } else {
      window.alert(
        "Attempting to add shift cue without audio position inside any word"
      );
    }
  }

  addShiftEndCue() {
    // TODO add currentIsUnderIndex to trackers?
    const id = chaat.atomTracker.currentIsUnder;
    let index = chaat.atomIdToIndex(id);
    if (index >= 0) {
      chaat.cueInsertAtomId = chaat.atomIndexToId(index + 1);
      this.addCue();
    } else {
      window.alert(
        "Attempting to add shift cue without audio position inside any word"
      );
    }
  }

  async addCue() {
    if (!this.editorFlags.enabled) {
      window.alert("Editing locked adding cues not allowed");
      return;
    }
    const atomId = chaat.cueInsertAtomId;
    const atomIndex = chaat.atomIdToIndex(atomId);
    if (atomId < 0) {
      window.alert(
        "Error:Trying to add cue without setting cue insertion position"
      );
      return;
    }
    const existing = chaat.cuePositions.indexOf(atomId);
    const existingTimestamp = !(existing < 0)
      ? chaat.cueTimestamps[existing]
      : null;
    const timestamp = transportState.audioPosition;
    let problematic = false;
    if (
      atomId === this.lastCueActionAtomId &&
      Math.abs(timestamp - this.lastCueActionTimestamp) > 1000
    ) {
      problematic = true;
    }
    if (
      Math.abs(timestamp - chaat.atomTimeIntervals.interval(atomIndex).start) >
      1500
    ) {
      problematic = true;
    }
    // TODO add other logic to notice probable erroneous cues
    if (problematic) {
      const confirm = await Swal.fire({
        title: "Mistake?",
        html:
          "Current audio position seems far from cue words<br> Cue at: <span style='color:red;'>" +
          chaat.utils.getScriptStringWordCount(atomId, 4) +
          "</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Add cue"
      });
      if (!confirm.value) {
        return;
      }
    }
    this.lastCueActionAtomId = atomId;
    this.lastCueActionTimestamp = timestamp;
    chaat.localTimestamper.interpolateWithCue(atomIndex, timestamp);
    this.asyncAddCueServer(atomId, timestamp, existingTimestamp);
  }

  async asyncAddCueServer(atomId, timestamp, existingTimestamp) {
    const merge = firebase.firestore.FieldValue.arrayUnion({
      position: atomId,
      timestamp
    });
    const remove = existingTimestamp
      ? firebase.firestore.FieldValue.arrayRemove({
          position: atomId,
          timestamp: existingTimestamp
        })
      : null;

    const cuesDocRef = this.dbPaths.cuesDocRef;
    if (remove) {
      const batch = db.batch();
      batch.update(cuesDocRef, {
        cues: merge,
        version: this.getNewVersionKey()
      });
      batch.update(cuesDocRef, { cues: remove });
      const result = await batch.commit();
    } else {
      const result = await cuesDocRef.update({
        cues: merge,
        version: this.getNewVersionKey()
      });
    }
    chaat.updateTimestamping();
  }

  async removeCue() {
    const atomId = chaat.cueInsertAtomId;
    if (atomId < 0) {
      window.alert("Error: Trying to delete cue without setting cue position");
      return;
    }
    this.lastCueActionAtomId = atomId;
    const index = chaat.cuePositions.indexOf(atomId);
    const timestamp = chaat.cueTimestamps[index];

    this.lastCueActionAtomId = atomId;
    this.lastCueActionTimestamp = timestamp;
    this.asyncRemoveCueServer(atomId, timestamp);
  }

  async asyncRemoveCueServer(atomId, timestamp) {
    const remove = firebase.firestore.FieldValue.arrayRemove({
      position: atomId,
      timestamp
    });
    const cuesDocRef = this.dbPaths.cuesDocRef;
    const result = await cuesDocRef.update({
      cues: remove,
      version: this.getNewVersionKey()
    });
    chaat.updateTimestamping();
  }
}
