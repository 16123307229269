/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import SmallCueMark from "../small-cue-mark.js";

const Cue = ({ h = _h, root: cue }) => {
  return <SmallCueMark h={h} />;
};

export default Cue;
