import { computed, observable } from "mobx";
import { TimelineNavigator } from "../timeline-navigator";
import { SortedIntervals } from "../sorted/sorted-intervals";
import { transportState } from "../../app/app-root";
import { Tracker } from "../tracker/tracker";
import { TimelineEntities } from "../entity/timeline-entities";
import { SortedPoints } from "../sorted/sorted-points";
import {
  getAtomEntityList,
  overrideAtomData
} from "../entity/atom-entity-list";
import {
  getPassthruAdhocEntityList,
  getTimebasedAdhocEntityList
} from "../entity/adhoc-entity-list";
import { JWContentLayer } from "../content-layer/jw-content-layer";

export class ChaatContentLayer extends JWContentLayer {
  @observable notifyDataUpdate = 0;
  @observable.ref atoms = [];
  @observable.ref positionalMapping = { contentLength: 0, positions: [] };
  @observable.ref atomStartTimes = [];
  @observable.ref atomEndTimes = [];
  @observable.ref skipWarnStartTimes = [];
  @observable.ref skipWarnEndTimes = [];
  @observable.ref skipWarnData = [];
  @observable.ref interpolatedStartTimes = [];
  @observable.ref interpolatedEndTimes = [];
  @observable.ref interpolationData = [];
  @observable.ref notchStartTimes = [];
  @observable.ref notchEndTimes = [];
  @observable.ref cues = [];
  @observable.ref transcriptWords = [];
  @observable.ref transcriptWordStartTimes = [];
  @observable.ref transcriptWordEndTimes = [];
  @observable.ref audioRegions = {};
  originalWords = [];

  @observable.ref audioTimerange = [0, 0];

  constructor() {
    super();
    this.initializeUpdateReaction();
  }

  @computed
  get atomEntityList() {
    const atomData = this.atoms.map(val => {
      return { text: val };
    });
    return getAtomEntityList({
      atomData,
      timeIntervals: this.atomTimeIntervals,
      positionalMapping: this.positionalMapping
    });
  }

  @computed
  get atomTimeIntervals() {
    for (const [index, start] of this.atomStartTimes.entries()) {
      const end = this.atomEndTimes[index];
      if (end < start) {
        // TODO hacking to fix some problem with algo to give best result, fix algo so never occurs
        this.atomEndTimes[index] = start;
      }
    }
    return new SortedIntervals({
      startPoints: this.atomStartTimes,
      endPoints: this.atomEndTimes
    });
  }

  @computed
  get playerEntities() {
    const entities = new TimelineEntities();
    entities.add(this.atomEntityList);
    entities.atomRangeInterval = { start: 0, end: this.atoms.length };
    return entities;
  }

  @computed
  get scriptAreaEntities() {
    const t1 = Date.now();

    const entities = new TimelineEntities();

    const scriptAtomData = this.originalWords.map(val => {
      return { text: val };
    });

    const scriptAtomEntities = overrideAtomData(
      scriptAtomData,
      this.atomEntityList
    );
    entities.add(scriptAtomEntities);

    const segmentEntities = this.getTimebasedEntityList({
      type: "SEGMENT",
      atomRelations: this.segmentPoints,
      sortedTimes: this.segmentTimeIntervals
    });
    entities.add(segmentEntities);

    const cueEntities = this.getPassthruEntityList({
      type: "CUE",
      atomRelations: this.cuePoints
    });
    entities.add(cueEntities);

    const warningData = this.skipWarnData.map(val => {
      return { warningData: val };
    });

    const warningEntities = this.getTimebasedEntityList({
      type: "WARNING",
      entityData: warningData,
      atomRelations: this.warningAtomIntervals,
      sortedTimes: this.warningTimeIntervals
    });

    entities.add(warningEntities);

    entities.atomRangeInterval = { start: 0, end: this.atoms.length };
    console.log(
      "Chaat content layer script area entities build time: " +
        (Date.now() - t1)
    );
    return entities;
  }

  get atomTracker() {
    // TODO need more logic around this later to reindex
    if (this._atomTracker) {
      if (this._atomTracker.projectKey === this.projectKey) {
        this._atomTracker.setEntities(this.atomEntityList);
        return this._atomTracker;
      } else {
        this._atomTracker.dispose();
      }
    }
    this._atomTracker = new Tracker(() => transportState.audioPosition);
    this._atomTracker.setEntities(this.atomEntityList);
    this._atomTracker.projectKey = this.projectKey;
    return this._atomTracker;
  }

  @computed
  get segmentTimeIntervals() {
    return this.atomTimeIntervals.getGapIntervals(20);
  }

  @computed
  get rawWarningTimeIntervals() {
    return new SortedIntervals({
      startPoints: this.skipWarnStartTimes,
      endPoints: this.skipWarnEndTimes
    });
  }

  @computed
  get expandedWarningTimeIntervals() {
    return this.rawWarningTimeIntervals.getExpandShortIntervals(30);
  }

  @computed
  get warningAtomIntervals() {
    let intervals = this.atomTimeIntervals.sortedIntervalsMapRangeContains(
      this.rawWarningTimeIntervals
    );
    // TODO resolve the cause of nulls - some warning time result in null in call to rangeContaining
    //intervals = intervals.filter(val => val); // filter out nulls
    // TODO temporarily supported nulls in fromInterval but need to fix issue logically
    return SortedIntervals.fromIntervals(intervals);
  }

  get warningTimeIntervals() {
    return this.expandedWarningTimeIntervals;
  }

  @computed
  get cueDisplayTimeIntervals() {
    const startPoints = this.sortedCueTimestamps.map(time => time - 2);
    const endPoints = startPoints.map(time => time + 10);
    return new SortedIntervals({ startPoints, endPoints });
  }

  @computed
  get interpolatedTimeIntervals() {
    return new SortedIntervals({
      startPoints: this.interpolatedStartTimes,
      endPoints: this.interpolatedEndTimes
    });
  }

  @computed
  get notchTimeIntervals() {
    return new SortedIntervals({
      startPoints: this.notchStartTimes,
      endPoints: this.notchEndTimes
    });
  }

  @computed
  get sortedAudioRegions() {
    const audioRegions = Object.values(this.audioRegions);
    audioRegions.sort((a, b) => a.start - b.start);
    return audioRegions;
  }

  @computed
  get audioRegionTimeIntervals() {
    let audioRegionStartTimes = [];
    let audioRegionEndTimes = [];
    for (const region of Object.values(this.sortedAudioRegions)) {
      audioRegionStartTimes.push(region.start);
      audioRegionEndTimes.push(region.end);
    }
    return new SortedIntervals({
      startPoints: audioRegionStartTimes,
      endPoints: audioRegionEndTimes
    });
  }

  @computed
  get transcriptWordTimeIntervals() {
    return new SortedIntervals({
      startPoints: this.transcriptWordStartTimes,
      endPoints: this.transcriptWordEndTimes
    });
  }

  @computed
  get segmentPoints() {
    const atomIndexes = [];
    const allSegments = this.atomTimeIntervals.getGapIntervals();
    let lastUsedGapEnd = 0;
    // const gapEndTimesDebug = [];
    allSegments.forEach((interval, index) => {
      // TODO now 20 is hardcoded in two places put as constant somewhere
      let gapSize = interval.end - interval.start;
      if (gapSize >= 30) {
        if (interval.start - lastUsedGapEnd < 1500 && gapSize < 225) {
          return;
        }
        atomIndexes.push(index);
        // gapEndTimesDebug.push(interval.end);
        lastUsedGapEnd = interval.end;
      }
    });
    return new SortedPoints(atomIndexes);
  }

  @computed
  get cueTimestamps() {
    return this.cues.map(c => c.timestamp);
  }

  @computed
  get sortedCueTimestamps() {
    const timestamps = this.cueTimestamps.slice();
    timestamps.sort((a, b) => a - b);
    return timestamps;
  }

  @computed
  get cuePositions() {
    return this.cues.map(c => c.position);
  }

  @computed
  get cuePoints() {
    // const positionalIdToIndex = this.atomMapping.getLookupIndexFunction();
    const atomIdToIndex = this.atomEntityList.getIndex;
    const cueIndexes = this.cuePositions.map(pos => atomIdToIndex(pos));
    cueIndexes.sort((a, b) => a - b);
    return new SortedPoints(cueIndexes);
  }

  @computed
  get segmentNavigator() {
    const segmentNavigator = new TimelineNavigator("SEGMENT");
    segmentNavigator.setIntervals(
      this.segmentTimeIntervals,
      TimelineNavigator.MIDPOINT
    );
    return segmentNavigator;
  }

  @computed
  get notchNavigator() {
    const notchNavigator = new TimelineNavigator("NOTCH");
    notchNavigator.setIntervals(
      this.notchTimeIntervals,
      TimelineNavigator.MIDPOINT
    );
    return notchNavigator;
  }

  getPassthruEntityList({
    type,
    entityData,
    atomRelations,
    sortedTimes = null
  }) {
    return getPassthruAdhocEntityList({
      type,
      entityData,
      atomRelations,
      atomEntityList: this.atomEntityList,
      sortedTimes
    });
  }

  getTimebasedEntityList({
    type,
    entityData,
    atomRelations,
    sortedTimes = null
  }) {
    return getTimebasedAdhocEntityList({
      type,
      entityData,
      atomRelations,
      atomEntityList: this.atomEntityList,
      sortedTimes
    });
  }

  updateOn() {
    // touch the observables to sense update of input data
    let val = null;
    val = this.atoms;
    val = this.positionalMapping;
    val = this.atomStartTimes;
    val = this.atomEndTimes;
    val = this.skipWarnStartTimes;
    val = this.skipWarnEndTimes;
    val = this.interpolatedStartTimes;
    val = this.interpolatedEndTimes;
    val = this.notchStartTimes;
    val = this.notchEndTimes;
    val = this.cuePositions;
    val = this.cueTimestamps;
    val = this.audioTimerange;
    return val;
  }
}
