/** @jsx h */
import { _h } from "../../lib/jsx/default-jsx";
// import React from "react";

import { StyledPassage } from "../stylesystem/editorial";
import PassageHint from "./passage-hint";

const Passage = ({ h = _h, isUnderCursor, root: passage }) => {
  return (
    <StyledPassage h={h} current={isUnderCursor}>
      {passage.data.hint && (
        <PassageHint h={h} hint={passage.data.hint} current={isUnderCursor} />
      )}
    </StyledPassage>
  );
};

export default Passage;
