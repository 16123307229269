import { reaction } from "mobx";
import { TrackWidget } from "./track-widget";
import { AudioPositionsTrackGadget } from "./audio-positions-gadget";
import { WordsTrackGadget } from "./words-gadget";
import { WaveformTrackGadget } from "./waveform-gadget";
import { NavigationTrackGadget } from "./navigation-gadget";
import { player, playerState, transportState } from "../../app/app-root";
import * as colors from "../stylesystem/colors";
import { TransportState } from "../../lib/player/audio-transport";
import { appBus, root, chaat } from "../../app/app-root";
import { AudioRegionSelectionTrackGadget } from "./audio-region-selection-gadget";
import { openAudioRegionLabelDialog } from "../audio-region-label-dialog";
import { intervalToMinSecMsDurationString } from "../../lib/timestamp-formats";

export class MainTrackWidget extends TrackWidget {
  audioPositionGadget = null;
  waveformGadget = null;
  wordsGadget = null;
  notchesGadget = null;
  segmentsGadget = null;
  audioRegionsGadget = null;
  audioRegionSelectionGadget = null;
  warningsGadget = null;

  createGadgets() {
    this.audioPositionGadget = new AudioPositionsTrackGadget();
    this.audioPositionGadget.setTransportState(transportState);
    this.audioPositionGadget.cursorWidth = 25;
    this.audioPositionGadget.cursorFillStyle = colors.teal;
    this.gadgets.push(this.audioPositionGadget);

    this.wordsGadget = new WordsTrackGadget();
    this.wordsGadget.handleFillStyle = colors.white;
    this.wordsGadget.rolloverHandleFillStyle = colors.lightYellow;
    this.wordsGadget.font = "300 35px Arial";
    this.gadgets.push(this.wordsGadget);

    this.cuesGadget = new NavigationTrackGadget();
    this.cuesGadget.handleFillStyle = colors.teal;
    this.gadgets.push(this.cuesGadget);

    this.notchesGadget = new NavigationTrackGadget();
    this.notchesGadget.handleFillStyle = "#BBBBBB";
    this.gadgets.push(this.notchesGadget);

    this.segmentsGadget = new NavigationTrackGadget();
    this.segmentsGadget.handleFillStyle = colors.darkYellow;
    this.gadgets.push(this.segmentsGadget);

    this.warningsGadget = new NavigationTrackGadget();
    this.warningsGadget.handleFillStyle = colors.darkYellow;
    this.warningsGadget.handleFillStyleOverride = index => {
      return chaat.utils.getWarningLevel(index) === 1 ? "#FF0000" : null;
    };

    this.gadgets.push(this.warningsGadget);

    this.infoGadget = new NavigationTrackGadget();
    this.infoGadget.handleFillStyle = colors.gray3;
    this.gadgets.push(this.infoGadget);

    this.waveformGadget = new WaveformTrackGadget();
    this.waveformGadget.fillStyle = colors.darkBlue;
    this.gadgets.push(this.waveformGadget);

    this.audioRegionsGadget = new NavigationTrackGadget();
    this.audioRegionsGadget.handleFillStyle = "#4C7FAA50";
    this.gadgets.push(this.audioRegionsGadget);

    this.audioRegionSelectionGadget = new AudioRegionSelectionTrackGadget();
    this.audioRegionSelectionGadget.handleFillStyle = "#00995560";
    this.audioRegionSelectionGadget.setTransportState(transportState);
    this.audioRegionSelectionGadget.doesRollover = false;
    this.gadgets.push(this.audioRegionSelectionGadget);

    this.transcriptGadget = new WordsTrackGadget();
    this.transcriptGadget.handleFillStyle = colors.white;
    this.transcriptGadget.rolloverHandleFillStyle = colors.lightYellow;
    //this.transcriptGadget.font = "35px Arial";
    this.transcriptGadget.font = "300 35px Roboto ";
    this.transcriptGadget.fontColor = "#999";
    this.gadgets.push(this.transcriptGadget);

    reaction(
      () => root.notifyDataUpdate,
      () => this.initGadgetsData(),
      { fireImmediately: true }
    );
    //appBus.subscribe('audioRegionEdit', () => this.audioRegionEdit());
    appBus.subscribe("deselect", () => this.audioRegionDeselect());
  }

  layoutGadgetSlices() {
    let totalHeight = 0;
    let height;

    height = 40;
    this.segmentsGadget.setLayout(0, height);
    totalHeight += height;
    height = 23;
    const audioPositionStartY = totalHeight;
    this.audioPositionGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 65;
    this.waveformGadget.setLayout(totalHeight, height);
    this.audioRegionsGadget.setLayout(totalHeight + height / 2 - 35, 40);
    totalHeight += height;
    height = 25;
    const cuesStartY = totalHeight;
    this.notchesGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.audioPositionGadget.positionLineExtension =
      totalHeight - audioPositionStartY;
    this.audioRegionSelectionGadget.setLayout(
      audioPositionStartY,
      totalHeight - audioPositionStartY
    );
    height = 50;
    this.wordsGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.cuesGadget.setLayout(cuesStartY, totalHeight - cuesStartY);
    height = 15;
    this.warningsGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 15;
    this.infoGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 50;
    this.transcriptGadget.setLayout(totalHeight, height);
  }

  defaultNavigationReaction(gadget) {
    gadget.onNavigationSelect((gadget, key, select) => {
      if (gadget.navigatorKey) {
        player.navigate(key, select.index);
      } else {
        player.seek(select.time);
      }
    });
  }

  defaultSetupNavigation(gadget) {
    if (gadget.navigatorKey) {
      gadget.setNavigation(playerState);
    }
    this.defaultNavigationReaction(gadget);
  }

  initGadgetsData() {
    this.wordsGadget.setWordsIntervals(chaat.atoms, chaat.atomTimeIntervals);
    this.wordsGadget.onNavigationSelect((_, key, select) => {
      if (
        select.event.getModifierState("Alt") ||
        select.event.getModifierState("Ctrl") ||
        select.event.getModifierState("Shift")
      ) {
        // TODO need get id here
        appBus.emit("cueInsertionPoint", chaat.atomIndexToId(select.index));
      } else {
        player.seek(select.time);
      }
    });

    this.cuesGadget.setHandleIntervals(chaat.cueDisplayTimeIntervals);
    this.cuesGadget.onNavigationSelect((_, key, select) => {
      appBus.emit("cueInsertionPoint", chaat.cueIndexes[select.index]);
    });

    this.waveformGadget.setWaveformData(root.waveformData);
    this.waveformGadget.onTimeSelect(() => {
      const seekTo = this.waveformGadget.lastTimeSelect;
      // TODO factor this logic to player
      if (transportState.isPlaying) {
        player.seek(seekTo, true);
      } else {
        player.seek(seekTo);
      }
    });

    this.audioRegionSelectionGadget.onRegionSelect((_, region) => {
      chaat.setAudioRegionSelection(region);
    });

    this.audioRegionsGadget.setHandleIntervals(chaat.audioRegionTimeIntervals);

    this.segmentsGadget.setHandleIntervals(chaat.segmentTimeIntervals);
    this.segmentsGadget.navigatorKey = "SEGMENT";
    this.defaultSetupNavigation(this.segmentsGadget);

    this.warningsGadget.setHandleIntervals(chaat.warningTimeIntervals);

    this.infoGadget.setHandleIntervals(chaat.interpolatedTimeIntervals);

    this.notchesGadget.setHandleIntervals(chaat.notchTimeIntervals);
    this.notchesGadget.navigatorKey = "NOTCH";
    this.defaultSetupNavigation(this.notchesGadget);

    this.transcriptGadget.setWordsIntervals(
      chaat.transcriptWords,
      chaat.transcriptWordTimeIntervals
    );
    this.defaultSetupNavigation(this.transcriptGadget);
  }

  drawForegroundLayer() {
    super.drawForegroundLayer();
  }

  doHover() {
    const hoverState = this.lastHoverState;
    const gadget = hoverState.gadget;
    const interval = hoverState.interval;

    const posY = this.waveformGadget.originY;
    let hoverText = null;

    if (gadget === this.wordsGadget || gadget === this.audioRegionsGadget) {
      // hoverText = ` ${(interval.start / 1000).toFixed(2)} - ${(
      //   interval.end / 1000
      // ).toFixed(2)} `;
      hoverText = intervalToMinSecMsDurationString(interval);
    }

    if (gadget === this.warningsGadget) {
      hoverText = ` ${chaat.utils.getWarningUIText(hoverState.index)} `;
    }

    if (gadget === this.infoGadget) {
      hoverText = ` ${chaat.utils.getInterpolationInfoUIText(
        hoverState.index
      )} `;
    }

    if (hoverText) {
      this.showHover(gadget, hoverText, { interval, absolute: posY });
    }
  }

  audioRegionEdit() {
    const selectedInterval = this.audioRegionSelectionGadget.selectionInterval;
    if (selectedInterval) {
      openAudioRegionLabelDialog(selectedInterval);
    }
  }

  audioRegionDeselect() {
    this.audioRegionSelectionGadget.clearSelection();
  }

  toggleEnabled() {
    super.toggleEnabled();
    if (this.enabled) {
      this.audioPositionGadget.setTransportState(transportState);
    } else {
      this.audioPositionGadget.setTransportState(new TransportState());
    }
  }
}
