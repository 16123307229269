import { observable } from "mobx";
import { LocalTimestamper } from "./local-timestamper";
import * as firebase from "firebase/app";
import "firebase/firestore";
import { appBus, navigation, playerState } from "./app-root";
import { DbPaths } from "../lib/chaat/db-paths";
import { getWarningLevel } from "../lib/chaat/warning-data";

export const timestamperServerBox =
  "https://timestamper-test3-uontdmid5q-uc.a.run.app";
//"https://timestamper-test2-uontdmid5q-uc.a.run.app";

export class ChaatProject {
  root;
  projectKey = null;
  @observable cueInsertAtomId = -1;
  utils;
  dbPaths = null;
  atoms;
  atomEntityList;
  atomsTracker;
  atomTimeIntervals;
  segmentTimeIntervals;
  sentenceTimeIntervals;
  warningTimeIntervals;
  rawWarningTimeIntervals;
  warningData;
  interpolationData;
  interpolatedTimeIntervals;
  notchTimeIntervals;
  cueDisplayTimeIntervals;
  audioRegionTimeIntervals;
  audioRegions;
  segmentNavigator;
  notchNavigator;
  localTimestamper;
  audioRegionSelection = null;
  atomIndexToId = null;
  atomIdToIndex = null;
  cuePositions = null;

  constructor(root) {
    this.root = root;
    appBus.subscribe("cueInsertionPoint", id => {
      console.log("cue insertion select:" + id);
      this.cueInsertAtomId = id;
    });
    this.localTimestamper = new LocalTimestamper();
    this.utils = new ChaatUtils(this);
  }

  loadingProject(projectKey) {
    // TODO FIX UP THE LOADING OF PROJECTS VISA VI THE APP ROOT
    this.projectKey = projectKey;
    this.dbPaths = new DbPaths(projectKey);
  }

  setAudioRegionSelection(interval) {
    this.audioRegionSelection = interval;
  }

  get cueInsertIndex() {
    const insertPoint = this.cueInsertAtomId;
    if (insertPoint < 0) {
      return -1;
    }
    return this.atomIdToIndex(insertPoint);
  }

  contentUpdate(content) {
    // TODO go through these and see if they are all declared in class initialization
    // TODO go through these and see if they are all used somewhere in app
    this.projectKey = content.projectKey;
    this.atoms = content.atoms;
    this.atomEntityList = content.atomEntityList;
    this.atomTracker = content.atomTracker;
    this.atomTimeIntervals = content.baseContentLayer.atomTimeIntervals;
    this.segmentTimeIntervals = content.baseContentLayer.segmentTimeIntervals;
    this.warningTimeIntervals = content.baseContentLayer.warningTimeIntervals;
    this.rawWarningTimeIntervals =
      content.baseContentLayer.rawWarningTimeIntervals;
    this.warningData = content.baseContentLayer.skipWarnData;
    this.notchTimeIntervals = content.baseContentLayer.notchTimeIntervals;
    this.audioRegionTimeIntervals =
      content.baseContentLayer.audioRegionTimeIntervals;
    this.audioRegions = content.baseContentLayer.sortedAudioRegions;
    this.interpolatedTimeIntervals =
      content.baseContentLayer.interpolatedTimeIntervals;
    this.interpolationData = content.baseContentLayer.interpolationData;
    this.segmentNavigator = content.baseContentLayer.segmentNavigator;
    this.notchNavigator = content.baseContentLayer.notchNavigator;
    this.cuePositions = content.baseContentLayer.cuePositions;
    this.cueTimestamps = content.baseContentLayer.cueTimestamps;
    this.cueDisplayTimeIntervals =
      content.baseContentLayer.cueDisplayTimeIntervals;
    this.transcriptWords = content.baseContentLayer.transcriptWords;
    this.transcriptWordTimeIntervals =
      content.baseContentLayer.transcriptWordTimeIntervals;
    this.atomIndexToId = content.baseContentLayer.atomEntityList.getId;
    this.atomIdToIndex = content.baseContentLayer.atomEntityList.getIndex;

    const segmentNavigator = this.segmentNavigator;
    navigation.addNavigator(segmentNavigator);
    playerState.navigationPoint = segmentNavigator.navigationPoint(0);

    const notchNavigator = this.notchNavigator;
    navigation.addNavigator(notchNavigator);
  }

  async updateTimestamping() {
    setTimeout(() => {
      fetch(`${timestamperServerBox}/chaat_run?key=${this.projectKey}`);
    }, 50);
  }
}

export class ChaatUtils {
  chaat;

  constructor(chaat) {
    this.chaat = chaat;
  }

  getScriptStringMaxChars(position, chars) {
    const index = this.chaat.atomIdToIndex(position);
    if (index < 0) {
      return "";
    }
    const resultWords = [];
    let charCount = 0;
    let i = index;
    while (charCount < chars && i < this.chaat.atoms.length) {
      const atom = this.chaat.atoms[i];
      resultWords.push(atom);
      charCount += atom.length;
      i++;
    }
    return resultWords.join(" ");
  }

  getScriptStringWordCount(position, count) {
    let index = this.chaat.atomIdToIndex(position);
    if (index < 0) {
      index = 0;
    }
    const resultWords = this.chaat.atoms.slice(index, index + count);
    return resultWords.join(" ");
  }

  getWordRangeIntervalForWarning(index) {
    const warningInterval = this.chaat.warningTimeIntervals.interval(index);
    if (!warningInterval) {
      return null;
    }
    if (warningInterval) {
      return this.chaat.atomTimeIntervals.rangeContained(
        warningInterval.start,
        warningInterval.end
      );
    }
  }

  getDataForIndexRange(array, startIndex, endIndex) {
    // TODO move someplace in lib
    const result = [];

    for (let i = startIndex; i < endIndex; i++) {
      result.push(array[i]);
    }
    return result;
  }

  getWarningLevel(index) {
    return getWarningLevel(this.chaat.warningData[index]);
  }

  getWarningUIText(index) {
    const warningTextLookup = {
      short: "seems like a short audio period for ",
      long: "seems like a long audio period for ",
      silences: "audio silences inside interpolation for "
    };
    const atomRange = this.getWordRangeIntervalForWarning(index);
    if (!atomRange) {
      // TODO related to nulls issue to be dealt with
      return "";
    }
    const atoms = this.getDataForIndexRange(
      this.chaat.atoms,
      atomRange.start,
      atomRange.end
    );
    return (
      warningTextLookup[this.chaat.warningData[index]] + `"${atoms.join(" ")}"`
    );
  }

  getInterpolationInfoUIText(index) {
    let interpolationData = this.chaat.interpolationData[index];
    if (interpolationData.startsWith("char_interp")) {
      const chars = interpolationData.slice("char_interp(".length, -1);
      const charArray = [];
      for (const c of chars) {
        charArray.push(c);
      }
      return "character interpolation using: " + charArray.join("-");
    } else {
      return "linear interpolation";
    }
  }
}
