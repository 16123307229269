const emptyTags = [
  "area",
  "base",
  "br",
  "col",
  "command",
  "embed",
  "hr",
  "img",
  "input",
  "keygen",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr"
];

let esc = str => String(str).replace(/[&<>"']/g, s => `&${map[s]};`);
let map = { "&": "amp", "<": "lt", ">": "gt", '"': "quot", "'": "apos" };
let setInnerHTMLAttr = "dangerouslySetInnerHTML";
let DOMAttributeNames = {
  className: "class",
  htmlFor: "for"
};

let sanitized = {};

const Fragment = props => {
  return props.children;
};

export function html_h(name, attrs) {
  let stack = [],
    s = "";
  attrs = attrs || {};
  for (let i = arguments.length; i-- > 2; ) {
    stack.push(arguments[i]);
  }

  if (typeof name === "function") {
    attrs.children = stack.reverse();
    return name(attrs);
  }

  if (attrs.children) {
    const children = attrs.children;
    for (let i = children.length; i--; ) stack.push(children[i]);
    delete attrs.children;
  }

  if (name) {
    s += "<";
    s += name;
    if (attrs) {
      for (let [key, value] of Object.entries(attrs)) {
        const type = typeof value;
        if (type === "function") {
          value = value.alternate;
        }
        if (key === "__source" || type === "object") {
          continue;
        }
        if (value !== false && value != null && key !== setInnerHTMLAttr) {
          s += ` ${
            DOMAttributeNames[key] ? DOMAttributeNames[key] : esc(key)
          }="${esc(value)}"`;
        }
      }
    }
    s += ">";
  }

  if (emptyTags.indexOf(name) === -1) {
    if (attrs[setInnerHTMLAttr]) {
      s += attrs[setInnerHTMLAttr].__html;
    } else
      while (stack.length) {
        let child = stack.pop();
        if (child) {
          if (child.pop) {
            for (let i = child.length; i--; ) stack.push(child[i]);
          } else {
            s += sanitized[child] === true ? child : esc(child);
          }
        }
      }

    s += name ? `</${name}>` : "";
  }

  sanitized[s] = true;
  return s;
}

html_h.Fragment = Fragment;
