import React from "react";
import Play from "../components/play.js";
import styled from "styled-components";
import { player } from "../app/app-root";
import * as colors from "./stylesystem/colors";

const Button = styled.div`
  display: flex;
  text-decoration: none;
  justify-content: flex-end;
  padding: 0px 8px;
  border-radius: 6px;
  opacity: 0.8;
  transition-property: all;
  transition-duration: 0.3s;
  color: ${p => (p.current ? "white" : "#151515")};
  background: ${p =>
    p.current ? p.theme.jumpColor : p.warning ? colors.darkYellow : "#aaa"};
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 1;
  }
`;

const Label = styled.div`
  font-family: ${p => p.theme.sansSerif};
  color: inherit;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-align: right;
  padding-top: 15px;
  margin-top: 10px;
  margin-right: 6px;
  width: 1em;
`;

const JumpTo = ({ element, isCurrent, warning, label }) => (
  <Button
    current={isCurrent}
    warning={warning}
    href="#"
    onClick={e => {
      e.preventDefault();
      player.seekEntity(element.id);
    }}
  >
    <Label>{label}</Label>
    <Play />
  </Button>
);

export default JumpTo;
