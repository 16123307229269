import { getEntityType } from "../ids/id-utils";

export class Tracking {
  trackers = {};

  setTrackers(trackers) {
    this.trackers = trackers;
  }

  getTracker(entityId) {
    const type = getEntityType(entityId);
    return this.trackers[type];
  }

  currentIsUnder(type) {
    return this.trackers[type].currentIsUnder;
  }

  isUnder(entityId) {
    return this.getTracker(entityId).isUnder(entityId);
  }

  isBefore(entityId) {
    return this.getTracker(entityId).isBefore(entityId);
  }

  isVisited(entityId) {
    return this.getTracker(entityId).isVisited(entityId);
  }

  isUnderSignal(entityId) {
    return this.getTracker(entityId).isUnderSignal(entityId);
  }

  isBeforeSignal(entityId) {
    return this.getTracker(entityId).isBeforeSignal(entityId);
  }

  isVisitedSignal(entityId) {
    return this.getTracker(entityId).isVisitedSignal(entityId);
  }

  changedSignal(entityId) {
    return this.getTracker(entityId).changedSignal(entityId);
  }

  anyIsChangedSignal(type) {
    return this.trackers[type].anyIsChangedSignal();
  }
}
