import { reaction } from "mobx";
import { transportState } from "../../app/app-root";
import { TimelineTrackerEngine } from "./timeline-tracker-engine";

// TODO this is lib level but right now using app level module imports, pass the function to get audio position instead of using module import
const NO_INDEX = -1;

export class BaseTimelineTracker {
  projectKey = "";
  id = 0;

  trackerEngine = null;

  sortedIntervals = null;
  selfInterval = null;

  //mapping = null;
  entityList = null;
  entityIdToIndex = null;
  indexToEntityId = null;

  // arrays of abstract signals
  _isUnderSignals = null;
  _isBeforeSignals = null;
  _isVisitedSignals = null;
  _changeSignals = null;

  // single abstract signals
  _anyIsChanged = null;
  // _anyIsUnderChanged = null; // TODO anyIsBeforeChanged?

  isUnderListeners = null;
  isBeforeListeners = null;
  isVisitedListeners = null;

  disposers = [];

  constructor(driverSignalFunc = null) {
    this.trackerEngine = new TimelineTrackerEngine(this.sortedIntervals);

    if (driverSignalFunc) {
      this.disposers.push(
        reaction(
          () => driverSignalFunc(),
          () => this.processPositionChange()
        )
      );
    }
  }

  setEntities(entityList) {
    // TODO in the future will have another param to use to reindex internal arrays
    const oldEntityList = this.entityList;
    if (oldEntityList) {
      this._isUnderSignals = entityList.adaptArray(
        this._isUnderSignals,
        oldEntityList
      );
      this._isBeforeSignals = entityList.adaptArray(
        this._isBeforeSignals,
        oldEntityList
      );
      this._isVisitedSignals = entityList.adaptArray(
        this._isVisitedSignals,
        oldEntityList
      );
      this._changeSignals = entityList.adaptArray(
        this._changeSignals,
        oldEntityList
      );
    }
    this.entityIdToIndex = entityList.getIndex;
    this.indexToEntityId = entityList.getId;
    this.sortedIntervals = entityList.getSortedTimes();
    this.trackerEngine.refreshIntervals(this.sortedIntervals);
  }

  dispose() {
    for (const dispose of this.disposers) {
      dispose();
    }
  }

  // TODO make transportState and instance variable because this is at lib level
  getUnderlyingPosition() {
    return transportState.audioPosition;
  }

  notifyChange() {
    throw new Error("non implemented abstract");
  }

  notifySignal(signals, index) {
    throw new Error("non implemented abstract");
  }

  notifySignalRange(signals, start, end) {
    throw new Error("non implemented abstract");
  }

  makeSignal() {
    throw new Error("non implemented abstract");
  }

  makeSignalsArray() {
    return Array(this.sortedIntervals.length).fill(0);
  }

  notifyAllChanges() {
    throw new Error("non implemented abstract");
  }

  processPositionChange() {
    const position = this.getUnderlyingPosition();
    this.trackerEngine.recordChangesForNewPosition(position);
    if (this.trackerEngine.anyChangeRecord) {
      this.notifyAllChanges();
      this.trackerEngine.clearChangeRecords();
    }
  }

  subscribeIsUnder(f) {
    if (!this.isUnderListeners) {
      this.isUnderListeners = new Set();
    }
    this.isUnderListeners.add(f);
    return () => this.isUnderListeners.delete(f);
  }

  subscribeIsBefore(f) {
    if (!this.isBeforeListeners) {
      this.isBeforeListeners = new Set();
    }
    this.isBeforeListeners.add(f);
    return () => this.isBeforeListeners.delete(f);
  }

  subscribeIsVisited(f) {
    if (!this.isVisitedListeners) {
      this.isVisitedListeners = new Set();
    }
    this.isVisitedListeners.add(f);
    return () => this.isVisitedListeners.delete(f);
  }

  notifyListenersForRange(listeners, start, end) {
    if (!listeners || start < 0) {
      return;
    }
    const callbacks = Array.from(listeners);
    for (const callback of callbacks) {
      for (let index = start; index <= end; index++) {
        const id = this.indexToEntityId(index);
        callback(id);
      }
    }
  }

  notifyListeners(listeners, index) {
    if (!listeners || index === NO_INDEX) {
      return;
    }
    const callbacks = Array.from(listeners);
    for (const callback of callbacks) {
      const id = this.indexToEntityId(index);
      callback(id);
    }
  }

  notifyIsBefore(start, end) {
    this.notifyListenersForRange(this.isBeforeListeners, start, end);
    this.notifySignalRange(this._isBeforeSignals, start, end);
    if (this.needNotifyAnyChange) {
      this.notifySignalRange(this._changeSignals, start, end);
    }
  }

  notifyIsVisited(start, end) {
    this.notifyListenersForRange(this.isVisitedListeners, start, end);
    this.notifySignalRange(this._isVisitedSignals, start, end);
  }

  notifyIsUnder(index) {
    this.notifyListeners(this.isUnderListeners, index);
    this.notifySignal(this._isUnderSignals, index);
    if (this.needNotifyAnyChange) {
      this.notifyIsChanged(index);
    }
  }

  notifyIsChanged(index) {
    this.notifySignal(this._changeSignals, index);
  }

  get needNotifyAnyChange() {
    return !!this._changeSignals;
  }

  get isUnderSignals() {
    if (!this._isUnderSignals) {
      this._isUnderSignals = this.makeSignalsArray();
    }
    return this._isUnderSignals;
  }

  get isBeforeSignals() {
    if (!this._isBeforeSignals) {
      this._isBeforeSignals = this.makeSignalsArray();
    }
    return this._isBeforeSignals;
  }

  get isVisitedSignals() {
    if (!this._isVisitedSignals) {
      this._isVisitedSignals = this.makeSignalsArray();
    }
    return this._isVisitedSignals;
  }

  get changeSignals() {
    if (!this._changeSignals) {
      this._changeSignals = this.makeSignalsArray();
    }
    return this._changeSignals;
  }

  get anyIsChangedSignal() {
    if (!this._anyIsChanged) {
      this._anyIsChanged = this.makeSignal();
    }
    return this._anyIsChanged;
  }

  get anyIsUnderChangedSignal() {
    // TODO
    return null;
  }

  /************* Entity Id based external interface ************/

  get currentIsUnder() {
    return this.indexToEntityId(this.trackerEngine.currentIsUnder);
  }

  isUnder(entityId) {
    const idx = this.entityIdToIndex(entityId);
    return this.trackerEngine.isUnder(idx);
  }

  isBefore(entityId) {
    const idx = this.entityIdToIndex(entityId);
    return this.trackerEngine.isBefore(idx);
  }

  isVisited(entityId) {
    const idx = this.entityIdToIndex(entityId);
    return this.trackerEngine.isVisited(idx);
  }

  interval(entityId) {
    const idx = this.entityIdToIndex(entityId);
    return this.trackerEngine.interval(idx);
  }

  isUnderSignal(entityId) {
    const idx = this.entityIdToIndex(entityId);
    const signalArray = this.isUnderSignals;
    let signal = this._isUnderSignals[idx];
    if (!signal) {
      signal = this.makeSignal();
      signalArray[idx] = signal;
    }
    return signal;
  }

  isBeforeSignal(entityId) {
    const idx = this.entityIdToIndex(entityId);
    const signalArray = this.isBeforeSignals;
    let signal = this._isBeforeSignals[idx];
    if (!signal) {
      signal = this.makeSignal();
      signalArray[idx] = signal;
    }
    return signal;
  }

  isVisitedSignal(entityId) {
    const idx = this.entityIdToIndex(entityId);
    const signalArray = this.isVisitedSignals;
    let signal = this._isVisitedSignals[idx];
    if (!signal) {
      signal = this.makeSignal();
      signalArray[idx] = signal;
    }
    return signal;
  }

  changedSignal(entityId) {
    const idx = this.entityIdToIndex(entityId);
    const signalArray = this.changeSignals;
    let signal = this._changeSignals[idx];
    if (!signal) {
      signal = this.makeSignal();
      signalArray[idx] = signal;
    }
    return signal;
  }
}
