import { observable } from "mobx";

export class PlayerState {
  @observable navigationPoint = null;
}

export class StructuredPlayer {
  playerState = null;
  audioTransport = null;
  transportState = null;
  navigation = null;
  entities = null;
  peekDuration = 1500;
  disposers = [];
  playbackRateValues = [0.7, 0.8, 0.9, 1.0];
  playbackRateSelect = 3;

  constructor({ playerState, audioTransport, transportState, navigation }) {
    this.playerState = playerState;
    this.audioTransport = audioTransport;
    this.transportState = transportState;
    this.navigation = navigation;
  }

  setEntities(entities) {
    this.entities = entities;
  }

  get audioPosition() {
    return this.audioTransport.audioPosition;
  }

  play() {
    this.audioTransport.play();
  }

  pause(keepPauseAfter = true) {
    this.audioTransport.pause(keepPauseAfter);
  }

  pauseThenPlayAt(ms, position, keepPauseAfter = false) {
    this.pause(keepPauseAfter);
    this.seek(position, keepPauseAfter);
    setTimeout(() => this.play(), ms);
  }

  togglePlay() {
    if (this.transportState.isPlaying) {
      this.pause();
    } else {
      this.playForward();
    }
  }

  seek(time, keepPauseAfter = false) {
    this.audioTransport.seek(time);
    if (!this.transportState.isPlaying && !keepPauseAfter) {
      this.transportState.audioRestartPosition = time;
      console.log("setting audio restart: " + time);
    }
  }

  rewind() {
    if (this.transportState.isPlaying) {
      this.pause();
      return;
    }
    this.audioTransport.rewind();
    if (!this.transportState.isPlaying) {
      this.play();
    }
  }

  seekEntity(entityId) {
    this.seek(this.entities.getStartTime(entityId));
  }

  playEntity(entityId) {
    this.seekEntity(entityId);
    this.play();
  }

  playForward() {
    this.play();
  }

  playPeek() {
    if (
      this.transportState.isPlaying &&
      this.transportState.audioRestartPosition
    ) {
      this.audioTransport.seek(this.transportState.audioRestartPosition);
    } else {
      this.audioTransport.setPauseAfter(this.audioPosition + this.peekDuration);
      this.transportState.audioRestartPosition = this.audioPosition;
      this.play();
    }
  }

  playPeekBack() {
    if (
      this.transportState.isPlaying &&
      this.transportState.audioRestartPosition
    ) {
      const playFrom =
        this.transportState.audioRestartPosition - this.peekDuration;
      this.pauseThenPlayAt(100, playFrom, true);
      this.audioTransport.setPauseAfter(
        this.transportState.audioRestartPosition
      );
    } else if (this.transportState.audioRestartPosition) {
      this.audioTransport.setPauseAfter(
        this.transportState.audioRestartPosition
      );
      //this.seek(Math.max(this.audioPosition - this.peekDuration, this.selfInterval.start));
      this.seek(
        this.transportState.audioRestartPosition - this.peekDuration,
        true
      );
      this.play();
    }
  }

  nudge(val) {
    if (!this.transportState.isPlaying) {
      this.seek(this.transportState.audioPosition + val);
    } else if (this.transportState.audioRestartPosition) {
      const nudgeTo = this.transportState.audioRestartPosition + val;
      this.transportState.audioRestartPosition = nudgeTo;
      this.pauseThenPlayAt(100, nudgeTo);
      this.audioTransport.setPauseAfter(nudgeTo + this.peekDuration);
    }
  }

  next() {
    // TODO maybe sets restart point, BUT THEN when regular playing what does it do just set cursor?
    // TODO factor to somewhere else?
    const currentPoint = this.playerState.navigationPoint;
    const nextPoint = this.navigation.next(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.playerState.navigationPoint = nextPoint;
    }
  }

  prev() {
    const currentPoint = this.playerState.navigationPoint;
    const nextPoint = this.navigation.prev(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.playerState.navigationPoint = nextPoint;
    }
  }

  nextClosest() {
    // TODO different than prev/next but only keep one?
    const navigationPoint = this.playerState.navigationPoint;
    if (navigationPoint) {
      const nextPoint = this.navigation.nextClosest(
        navigationPoint,
        this.audioPosition
      );
      if (nextPoint) {
        this.seek(nextPoint.position);
        this.playerState.navigationPoint = nextPoint;
      }
    }
  }

  prevClosest() {
    const navigationPoint = this.playerState.navigationPoint;
    if (navigationPoint) {
      const tolerance = this.transportState.isPlaying ? 300 : 10;
      const nextPoint = this.navigation.prevClosest(
        navigationPoint,
        this.audioPosition - tolerance
      );
      if (nextPoint) {
        this.playerState.navigationPoint = nextPoint;
        if (this.transportState.isPlaying) {
          this.pauseThenPlayAt(600, nextPoint.position);
        } else {
          this.seek(nextPoint.position);
        }
      }
    }
  }

  playSection() {
    const navigationPoint = this.playerState.navigationPoint;
    if (navigationPoint) {
      const nextPoint = this.navigation.nextClosest(
        navigationPoint,
        this.audioPosition + 50
      );
      if (nextPoint) {
        this.audioTransport.setPauseAfter(nextPoint.position);
        this.audioTransport.clearAudioRestartPosition();
        this.play();
      }
    }
  }

  navigate(key, index) {
    const navigator = this.navigation.getNavigatorForKey(key);
    const navigationPoint = navigator.navigationPoint(index);
    this.setNavigationPoint(navigationPoint);
  }

  // only seek to audio position not change current navigation point
  seekToNavigationPoint(navigationPoint) {
    if (navigationPoint) {
      this.seek(navigationPoint.position);
    }
  }

  setNavigationPoint(navigationPoint = null) {
    this.playerState.navigationPoint = navigationPoint;
    if (navigationPoint) {
      this.seek(navigationPoint.position);
    }
  }

  adjustPlaybackRateAction(direction) {
    const select = direction
      ? this.playbackRateSelect + 1
      : this.playbackRateSelect - 1;
    if (select < 0 || select >= this.playbackRateValues.length) {
      return;
    }

    this.playbackRateSelect = select;
    this.audioTransport.setPlaybackRate(
      this.playbackRateValues[this.playbackRateSelect]
    );
  }

  setKerningPoints(points) {
    this.audioTransport.setKerningPoints(points);
  }

  setKerningDuration(duration) {
    this.audioTransport.setKerningDuration(duration);
  }

  setKerning(points, duration) {
    this.audioTransport.setKerning(points, duration);
  }

  kerningEnable(enable) {
    this.audioTransport.kerningEnable(enable);
  }
}
